import './posts.style.scss';
import { useSelector, useDispatch } from 'react-redux';
import { FaInstagram, FaSnapchatGhost, FaTiktok, FaTwitter } from "react-icons/fa";


import { setActivePost, selectActivePost, clearPosts, updatePosts } from './postsSlice';

import dayjs from 'dayjs';
import { selectUser } from '../auth/userSlice';
import { memo, useEffect, useRef, useState } from 'react';
import {
  google_translate_moderation,
  db,
  view_verif_img,
  auth,
} from '../../app/firebase';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FcCamera, FcOpenedFolder } from 'react-icons/fc';
import { delay } from '../../app/globaleSlice';

function Post(props) {
  const [translate, setTranslate] = useState(null);
  const [translateShow, setTranslateShow] = useState(true);
  const [verifImg, setVerifImg] = useState();
  const [zoom, setZoom] = useState(false);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [showZoomedImg, setShowZoomedImg] = useState(false);
  const timerStartedRef = useRef(false);

  const [imageWidth, setImageWidth] = useState(0);
  const imageRef = useRef(null);

  const {
    isPictureFullscreen,
    setIsPictureFullscreen,
    setID,
    setUsername,
    setIsLoading,
    setIsLoading2,
    isLoading2,
    links
  } = props;
  const activePost = useSelector(selectActivePost);
  const dispatch = useDispatch();

  let user = useSelector(selectUser);
  const isActive = activePost === props.postId;
  const isBoosted = props.boost_request && props.reported;
  const isSafelyBoosted = props?.boost_safe;

  const prevProfile = props.allPosts[activePost - 1];
  const activeProfile = props.allPosts[activePost];
  const nextProfile = props.allPosts[activePost + 1];

  const handleKeyUp = (e) => {
    if (e.key === 't' && isActive) {

      google_translate_moderation({
        username: props.username,
        caption: props.postText,
        other_text: props?.other_text,
        bio: activeProfile?.bio,
      }).then((res) => {
        console.log("res.data  ", res.data);
        setTranslate(res.data.english);
        console.log("translate", res.data.english);
        // window.alert(res.data.english);
      });
    }
  };

  const mod_uid = auth().currentUser;
  const currentUID = mod_uid ? mod_uid.uid : null;
  const admin_uids = ["8InOiDv72waRp4Nul3aFddOS1ng2", "BuT86HiYu1b14bmbffvLv5w7D362", "G8dOCB2xcUS99mdYLaYY99nFfsj2"];
  const loadedImgRef = useRef(false); //this is for posts that have already loaded there images to not set the flag to true again


  const updateImageWidth = () => {
    if (imageRef.current) {
      setImageWidth(imageRef.current.clientWidth);
    }
  };

  useEffect(() => {
    // Initial update
    updateImageWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateImageWidth);

    // Add event listener for image load
    const img = imageRef.current;
    if (img) {
      img.addEventListener('load', updateImageWidth);
    }

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('resize', updateImageWidth);
      if (img) {
        img.removeEventListener('load', updateImageWidth);
      }
    };
  }, [activePost]);

  useEffect(() => {
    // Apply conditional styles or changes based on imageWidth
    console.log('Image width:', imageWidth);
  }, [imageWidth]);


  useEffect(() => {
    let ignore = false;
    if (isActive) {
      console.log("USE EFFECT ISSUE:")
      setID(props.ppp.userID);
      setUsername(props.username);
      //this has no effect after switching to one array approach
      if (activePost > props?.allPosts?.length) {
        console.log("active post is greater than length of all posts")
        return setIsLoading2(true);
      }  //this is important if the user opens a post that its updated data has not arrived to show that he might have images

      if (!("first_profile_url" in props?.allPosts[activePost]) || !("verifyImageURL" in props?.allPosts[activePost])) {
        if (props?.allDataReadyRef.current !== 'in_progress') { // props?.allDataReadyRef.current && 
          setIsLoading2(true)
          console.log("updating!!! because active post loading indicator")
          return props.updatePostArray();
        }
        else if (props?.allDataReadyRef.current === 'in_progress') {
          console.log("setting loading to true because updates are being loaded")
          // Call updateOnePost to make it faster
          setIsLoading2(true);
          props.updateOnePost(activePost, ignore);
          return;
        }
      }
      else if ((props?.allPosts[activePost]?.first_profile_url?.includes(props.userID) || props?.allPosts[activePost]?.verifyImageURL?.includes(props.userID)) && !loadedImgRef.current) {
        console.log("setting isLoading2 true because it has fields in array")
        setIsLoading2(true);
      }
      else if ((!props?.allPosts[activePost]?.first_profile_url && !props?.allPosts[activePost]?.verifyImageURL) && props?.allPosts?.length > 0) {
        console.log("setting isLoading2 false because it does not have urls")
        setIsLoading2(false);
        // if(props?.allDataReadyRef.current !== 'in_progress')
      }
      else {
        console.log("setting isLoading2 false as fall back inside useEffect")
        setIsLoading2(false); //fall back to prevent infinite loading spinner
      }
      // console.log("Seen by : ", props.post_seen_by);
      console.log("activePost", activePost);
      // setIsLoading(true);
    } else {
      ignore = true;
      console.log("post is not active")
      props.setNextPostDetails({});
      props.setPrevPostDetails({});
    }
  }, [isActive, activePost, props.allPosts]);

  const storeDelay = useSelector(delay);

  useEffect(() => {
    let timer;
    if (isLoading2 && isActive && props?.allDataReadyRef.current !== "in_progress" && !timerStartedRef.current) {
      console.log("timer started", 5000, "seconds");
      timerStartedRef.current = true
      timer = setTimeout(() => {
        // if (props?.allDataReadyRef.current && props?.allDataReadyRef.current !== 'in_progress') {
        timerStartedRef.current = false;
        console.log(`refetching after 5000 seconds`)
        props.updatePostArray();
        // }
      }, [5000])
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
        console.log(`timer of post ${activePost} cleared`)
      }
    }
  }, [isLoading2, props.updatePostArray, activePost])

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);

    return function () {
      document.removeEventListener('keyup', handleKeyUp);
    };
  });

  function handleSelect() {
    if (!isActive) dispatch(setActivePost(props.postId));
  }

  function unselect() {
    dispatch(setActivePost(-1));
    props.setUserData({});
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  if (isActive) {
    return (
      <div className={`post ${isActive && 'fullscreen '}`}>
        {!isPictureFullscreen && (
          <div onClick={unselect} className="close">
            Close
          </div>
        )}
        {translate && (
          <div className="translating">
            <p style={{ margin: '0px', fontSize: '20px' }}>{translate}</p>
          </div>
        )}
        <div
          className="picture"
          style={{ width: isPictureFullscreen ? '' : '25vw' }}
          {...(isPictureFullscreen ? { fullscreen: 'true' } : {})}
        >
          {isPictureFullscreen && (
            <div
              className="close-fullscreen"
              onClick={() => setIsPictureFullscreen(false)}
            >
              Close
            </div>
          )}

          {/*The image in the middle */}
          <div
            className="img-wrapper"
            style={{
              width: '100%',
              maxWidth: isPictureFullscreen ? '25vw' : '',
              height: isPictureFullscreen ? '85vh' : '100%',
              borderRadius: isPictureFullscreen ? '10px' : '',
              overflow: 'hidden',
            }}
            onMouseMove={(e) => {
              const rect = e.target.getBoundingClientRect();
              setPosX(e.clientX - rect.left);
              setPosY(e.clientY - rect.top);
            }}
            onMouseEnter={() => {
              setZoom(true);
              setShowZoomedImg(true);
            }}
            onMouseLeave={() => {
              setZoom(false);
              setShowZoomedImg(false);
            }}
          >
            <img
              ref={imageRef}
              src={
                props?.allPosts[activePost]?.imageUrlPost ||
                props?.ppp?.imageUrlPost
              } 
              className={(isBoosted || isSafelyBoosted) && 'boosted-img'}
              alt={`${props.username}'s profile`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </div>

          {/* The zoomed image */}
          {showZoomedImg && (
            <div
              className="img-wrapper-zoomed"
              style={{
                position: 'fixed',
                width: '70%',
                maxWidth: '25vw',
                height: '85vh',
                overflow: 'hidden',
                left: '20px',
                zIndex: 20,
              }}
            >
              <img
                src={
                  props?.allPosts[activePost]?.imageUrlPost ||
                  props?.ppp?.imageUrlPost
                }
                className={(isBoosted || isSafelyBoosted) && 'boosted-img'}
                alt={`${props.username}'s profile`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  transition: 'transform 0.2s',
                  transform: `scale(${zoom ? 2.5 : 1})`,
                  transformOrigin: `${posX}px ${posY}px`,
                }}
              />
            </div>
          )}
        </div>
        {!isPictureFullscreen && (
          <div
            className={
              'hidden postText_allPosts ' +
              ((isBoosted || isSafelyBoosted) && ' boosted ')
            }
            style={
              props?.allPosts[activePost]?.bio?.length > 8
                &&
                (props?.username?.length + props?.postText?.length + props?.reported?.length) > 8
                ?
                {
                  left: '25vw'
                }
                :
                {}
            }
          >
            {props.imgInfo == 0 ? (
              <span style={{ marginRight: '5px' }}>
                <FcCamera />
              </span>
            ) : (
              <span style={{ marginRight: '5px' }}>
                <FcOpenedFolder />
              </span>
            )}
            {/* style={{marginRight:"5px",fontSize:"25px"}} */}
            {isBoosted && '!!! ⚠️ '}
            {isSafelyBoosted && '🔥 '}

            {props.post_seen_mod && '🟢 '}

            {props.username}

            {props.postText && ` • ${props.postText}`}

            {props.reported && ' • '}

            {
              <>
                {props.reported &&
                  (props.reason ? capitalizeFirstLetter(props.reason) : '""')}
              </>
            }

            {isSafelyBoosted && ' 🔥'}
            {isBoosted && ' ⚠️ !!!'}
          </div>
        )}

        {/* new profile */}

        <div
          className="new_profile_div"
          style={{ display: props.isLoading ? 'flex' : 'none' }}
        >
          <ProgressSpinner />
          {/* <h1>1111111</h1> for testing to know which isLoading is causing the spinner  */}
        </div>
        <div
          className="new_profile_div"
          style={{ display: isLoading2 ? 'flex' : 'none' }}
        >
          <ProgressSpinner />
          {/* <h1>222222</h1>for testing to know which isLoading is causing the spinner  */}
        </div>
        {!isPictureFullscreen ? (
          <div
            className="new_profile_div"
            style={
              props?.allPosts[activePost]?.bio?.length > 35
                ?
                {
                  display: props.isLoading ? 'none' : 'flex',
                  top: '35.5vh'
                }
                :
                {
                  display: props.isLoading ? 'none' : 'flex',
                }
            }
          >
            <div className="new_profile_img">
              {' '}
              {!(props?.profilepicShow == props?.userID) &&
                (props?.allPosts[activePost]?.first_profile_url ? (
                  <img
                    width="100%"
                    height="100%"
                    style={{ objectFit: 'cover', borderRadius: '10px' }}
                    src={props?.allPosts[activePost]?.first_profile_url}
                    alt="first_profile_url"
                    onLoad={() => {
                      // add the check for first post index = 0
                      setIsLoading2(false);
                      loadedImgRef.current = true;
                    }}
                    onError={() => {
                      setIsLoading2(false);
                      loadedImgRef.current = true;
                    }}
                  />
                ) : (
                  <p style={{ display: isLoading2 ? 'none' : 'block' }}>
                    {' '}
                    No Profile Picture{' '}
                  </p>
                ))}
            </div>
            <div className="new_profile_bio">
              {' '}
              {links[activePost]?.length > 0 && <p style={{
                // width: "100%",
                // height: "10%",
                backgroundColor: "#ffb6c1",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                borderRadius: '10px',
                margin: '0',
                gap: "5px"
              }}>
                {links[activePost][0] && <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '3px' }}><FaSnapchatGhost color={'#fffff'} /></span>}
                {links[activePost][1] && <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '3px' }}><FaInstagram color={'#fffff'} /></span>}
                {links[activePost][2] && <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '3px' }}><FaTwitter color={'#fffff'} /></span>}
                {links[activePost][3] && <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '3px' }}><FaTiktok color={'#fffff'} /></span>}
              </p>
              }
              {!(props.bioCheck == props.userID) && (
                <span style={{ display: isLoading2 ? 'none' : 'block' }}>
                  {' '}
                  bio: {props?.allPosts[activePost]?.bio}{' '}
                </span>
              )}
            </div>
          </div>
        ) : (
          <div
            className="new_profile_div_fullScreen full_profile"
            style={{ display: props.isLoading ? 'none' : 'flex' }}
          >
            <div className="new_profile_img">
              {' '}
              {!(props?.profilepicShow == props?.userID) && (
                <>
                  {props?.allPosts[activePost]?.first_profile_url ? (
                    <img
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'cover', borderRadius: '10px' }}
                      src={props?.allPosts[activePost]?.first_profile_url}
                      alt="first_profile_url"
                      onLoad={() => {
                        // add the check for first post index = 0
                        setIsLoading2(false);
                        loadedImgRef.current = true;
                      }}
                      onError={() => {
                        setIsLoading2(false);
                        loadedImgRef.current = true;
                      }}
                    />
                  ) : (
                    <p style={{ display: isLoading2 ? 'none' : 'block' }}>
                      {' '}
                      No Profile Picture{' '}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {showZoomedImg ? (
          <div style={{ display: 'none' }}>
            {!isPictureFullscreen ? (
              <div
                className="new_profile_div new_verify_div"
                style={{ display: props.isLoading ? 'none' : 'flex' }}
              >
                <div className="new_profile_img">
                  {props?.allPosts[activePost]?.verifyImageURL ? (
                    <img
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'cover', borderRadius: '10px' }}
                      src={props?.allPosts[activePost]?.verifyImageURL}
                      alt="first_profile_url"
                      onLoad={() => {
                        // add the check for first post index = 0
                        if (isActive) {
                          setIsLoading2(false);
                          loadedImgRef.current = true;
                        }
                      }}
                      onError={() => {
                        setIsLoading2(false);
                        loadedImgRef.current = true;
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        position: 'absolute',
                        top: '7vw',
                        display: isLoading2 ? 'none' : 'block',
                      }}
                    >
                      No Verify Picture
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="new_profile_div_fullScreen full_profile full_verify_img"
                style={{ display: props.isLoading ? 'none' : 'flex' }}
              >
                <div className="new_profile_img">
                  {props?.allPosts[activePost]?.verifyImageURL ? (
                    <img
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'cover', borderRadius: '10px' }}
                      // className="img-circle"
                      src={props?.allPosts[activePost]?.verifyImageURL}
                      alt="view_verif_img"
                      onLoad={() => {
                        // add the check for first post index = 0
                        setIsLoading2(false);
                        loadedImgRef.current = true;
                      }}
                      onError={() => {
                        setIsLoading2(false);
                        loadedImgRef.current = true;
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        position: 'absolute',
                        top: '11vw',
                        left: '3vw',
                        display: isLoading2 ? 'none' : 'block',
                      }}
                    >
                      No Verify Picture
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {!isPictureFullscreen ? (
              <div
                className="new_profile_div new_verify_div"
                style={{ display: props.isLoading ? 'none' : 'flex' }}
              >
                <div className="new_profile_img">
                  {props?.allPosts[activePost]?.verifyImageURL ? (
                    <img
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'cover', borderRadius: '10px' }}
                      src={props?.allPosts[activePost]?.verifyImageURL}
                      alt="first_profile_url"
                      onLoad={() => {
                        // add the check for first post index = 0
                        setIsLoading2(false);
                        loadedImgRef.current = true;
                      }}
                      onError={() => {
                        setIsLoading2(false);
                        loadedImgRef.current = true;
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        position: 'absolute',
                        top: '7vw',
                        display: isLoading2 ? 'none' : 'block',
                      }}
                    >
                      No Verify Picture
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="new_profile_div_fullScreen full_profile full_verify_img"
                style={{ display: props.isLoading ? 'none' : 'flex' }}
              >
                <div className="new_profile_img">
                  {props?.allPosts[activePost]?.verifyImageURL ? (
                    <img
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'cover', borderRadius: '10px' }}
                      // className="img-circle"
                      src={props?.allPosts[activePost]?.verifyImageURL}
                      alt="view_verif_img"
                      onLoad={() => {
                        // add the check for first post index = 0
                        setIsLoading2(false);
                        loadedImgRef.current = true;
                      }}
                      onError={() => {
                        setIsLoading2(false);
                        loadedImgRef.current = true;
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        position: 'absolute',
                        top: '11vw',
                        left: '3vw',
                        display: isLoading2 ? 'none' : 'block',
                      }}
                    >
                      No Verify Picture
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {/* Verif Image */}
        <div
          className="new_profile_div new_verify_div"
          style={{ display: props.isLoading ? 'flex' : 'none' }}
        >
          <ProgressSpinner />
        </div>
        <div
          className="new_profile_div new_verify_div"
          style={{ display: isLoading2 ? 'flex' : 'none' }}
        >
          <ProgressSpinner />
        </div>

        {/* new profile */}
        <div className="description">
          <small className="time">{dayjs.unix(props.datePost).fromNow()}</small>

          <p>
            <b> {props.username} </b>
          </p>

          <p> ID: {props.userID} </p>

          <p> age: {props.age} </p>
          <p> country: {props.country} </p>
          <p> gender: {props.gender == 0 ? 'Guy' : 'Girl'} </p>

          {/* <p> Verified: {props?.allPosts[activePost]?.verified_profile ? "yes" : "no"} </p> */}
          <p> Verified: {props?.ppp?.verified_profile ? 'yes' : 'no'} </p>

          <p>
            <b>
              {props.post_seen_by?.length
                ? (admin_uids.includes(currentUID)
                  ? 'Seen by: ' + props.post_seen_by.join(", ")
                  : 'Seen')
                : 'Unseen'}
            </b>
          </p>

          {props.verifyImg ? (
            <img
              className="verification-image"
              src={props.verifyImg}
              alt="Verification"
            />
          ) : (
            ''
          )}
        </div>

        {props.controls}
        {!props?.allPosts[activePost]?.verifyImageURL ||
          !props?.allPosts[activePost]?.first_profile_url
          ? setIsLoading(false)
          : ''}
      </div>
    );
  }
  // Not active

  return (
    <div>
      <div
        className={'post ' + ((isBoosted || isSafelyBoosted) && ' boosted')}
        onClick={handleSelect}
      >
        <img
          src={props.imageUrlPost}
          alt={`${props.username}'s profile`}
          {...(user.developer ? { developer: 'true' } : {})}
        />

        <div className={'description '}>
          {isBoosted && '!!! ⚠️'}
          {isSafelyBoosted && '🔥 '}
          {props.post_seen_mod && '🟢'}
          {props.username}
          {' • '}
          {props.postText && props.postText}
          {isSafelyBoosted && ' 🔥'}
          {isBoosted && '⚠️ !!!'}
        </div>
      </div>
    </div>
  );
}


export default memo(Post);