import "./posts.style.scss";
import "./profilepage.css";
import { useSelector, useDispatch } from "react-redux";

import { setActivePost, selectActivePost } from "./postsSlice";

import dayjs from "dayjs";
import { selectUser } from "../auth/userSlice";
import ReactCountryFlag from "react-country-flag";
import { useEffect, useState } from "react";
import { google_translate_moderation } from "../../app/firebase";
import { useLocation } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
export default function Post(props) {

    const { isPictureFullscreen, setIsPictureFullscreen, username } = props;
    const activePost = useSelector(selectActivePost);
    const dispatch = useDispatch();


    // console.log(props.reported_User,"props");

    let user = useSelector(selectUser);
    const isActive = activePost === props.postId;
    const isBoosted = props.boost_request && props.reported;
    const isSafelyBoosted = props?.boost_safe;
    const location = useLocation();
    const { pathname } = location;

    useEffect(()=>{
        console.log("reported user changed")
    },[props.reported_User])

    function handleSelect() {
        if (!isActive){
            dispatch(setActivePost(props.postId));
            console.log("dispatching active post")
        } 
    }

    function unselect() {
        dispatch(setActivePost(-1));
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        if (isActive) {
            props.setUId(props.uid)
            props.setUsername(props.username)
            props.setBioValue(props.bio)
            props.setTranslate(false);
            props.setUsernameForProfile(props.username)
        }
        // console.log("these are the props of active post", props.mod_uid);
    }, [isActive])


    if (isActive && pathname !== '/swiping/profile') {
        return (
            <>

                <div className={`post ${isActive && "fullscreen "}`}>
                    {!isPictureFullscreen && (
                        <button onClick={unselect} className="close">
                            Close
                        </button>
                    )}
                    <div
                        className="picture" style={{ flexDirection: "column" }}
                        {...(isPictureFullscreen ? { fullscreen: "true" } : {})}
                    >

                        {isPictureFullscreen && (
                            <button
                                className="close"
                                onClick={() => setIsPictureFullscreen(false)}
                            >
                                Close
                            </button>
                        )}
                        {/* {isPictureFullscreen  && (
                        <h1 className="">
                            {props.username && `${props.username}`}
                        </h1>
                    )} */}
                        <div style={{ textAlign: "center" }}> <h1 style={{ margin: "0px", padding:"0px" }}>{props.username_warning && " 🚩 "}{props.username && `${props.username}`}</h1>

                        </div>
                        {/* {props.username_warning?<p style={{marginBottom:"5px",textAlign:"center"}}>{" ⚠️ "+":"+" "+props.username }</p>:<p style={{marginBottom:"25px",textAlign:"center"}}></p>} */}




                        {!isPictureFullscreen && <div className="static-bio">
                            {props.first_profile_url ?
                                <img
                                    src={props.first_profile_url}
                                    className={isBoosted || isSafelyBoosted ? " boosted-img" : ""}
                                    alt={`${props.username}'s profile`}
                                    style={{ objectFit: "contain" }}
                                />
                                : <h3 style={{ textAlign: "center", margin: "0" }} > Image not available</h3>
                            }
                            {/* <div className="" style={{display:"flex",flexDirection:"column",justifyContent:"center",}}> */}
                            <div className="bio-desc">
                                {/* {console.log(props.displayBio, "================props.displayBio===-----------------")} */}
                                <p> {props.displayBio ? props.bio_warning && " 🚩 " : ""}{props.translate ? props.translate : props.displayBio ? props.displayBio : "Bio Not Available"}</p>

                            </div>
                            {/* <button onClick={translates}>translate </button> */}
                            {/* </div> */}
                        </div>}
                        {isPictureFullscreen && <> {props.first_profile_url ?
                            <img
                                src={props.first_profile_url}
                                className={isBoosted || isSafelyBoosted ? " boosted-img" : ""}
                                alt={`${props.username}'s profile`}
                                style={{ objectFit: "contain" }}
                            />
                            : <h3 style={{ textAlign: "center", margin: "0" }} > Image not available</h3>}
                        </>}

                        {isPictureFullscreen && props.postText && (
                            <div className="caption">
                                {props.postText && `${props.postText}`}
                            </div>
                        )}
                    </div>

                    <div
                        className={
                            "hidden postText_profile " + ((isBoosted || isSafelyBoosted) && " boosted ")
                        }
                    >
                        {/* {isBoosted && "!!! ⚠️ "}
                    
          {isSafelyBoosted && "🔥 "}

          {props.post_seen_mod && "🟢 "}

          {props.username}

          {props.postText && ` • ${props.postText}`}

          {props.reported && " • "} */}


                        {/* count */}
                        {props.report_counter && `${props.report_counter} • `}

                        {/* gender */}
                        {props.gender === 0 && `M • `}
                        {props.gender === 1 && `F • `}

                        {/* age */}
                        {props.age && `${props.age} • `}

                        {/* username */}
                        {props.username && `${props.username} • `}

                        {/* country */}
                        {props.country && `${props.country} • `
                            // &&
                            // <><ReactCountryFlag countryCode={props.country} svg style={{ borderRadius: "0px" }} /> {" • "}</>
                        }

                        {/* verified profile */}
                        {props.verified_profile && props.verified_profile === true ? `Verified • ` : `Ø • `}

                        {/* datePost */}
                        {/* {props.datePost && `${moment(new Date(props.datePost * 1000), "YYYYMMDD").startOf('day').fromNow()} • `} */}
                        {props.datePost && `${dayjs.unix(props.datePost).fromNow()}`}

                        {/* {`by mod = ${props.mod_uid}`} */}

                        {/* <p>hello</p> */}

                        {/* reason */}
                        {/* {props.reason && `${props.reason}`} */}

                        {/* uid */}

                        {
                            <>
                                {props.reported &&
                                    (props.reason ? capitalizeFirstLetter(props.reason) : '""')}
                            </>
                        }

                        {isSafelyBoosted && " 🔥"}
                        {isBoosted && " ⚠️ !!!"}

                        <div className="id-fixed">{props.uid && `${props.uid}`}</div>
                    </div>

                    {/* <div className="description">
          <small className="time">{dayjs.unix(props.datePost).fromNow()}</small>

          <p>
            <b> {props.username} </b>
          </p>

        //   <p> ID: {props.userID} </p>

          <p> age: {props.age} </p>
          <p> country: {props.country} </p>
          <p> gender: {props.gender} </p>
          <p> bio: {props.bio} </p>

          <p>
            <b> {props.post_seen_by?.length ? "Seen by:" : "Unseen"} </b>

            {props.post_seen_by?.map((moderator) => {
              return <div>{moderator} </div>;
            })}
          </p>

          {props.verifyImg ? (
            <img
              className="verification-image"
              src={props.verifyImg}
              alt="Verification"
            />
          ) : (
            <p> No verification img available </p>
          )}
        </div> */}

                    {props.controls}
                </div>
            </>
        );
    }

    if (isActive && pathname === '/swiping/profile') {
        return (
            <>
                <div className={`post ${isActive && "fullscreen spinner-profile "}`}>
                    {/* {console.log("comp called ")} */}
                    {!isPictureFullscreen && (
                        <button onClick={() => (unselect(),
                            props.setEditData(), props.setLastKey(""))}
                            className="close" 
                            style={{backgroundColor:"white", border:"1px solid lightgray", borderRadius:"10px", padding:"10px", fontWeight:"bold", fontSize:"15px", zIndex:"999"}}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'darkgray', 
                            e.currentTarget.style.color = 'white')}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white',
                            e.currentTarget.style.color = 'black')}>
                            Close
                        </button>

                    )}
                    {
                        props.isProfileLoading &&
                        <div className="loader" ><ProgressSpinner /></div>
                    }
                    <div
                        className="picture" style={{ flexDirection: "column" }}
                        {...(isPictureFullscreen ? { fullscreen: "true" } : {})}
                    >
                        {isPictureFullscreen && (
                            <div
                                className="close-fullscreen"
                                onClick={() => (
                                    setIsPictureFullscreen(false)
                                )
                                }
                            >
                                Close

                            </div>
                        )}

                        {isPictureFullscreen && (
                            <h1 className="" style={{marginTop: "70px"}}>
                                {props.username && `${props.username}`}

                            </h1>
                        )}


                        {
                            <>
                                <div style={{ textAlign: "center", marginTop: "70px" }}>   <h1 style={{  }}>
                                    {props?.reported_User?.name && (
                                        <span style={{ color: props.mod_edit.name ? "red" : "black" }}>
                                            {props.reported_User.name}
                                        </span>
                                    )}, {props.reported_User.age && (
                                        <span style={{ color: props.mod_edit.age ? "red" : "black" }}>
                                            {props.reported_User.age}
                                        </span>
                                    )}
                                </h1>
                                </div>
                                {/* <p style={{ textAlign: "center", margin: "0px", fontSize: "20px", fontWeight: "700", color: "#c5c5c5", }}>name , age  </p> */}
                                {/* {props.username_warning?<p style={{marginBottom:"5px",textAlign:"center"}}>{" ⚠️ "+":"+" "+props.username }</p>:<p style={{marginBottom:"25px",textAlign:"center"}}></p>} */}

                                {!isPictureFullscreen && <div className="static-bio-profile">

                                    <div className="card-imag-main">

                                        {props.reported_User && <> {props.reported_User.imgVerif && props.reported_User.imgVerif !== "Unkown" ?
                                            <div className="card-bg-image" >
                                                {console.log("props======", props.mod_edit)}
                                                {props.mod_edit.verified == true ? (
                                                    <>
                                                        <div className="image-container">
                                                            {/* <div className="cross-image" >
                                                                ❌
                                                            </div> */}
                                                            <img
                                                                src={props.reported_User?.imgVerif}
                                                                className={isBoosted || isSafelyBoosted ? " boosted-img" : ""}
                                                                alt={`${props.username}'s profile`}
                                                            />
                                                        </div>
                                                    </>
                                                ) : <img
                                                    src={props.reported_User?.imgVerif}
                                                    className={isBoosted || isSafelyBoosted ? " boosted-img" : ""}
                                                    alt={`${props.username}'s profile`}
                                                />}

                                                <div className="image-title">
                                                    <p>ImgVerif {props.mod_edit.verified == true ? "❌" :""}</p>
                                                </div>
                                            </div>
                                            : <h3 style={{ textAlign: "center", margin: "0" }} > Image not available</h3>}
                                        </>}

                                        {props.reported_User && <> {props.reported_User.imgOne && props.reported_User.imgOne !== "Unkown" ?
                                            <div className="card-bg-image">
                                                {props.mod_edit.imgOne == true ? (
                                                    <>
                                                        <div className="image-container">
                                                            {/* <div className="cross-image" >
                                                                ❌
                                                            </div> */}
                                                            <img
                                                                src={props.reported_User?.imgOne}
                                                                className={isBoosted || isSafelyBoosted ? " boosted-img" : ""}
                                                                alt={`${props.username}'s profile`}
                                                            />
                                                        </div>
                                                    </>
                                                ) : <img
                                                    src={props.reported_User?.imgOne}
                                                    className={isBoosted || isSafelyBoosted ? " boosted-img" : ""}
                                                    alt={`${props.username}'s profile`}
                                                />}

                                                <div className="image-title">
                                                    <p>ImgOne {props.mod_edit.imgOne == true ? "❌" :""}</p>
                                                </div>
                                            </div>
                                            : <h3 style={{ textAlign: "center", margin: "0" }} > Image not available</h3>}
                                        </>}
                                        {props.reported_User && <> {props.reported_User.imgTwo && props.reported_User.imgTwo !== "Unkown" ?
                                            <div className="card-bg-image">
                                                <img
                                                    src={props.reported_User?.imgTwo}
                                                    className={isBoosted || isSafelyBoosted ? " boosted-img" : ""}
                                                    alt={`${props.username}'s profile`}
                                                />
                                                <div className="image-title">
                                                    <p>ImgTwo</p>
                                                </div>
                                            </div>
                                            : <h3 style={{ textAlign: "center", margin: "0" }} > Image not available</h3>}
                                        </>}

                                        {props.reported_User && <> {props.reported_User.imgThree && props.reported_User.imgThree !== "Unkown" ?
                                            <div className="card-bg-image">
                                                <img
                                                    src={props.reported_User?.imgThree}
                                                    className={isBoosted || isSafelyBoosted ? " boosted-img" : ""}
                                                    alt={`${props.username}'s profile`}
                                                />
                                                <div className="image-title">
                                                    <p>ImgThree</p>
                                                </div>
                                            </div>
                                            : <h3 style={{ textAlign: "center", margin: "0" }} > Image not available</h3>}
                                        </>}

                                    </div>


                                    {/* {props.first_profile_url ?
                            : <h3 style={{ textAlign: "center", margin: "0" }} > Image not available</h3>
                        } */}

                                    {/* <div className="" style={{display:"flex",flexDirection:"column",justifyContent:"center",}}> */}
                                    {/* <div className="bio-desc">
                            {console.log(props.displayBio, "================props.displayBio===-----------------")}
                            <p> {props.displayBio ? props.bio_warning && " 🚩 " : ""}{props.translate ? props.translate : props.displayBio ? props.displayBio : "Bio Not Available"}</p>

                        </div> */}
                                    {/* <button onClick={translates}>translate </button> */}
                                    {/* </div> */}
                                </div>}


                                {isPictureFullscreen && <> {props.first_profile_url ?
                                    <img
                                        src={props.first_profile_url}
                                        className={isBoosted || isSafelyBoosted ? " boosted-img" : ""}
                                        alt={`${props.username}'s profile`}
                                        style={{ objectFit: "contain" }}
                                    />
                                    : <h3 style={{ textAlign: "center", margin: "0" }} > Image not available</h3>}
                                </>}

                                {isPictureFullscreen && props.postText && (
                                    <div className="caption">

                                        {props.postText && `${props.postText}`}
                                    </div>
                                )}
                            </>
                        }
                    </div>

                    {/* <div className="description">
          <small className="time">{dayjs.unix(props.datePost).fromNow()}</small>

          <p>
            <b> {props.username} </b>
          </p>

        //   <p> ID: {props.userID} </p>

          <p> age: {props.age} </p>
          <p> country: {props.country} </p>
          <p> gender: {props.gender} </p>
          <p> bio: {props.bio} </p>

          <p>
            <b> {props.post_seen_by?.length ? "Seen by:" : "Unseen"} </b>

            {props.post_seen_by?.map((moderator) => {
              return <div>{moderator} </div>;
            })}
          </p>

          {props.verifyImg ? (
            <img
              className="verification-image"
              src={props.verifyImg}
              alt="Verification"
            />
          ) : (
            <p> No verification img available </p>
          )}
        </div> */}

                    {props.controls}

                    <div className="postText_profile title-box">
                        <h2> {props.reported_User.gender == 1 ? "Girl" : "Guy"} •  {props.reported_User.country} • {props.reported_User.verified ? `Verified • ` : `Ø • `} {props.date_report && `${dayjs.unix(props.date_report.seconds).fromNow()}`}
                        </h2>
                        {props.showModUID && <h3 style={{ textAlign: 'center', color:"#8b8b8b", fontWeight:"normal" }}>{`${props.mod_uid} `}{props.mod_comment ? (`• ${props.mod_comment}`) : ("")}</h3>}
                    </div>
                </div>

            </>
        );
    }


    // Not active

    if (!isActive && pathname !== '/swiping/profile') {
        return (
            <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
                {/* {console.log("isActive", isActive)} */}
                <div
                    className={"post " + ((isBoosted || isSafelyBoosted) && " boosted")}
                    onClick={handleSelect}
                    style={{ marginTop: "0px" }}
                >

                    <img
                        src={props.first_profile_url}
                        alt={`${props.username}'s profile`}
                        {...(user.developer ? { developer: "true" } : {})}
                    />

                    {

                        props.profile_data ?
                            undefined
                            :
                            <div className={"description "}>
                                {isBoosted && "!!! ⚠️"}
                                {isSafelyBoosted && "🔥 "}
                                {props.post_seen_mod && "🟢"}
                                {props.post_seen_mod && props.postText && " • "}
                                {props.postText && props.postText}
                                {isSafelyBoosted && " 🔥"}
                                {isBoosted && "⚠️ !!!"}
                            </div>
                    }

                </div>
            </div>
        );
    }


    if (!isActive && pathname === '/swiping/profile') {
        return (
            <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>

                <div
                    className={"post " + ((isBoosted || isSafelyBoosted) && " boosted")}
                    onClick={handleSelect}
                    style={{ marginTop: "0px" }}
                >

                    <img
                        src={props?.reported_user?.imgVerif}
                        alt={`${props.username}'s profile`}
                        {...(user.developer ? { developer: "true" } : {})}
                    />
                    {/* {console.log("props", props.profile_data)} */}
                    {
                        props.profile_data ?
                            <div className={"description"}>
                                {/* <p className="p-wrap">{
                                    props.mod_edit ? (
                                        <span className="mod">
                                            {(props.mod_edit.imgOne || props.mod_edit.name || props.mod_edit.age) === true && "🔴"}
                                            {props.mod_edit.verified === true && "❌"}
                                            {props.mod_comment && `🚫${props.mod_comment} • ${props.mod_uid} • ${dayjs.unix(props.date_report.seconds).fromNow()}`}
                                        </span>
                                    ) : ("")
                                }</p> */}

                                {isBoosted && "!!! ⚠️"}
                                {isSafelyBoosted && "🔥 "}
                                {props.post_seen_mod && "🟢"}
                                {props.post_seen_mod && props.postText && " • "}
                                {props.postText && props.postText}
                                {isSafelyBoosted && " 🔥"}
                                {isBoosted && "⚠️ !!!"}
                            </div> :
                            undefined
                    }

                </div>
            </div>
        );
    }


}
