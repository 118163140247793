import { Box, Button, TextField, Snackbar, Alert } from '@mui/material';
import TableDetails from './TableDetails';
import { age_verif_mod } from '../../../../app/firebase';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removePost2, selectPage, selectPosts, setActivePost, setPosts } from '../../../posts/postsSlice';

export default function ActivePost({ id, post }) {

    const {
        age_ai,
        age_req,
        age_url,
        birthday_ai,
        date,
        liveliness,
        priority,
        st_dev,
        uid,
        waiting_moderation
    } = post;

    let posts = useSelector(selectPosts);
    let loadedPage = useSelector(selectPage);

    // const [comment, setComment] = useState("");
    const [showComment, setShowComment] = useState({
        status: false,
        action: "BAN"
    });
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openErrorComment, setOpenErrorComment] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const dispatch = useDispatch();

    const buttonStyle = {
        backgroundColor: 'white',
        height: '70px',
        width: '150px',
        fontSize: "20px",
        fontWeight: '400',
        borderRadius: "40px",
        color: 'black',
        border: '1px solid lightgrey',
        transition: 'all 0.45s',
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        display: 'flex',
        flexDirection: 'column',  // Arrange items in a column
        justifyContent: 'center', // Center items vertically
        alignItems: 'center',     // Center items horizontally
        textAlign: 'center'
    }

    const handleControls = async (res, comment) => {
        console.log(`{
            res: ${res},
            uid: ${uid},
            comment: ${comment}
            }`);

        age_verif_mod({ res, uid, comment })
            .then((result) => {
                if (result?.data?.status !== 1) {
                    // setOpenError(true);
                    // setErrorMessage(result?.data?.message);
                    console.log(result?.data?.message);
                    return;
                }
                console.log(result?.data?.message);
                // setSuccessMessage(result?.data?.message);
                // setOpenSuccess(true);
            })
            .catch((error) => {
                console.log(error?.message);
                // setOpenError(true);
                // setErrorMessage(error.message || 'An error occurred');
            });

        dispatch(removePost2({
            id
        }));
        if(id === posts[loadedPage].length - 1) dispatch(setActivePost(id-1))

        // try {
        //     const result = await age_verif_mod({
        //         res,
        //         uid,
        //         comment
        //     })
        //     if (result?.data?.status !== 1) {
        //         setOpenError(true);
        //         setErrorMessage(result?.data?.message);
        //         return
        //     }
        //     console.log(result);
        //     setSuccessMessage(result?.data?.message);
        //     setOpenSuccess(true);
        // } catch (error) {
        //     setOpenError(true);
        //     setErrorMessage(error);
        // }
    }

    const handleClick = (action) => {
        let res = action === "BAN" ? 3 : action === "SUPPORT" ? 4 : -1;
        if (res === -1) {
            return alert("Contact Support because res integer is unspported");
        }

        const userComment = prompt(`Please enter a comment to ${action} this user:`);

        if (userComment !== null) {
            handleControls(res, userComment)
        } else {
            console.log('Ban action cancelled');
            // setOpenErrorComment(true)
        }
    }

    const handleKeyDown = (e) => {
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
            return //The key was pressed inside an input or textarea.
        }
        switch (e.key) {
            case 'j':
                handleControls(1);
                break;
            case 'f':
                handleControls(2)
                break;
            case 'i':
                handleClick("BAN");
                break;
            case 's':
                handleClick("SUPPORT");
                break;
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    })

    return (
        <div className='full-screen'>
            <div className='active-post'>
                <div className='details'>
                    <div style={{
                        position: 'fixed',
                        top: '2%',
                        left: '2%'
                    }}>
                        <Button variant="text" sx={buttonStyle} style={{
                            height: '40px',
                            width: '100px',
                            fontSize: "18px",
                            fontWeight: '400',
                        }}
                            onClick={() => {
                                dispatch(setActivePost(-1));
                            }}>
                            Close
                        </Button>
                    </div>
                    <div style={{ width: '250px' }}>
                    </div>
                    <div className='active-post-img'>
                        <img src={age_url} />
                        <div style={{
                            position: 'fixed',
                            top: '70vh',
                            backgroundColor: 'white',
                            width: '100px',
                            height:'30px',
                            fontSize:'19px',
                            textAlign: 'center',
                            // display:'flex',
                            // alignItems:'center',
                            // justifyContent: 'center',
                            borderRadius: '25px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
                        }}>{age_req > 17 ? '🟡 18+' : '🔴 13 - 17'}</div>
                    </div>
                    <div style={{ width: '250px' }}>
                        {/* {showComment.status &&
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch', backgroundColor: 'white' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    gap: '5px'
                                }}>
                                    <TextField
                                        sx={{
                                            backgroundColor: 'white'
                                        }}
                                        required
                                        id="outlined-required"
                                        label="Required Comment"
                                        value={comment}
                                        onChange={e => setComment(e.target.value)}
                                        multiline
                                        maxRows={4}
                                    />
                                    <Button variant="text" sx={{ ...buttonStyle, width: '65px', height: '55px', fontSize: "14px" }} onClick={() => handleClick(showComment.action)}>
                                        {showComment.action}
                                    </Button>
                                </div>
                            </Box>
                        } */}
                    </div>
                </div>
                <TableDetails age_ai={age_ai} age_req={age_req} liveliness={liveliness} />
                <div className='active-post-controls'>
                    <Button variant="text" sx={buttonStyle} onClick={() => handleControls(1)}>
                        <Box display="flex" alignItems="center">
                            {/* <DoneOutlineOutlinedIcon sx={{ fontSize: 15, marginRight: 1 }} /> */}
                            <Box>Accept</Box>
                        </Box>
                        <Box fontSize="18px" color="grey" >J</Box>
                    </Button>
                    <Button variant="text" sx={buttonStyle} onClick={() => handleControls(2)}>
                        <Box display="flex" alignItems="center">
                            {/* <CloseOutlinedIcon sx={{ fontSize: 15, marginRight: 1 }} /> */}
                            <Box>Reject</Box>
                        </Box>
                        <Box fontSize="18px" color="grey" >F</Box>
                    </Button>
                    <Button variant="text" sx={buttonStyle} onClick={() => {
                        handleClick("BAN");
                    }}>
                        <Box display="flex" alignItems="center">
                            {/* <MobileOffOutlinedIcon sx={{ fontSize: 15, marginRight: 1 }} /> */}
                            <Box>Ban</Box>
                        </Box>
                        <Box fontSize="18px" color="grey">I</Box>
                    </Button>
                    <Button variant="text" sx={buttonStyle} onClick={() => {
                        handleClick("SUPPORT");
                    }}>
                        <Box display="flex" alignItems="center">
                            {/* <DeleteOutlineOutlinedIcon sx={{ fontSize: 15, marginRight: 1 }} /> */}
                            <Box>Support</Box>
                        </Box>
                        <Box fontSize="18px" color="grey" >S</Box>
                    </Button>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={openErrorComment}
                autoHideDuration={2500}
                onClose={() => setOpenErrorComment(false)}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    "Please enter a comment to be able to {showComment.action}"
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={openError}
                autoHideDuration={2500}
                onClose={() => setOpenError(false)}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    "Error: {errorMessage}"
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={openSuccess}
                autoHideDuration={300}
                onClose={() => {
                    setOpenSuccess(false);
                    let updatedPosts = [
                        ...posts[loadedPage],
                    ]
                    updatedPosts.splice(id, 1)
                    dispatch(setPosts({
                        posts: updatedPosts,
                        page: loadedPage
                    }))
                }}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    "Success! {successMessage}"
                </Alert>
            </Snackbar>
        </div>
    )
}

