import { db } from "../../../../app/firebase";

export const fetchPosts = async () => {
    try {
        const res = await db.collection('age')
            .where("waiting_moderation", "==", true)
            .orderBy("priority", "desc")
            .orderBy("date", "desc")
            .limit(20)
            .get();

        let lastVisible = res?.docs[res?.docs?.length - 1];

        const postsArray = [];
        res.forEach(post => {
            postsArray.push(post.data())
        });

        return [postsArray, lastVisible]
    } catch (error) {
        console.error("error occured while fetching posts:", error)
    }
}

export const fetchNext = async (startAfter) => {
    try {
        const res = await db.collection('age')
            .where("waiting_moderation", "==", true)
            .orderBy("priority", "desc")
            .orderBy("date", "desc")
            .startAfter(startAfter)
            .limit(20)
            .get();

        let lastVisible = res?.docs[res?.docs?.length - 1];

        const postsArray = [];
        res.forEach(post => {
            postsArray.push(post?.data())
        });
        return [postsArray, lastVisible, res?.docs?.length]
    } catch (error) {
        console.error("error occured while fetching posts:", error)
    }
}