import { db, sendNotification } from "../../app/firebase";
import store from "../../app/store";
import firebase from 'firebase';
import { setPosts, changePage } from "./postsSlice";
const currentTimestamp = firebase.firestore.Timestamp.now();

function logError(error) {
    console.error(error, " ==============");
}

export async function getPosts({
    reported = false,
    unread = false,
    page = 0,
    startAt = {},
    startAfter = {},
    switchS = false,
    uid,
    // filter = false,
    mod_res,
    range
}) {

    var postsRef

    // let postsRef = unread
    //   ? db
    //       .collection("public_post")
    //       .where("post_seen_mod", "==", false)
    //       .where("finish_processing", "==", true)
    //       .orderBy("datePost", "desc")
    //       .limit(limit)
    //   : reported
    //   ? db
    //       .collection("moderation_post")
    //       .where("moderated", "==", false)
    //       .orderBy("datePost", "desc")
    //       .limit(limit)
    //   : db
    //       .collection("public_post")
    //       .where("finish_processing", "==", true)
    //       .orderBy("datePost", "desc")
    //       .limit(limit);

    // if (startAfter) postsRef = postsRef.startAfter(startAfter);
    // if (startAt) postsRef = postsRef.startAt(startAt);

    // ---------------------------------------------------------
    // let postsRef = db
    //     .collection("profile")
    //     // .where("post_seen_mod", "==", false)
    //     // .where("finish_processing", "==", true)
    //     .orderBy("date", "desc")
    //     .limit(limit)


    if (window.location.pathname === '/swiping/profile') {
        if (!switchS) {
            // console.log("this is getPosts -- no history -- and swiping/profile ****")
            postsRef = db.collection('swiping')
                .where('type', '==', 'profile_report')
                .where('waiting_moderation', '==', true)
                .orderBy("date_report", "desc")
                .limit(20);
        } else {
            postsRef = db.collection('swiping')
                .where('type', '==', 'profile_report')
                .where('waiting_moderation', '==', false)
                .where(
                    'mod_res_date', '<=', range ? new Date(range) : currentTimestamp
                )
                .orderBy("mod_res_date", "desc")

            if (uid) {
                console.log("there is uid");
                postsRef = postsRef.where("mod_uid", "==", uid);
            }
            if (mod_res) {
                console.log("there is mod_res");
                postsRef = postsRef.where("mod_res", "==", mod_res)
            }
            postsRef = postsRef.limit(20);

        }
        // console.log("postsRef", postsRef);
    }
    else {
        postsRef = db.collection("profile").where("report_counter", ">=", 1).orderBy("report_counter", "desc").orderBy("date", "desc").limit(20)
    }


    if (JSON.stringify(startAfter) !== '{}') postsRef = postsRef.startAfter(startAfter);
    //here the problem was that the first time 
    //the nextpage is called, the last seen was false and then set to {}
    //it was fixed by simply modifiying the initial value of the parameter of this function getPosts()
    if (JSON.stringify(startAt) !== '{}') postsRef = postsRef.startAt(startAt);
    // ---------------------------------------------------------


    return postsRef
        .get()
        .then((snapshot) => {
            let posts = [];
            if (snapshot.docs.length === 0) {
                console.log("there is no posts");
                store.dispatch(setPosts({
                    posts,
                    reported,
                    unread,
                    page,
                    switchS,
                    range, mod_res
                }));
                return { lastSeen: {}, firstSeen: {}, noPosts: true, err: "error" };
            }
            console.log("fetched the next page")
            snapshot.forEach((item, i) => {
                let post = { ...item.data(), id: item.id };
                post.datePost = post.date?.seconds;
                post.imageUrlPost = post.profile_img;
                post.date = undefined;
                post.profile_img = undefined;
                post.profile_data = true;

                if (post.seen_mod_date === undefined) delete post.seen_mod_date;
                posts.push(post);
            });

            let lastVisible = snapshot.docs[snapshot.docs.length - 1];
            console.log("+-+-", posts)
            let data = {
                posts,
                reported,
                unread,
                page,
                switchS,
                range, mod_res
            }
            if (page === 1) {
                console.log(data, "this is the posts data");
            }
            store.dispatch(setPosts(data));
            if (posts.length) store.dispatch(changePage(page));

            return { lastSeen: lastVisible, firstSeen: snapshot.docs[0] };
        })
        .catch((err) => {
            console.error("Error with getting posts from firebase:", err);
            return { lastSeen: {}, firstSeen: {}, err: "error" };
        });
}

export function deleteNotification({ fcm_token }) {
    sendNotification({ registrationToken: fcm_token, delete: true })
        .then()
        .catch(logError);
}
export function keepNotification({ fcm_token }) {
    sendNotification({ registrationToken: fcm_token, keep: true })
        .then()
        .catch(logError);
}
