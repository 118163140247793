import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

import {
    firebaseDeletePost,
    db,
    firestore,
    logAnalytics,
    google_translate_moderation,
    ban_user_mod,
    get_counter_mod,
    res_profile_report_mod_swiping
} from "../../app/firebase";

import { getPosts, deleteNotification, keepNotification } from "./model";
import { profile_moderation_response, bio_moderation } from '../../app/firebase'
import firebase from "firebase";
import store from "../../app/store";

import sendDeleteMessage from "./functions/sendDeleteMessage";

import { selectUser } from "../auth/userSlice";
import {
    nextPost,
    selectPosts,
    setActivePost,
    selectActivePost,
    selectPostsLoading,
    selectReportedPosts,
    selectPage,
    changePage,
    removePost,
    setPostUsername,
    selectUnreadPosts,
    setReportedPostSwiping,
    removePostSwiping,
    setPosts
} from "../profile/postsSlice";
// } from "../posts/postsSlice";

import Post from "./Post";
import { deleteHistory } from "./functions/deleteHistory";
import { Button } from "primereact/button";
import { createTest } from "./functions/createTest";
import { Controls } from "./Controls";
import { keepHistory } from "./functions/Keep.functions";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import SearchUser from "../search/SearchUser";

function Profile(props) {
    let { uid } = useSelector(selectUser);
    var lastDocSnapShot = null;
    const [key, setKey] = useState("");
    const [banPrompt, setBanPrompt] = useState(false);
    const [textPrompt, setTextPrompt] = useState(false);
    const [reasonPrompt, setReasonPrompt] = useState(false);
    const [verifImg, setVerifyImg] = useState("");
    const [isPictureFullscreen, setIsPictureFullscreen] = useState(false);
    const [usernameForProfile, setUsernameForProfile] = useState("")
    const [bio, setBio] = useState("");
    const [lastSeen, setLastSeen] = useState({});
    const [newName, setNewName] = useState("")
    const [newArray, setNewArray] = useState()
    const [switchS, setSwitchS] = useState(false);
    const ref = useRef(null);
    //-----------------------------------------------------------------------------------
    const [totalProfileCount, setTotalProfileCount] = useState(undefined)
    const [pageNumber, setPageNumber] = useState(1);
    const [page, setPage] = useState(0);
    const [profileInfo, setProfileInfo] = useState(undefined)
    const [userId, setUserId] = useState("")
    const [bioValue, setBioValue] = useState("")
    const [username, setUsername] = useState("")
    const [editData, setEditData] = useState()
    const [age, setAge] = useState("")
    const [translate, setTranslate] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [isBack, setIsBack] = useState(false);
    const [switchHistory, setSwitchHistory] = useState(false);
    const [countResponse, setCountResponse] = useState("-");
    const [updatedPosts, setUpdatedPosts] = useState([]);
    const pathLocation = useLocation();
    const [lastKey, setLastKey] = useState('');
    const [refreshedData, setRefreshedData] = useState();
    const [prerefreshedData, setPrerefreshedData] = useState();
    const [backrefreshedData, setBackrefreshedData] = useState();
    const [lastDoc, setLastDoc] = useState(null);
    const [swipingHistoryData, setSwipingHistoryData] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [admins, setAdmins] = useState([{ label: uid, value: uid }]);
    const [adminFilter, setAdminFilter] = useState(null);
    const [range, setRange] = useState('');
    const [links, setLinks] = useState('');
    const [showLinksButton, setShowLinksButton] = useState(false);
    const [showLinks, setShowLinks] = useState(false);
    const [deactivateNext, setDeactivateNext] = useState(false);
    const { pathname } = pathLocation;
    const isProfilePage = pathname === '/swiping/profile'
    let allPosts = useSelector(selectPosts);
    let numberOfPages = allPosts.length - 1;

    var selected_mod_uid = adminFilter;
    const [showPopup, setShowPopup] = useState({ show: false, ID: undefined });


    const [actionFilter, setActionFilter] = useState(0);
    const actionOptions = [
        { label: 'All', value: 0 },
        { label: 'Keep', value: 1 },
        { label: 'Delete', value: 2 },
        { label: 'Ban', value: 3 },
    ];

    const showClearIcon = actionFilter !== '';

    useEffect(() => {
        console.log("lastSeen changed:", lastSeen)
        const Page = selectPage(store.getState());
        console.log("page is :", Page)
        const Posts = selectPosts(store.getState());
        console.log("Posts are : *****", Posts)
        console.log("number of pages is :", numberOfPages)
    }, [numberOfPages])

    function loadAdmins() {
        const adminsArray = [];
        db.collection('admin')
            .where('admin', '==', true)
            .get()
            .then((snapshot) => {
                snapshot.forEach((admin) => {
                    const { userID } = admin.data();
                    // console.log(userID, '-------------------userid');
                    adminsArray.push({ label: userID, value: userID });
                });
                setAdmins((admins) => [...admins, ...adminsArray]);
            });
    }
    useEffect(() => {
        loadAdmins()
    }, []);


    const location =
        props.reported
            ? "all_reported"
            : props?.unread
                ? "new_posts"
                :
                "all_posts";


    function handleDelete(reason) {
        deletePost(reason);
    }

    const translates = () => {
        if (bioValue) {
            google_translate_moderation({ bio: bioValue })
                .then((res) => {
                    if (res.data.status == 1) {
                        setTranslate(res.data.english);
                        //   window.alert(res.data.english);
                    } else {
                        window.alert(`${res?.data?.message}`)
                    }
                })
        }
        //   alert("res.data.english");
    }

    function handleDeleteUserName() {

        // console.log("first", userId)
        // console.log("==========usernameForProfile===================", usernameForProfile)
        // return

        const data = {
            userID: userId,
            username: usernameForProfile,
            // location:"all_reported", "new_posts" , "all_posts" , "history"
            location: "profile",
        }
        const usernameModeration = firebase
            .functions()
            .httpsCallable("username_moderation")

        usernameModeration(data)
            .then(res => {
                const { message, status, username } = res.data
                // console.log(res?.data,"--------------");
                if (status === 1) {
                    setNewName(username)
                    // return
                    // store.dispatch(
                    //     setPostUsername({
                    //         username: username,
                    //         reported: false,
                    //         unread: false,
                    //     })
                    // )
                    // console.log(setPostUsername,"------")
                    // setIsDeleteUserName(true);
                    // deleteUsername(username)
                    // console.log(username, "newwwwwwww");
                } else {
                    // setIsDeleteUserName(false);
                }
            })
            .catch(err => {
                console.log(err);
            })

    }

    let user = useSelector(selectUser);
    let unreadPosts = useSelector(selectUnreadPosts);
    let reportedPosts = useSelector(selectReportedPosts);

    // console.log(allPosts, "allPosts");

    let loadedPage = useSelector(selectPage);
    let postsCategory =
        props.unread
            ? unreadPosts
            : props.reported
                ? reportedPosts
                :
                allPosts;

    let posts = useMemo(() => {
        return postsCategory[loadedPage] || [];
    }, [loadedPage, postsCategory, window.location.pathname]);

    // console.log("postsCategory", postsCategory, loadedPage);

    useEffect(() => {
        setNewArray(() => posts ? posts : [])
    }, [posts, window.location.pathname]);
    // console.log("newArray========", newArray);


    useEffect(() => {

        setNewArray(
            () =>
                posts?.map((post) => {

                    if (post?.id == userId) {
                        const postValue = { ...post, ['username']: newName }
                        return postValue
                    }
                    return post
                })
        )
    }, [newName])


    //-----------------------------------------------------------------------------------

    useEffect(() => {
        fetchTotalProfileCount()
        // getProfileData()
    }, [props]);


    const fetchTotalProfileCount = () => {
        db
            .doc("profile/info")
            .get()
            .then((res) => {
                // setTotalProfileCount(res.data().counter_profile_one)
                setProfileInfo(res.data())
            })
            .catch(err => {
                console.log(err);
            })
        // db.collection('profile').get().then((doc) => {
        //     doc.forEach((d) => {
        //         console.log(d.data(), "---------------RES-----------------");
        //     })
        // })
        // console.log(snapshot.docs, "---------------RES-----------------");

    }

    const getProfileData = async () => {
        const profileData = []
        db.collection("profile")
            .limit(15)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    profileData.push(doc.data())
                });
                // console.log(profileData);
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }
    //-----------------------------------------------------------------------------------

    let postsLoading = useSelector(selectPostsLoading);
    let activePost = useSelector(selectActivePost);
    const isActivePostBoosted = posts[activePost]?.boost_request;

    let dispatch = useDispatch();
    const {
        reported, unread,
    } = props;

    useEffect(() => {
        getPosts({ reported: reported, page: 1, unread: unread }).then(
            ({ lastSeen }) => {
                setLastSeen(lastSeen);
                console.log("this is last seen of the first page")
            }
        );
    }, [reported, unread]);

    function preloadImages(urls) {
        if (urls.length < 0) return;
        urls.forEach(url => {
            const img = new Image();
            img.src = url;
            //   console.log("those are the images of the next user:")
            // Optionally handle onload and onerror events
            // img.onload = () => console.log(`Loaded: ${url}`);
            // img.onerror = () => console.error(`Failed to load: ${url}`);
        });
        console.log("those are the images of the next user:", urls)
        // console.log("posts[activePost+1]", posts[activePost+1].reported_user)
    }

    useEffect(() => {
        console.log("active post changed")
        if (activePost >= 0 && !switchS) {
            refreshDataSwiping()
            // console.log("activePost==========", activePost);
        }

        if (activePost === -1 && posts.length && !switchS) {

            setIsBack(false)
            setRefreshedData()
            refreshDataSwiping(0)
        }
        if (pathname === '/swiping/profile') {
            const nextPost = posts[activePost + 1]?.reported_user;

            if (nextPost) {
                const imagesToPreload = [
                    nextPost.first_profile_url,
                    nextPost.imgVerif,
                    nextPost.imgOne,
                    nextPost.imgTwo
                ].filter(url => !!url); // Filter out any undefined or null values

                if (imagesToPreload.length > 0) {
                    preloadImages(imagesToPreload);
                }
            }
        }
    }, [activePost, posts])


    useEffect(() => {

        if (activePost >= 0 && !switchS) {
            preRefreshDataSwiping(activePost)
        }

    }, [activePost, posts])

    // console.log(refreshedData,"---------- refreshedData -------");


    function nextPage() {
        // setIsLoading(true);
        let query;
        let startAfterValue;

        //we need to make sure that if there is loaded pages to just show them
        if (loadedPage < numberOfPages) {
            return store.dispatch(changePage(loadedPage + 1));
        }
        //after we made sure there is no loaded pages and next is deactivated we return
        if (deactivateNext) {
            return console.log("NEXT DEACTIVATED")
        }
        setIsLoading(true)
        if (selected_mod_uid) {
            query = {
                reported: props.reported,
                page: loadedPage + 1,
                unread: props.unread,
                startAfter: lastSeen,
                switchS: switchS,
                uid: selected_mod_uid,
                mod_res: actionFilter,
                range
            }
            if (lastSeen && lastSeen.data) {
                startAfterValue = lastSeen.data().date;
            } else {
                startAfterValue = null;
            }
        }
        else {
            // console.log("switchS-------", switchS, actionFilter);
            // const fetchedPagesCount = 
            query = {
                reported: props.reported,
                page: loadedPage + 1,
                unread: props.unread,
                startAfter: lastSeen,
                switchS: switchS,
                mod_res: actionFilter,
                range
            }
            if (lastSeen && lastSeen.data) {
                startAfterValue = lastSeen.data().date;
            } else {
                startAfterValue = null;
            }
        }
        if (isLoading) return;

        getPosts(query)
            .then(({ lastSeen, firstSeen, noPosts }) => {
                // console.log("the get posts function is called in the next page");
                if (noPosts) {
                    setDeactivateNext(true)
                }
                setLastSeen(lastSeen);
                setIsLoading(false)
            })
            .catch((err) => console.error("Error with getting posts", err));
    }

    function previousPage() {
        if (loadedPage - 1) {
            dispatch(changePage(loadedPage - 1));
        }
    }

    function lock(reason = {}, post = {}) {
        db.collection("banned_moderation")
            .doc(posts[activePost]?.userID)
            .set(
                {
                    ...reason,
                    unlock_all: false,
                    report_counter_mod: firestore.FieldValue.increment(1),
                    // banned_unlock_date: firestore.Timestamp.fromDate(
                    //     dayjs().add(3, "hour").toDate()
                    // ),
                    report_mod_uid: user?.uid,
                    report_mod_date: firestore.FieldValue.serverTimestamp(),
                    last_post_reported: true,
                    postText: post?.postText ?? "",
                    imageUrlPost: post?.imageUrlPost ?? "",
                },
                { merge: true }
            );
    }

    function deletePost(reason, post, ban = false) {
        let postToDelete = post ?? posts[activePost];

        deleteHistory({
            post: postToDelete,
            user,
            reason,
            location,
            ...(ban ? { action: "ban" } : {}),
        });

        log("remove_img_mod", reason?.reason_report);

        deleteNotification({ fcm_token: postToDelete.fcm_token });

        sendDeleteMessage({
            username: postToDelete.username,
            uid: postToDelete.userID,
            emoji: postToDelete.emoji,
            reason,
        });


        lock(reason, postToDelete);

        adminAction({ newPosts: props.unread });

        firebaseDeletePost(postToDelete.id, postToDelete.userID, props.reported);

        if (reason) {
            setReasonPrompt(false);
            setTextPrompt(false);
            setBanPrompt(false);
        }
    }

    function adminAction({ newPosts = false }) {
        const actionObject = newPosts
            ? {
                new_post_uid: user.uid,
                new_post_date: firestore.FieldValue.serverTimestamp(),
            }
            : {
                last_action: Date.now(),
                userID: user.uid,
                last_action_timestamp: firestore.FieldValue.serverTimestamp(),
            };
        db.collection("admin").doc("info").set(actionObject, { merge: true })
            .then(res => {
                console.log(res);
            })

    }

    function log(event, reason = null) {
        const logObject = {
            event_type: event,
            email: "email@gmail.com",
            userID: posts[activePost].userID,
            mod_location: props.unread
                ? "new_posts"
                : props.reported
                    ? "all_reported"
                    : "all_posts",
            imageUrlPost: posts[activePost].imageUrlPost,
            postText: posts[activePost].postText,

            data: {
                reason,
                ...(posts[activePost]?.response_time &&
                    (event === "skip_post" ||
                        event === "keep_img_mod" ||
                        event === "remove_img_mod")
                    ? {
                        response_time: dayjs().unix() - posts[activePost]?.response_time,
                    }
                    : {}),
            },
        };

        logAnalytics(logObject).catch((err) => console.error("log err", err));
    }

    function deleteBio() {
        db.collection("users")
            .doc(posts[activePost].userID)
            .set(
                {
                    bio: "",
                },
                { merge: true }
            )
            .then(() => setBio(""));
    }

    function deleteVerificationImg() {
        setTextPrompt(false);
        setVerifyImg("");
        db.collection("users").doc(posts[activePost].userID).set(
            {
                verifImgUrl: "",
            },
            { merge: true }
        );
    }

    function deleteUsername(p) {
        // console.log(p,"----fing old user name--------")
        // return;
        db.collection(props.reported ? "moderation_post" : "public_post")
            .doc(posts[activePost].userID)
            .set({ username: p.username }, { merge: true })
            .then(() => { });

        if (props.reported) {
            db.collection("public_post")
                .doc(posts[activePost].userID)
                .set({ username: p.username }, { merge: true })
                .then(() => { });
        }

        db.collection("users")
            .doc(posts[activePost].userID)
            .set(
                {
                    username: p.username,
                },
                { merge: true }
            )
            .then(() => {
                setTextPrompt(false);
                dispatch(
                    setPostUsername({
                        username: p.username,
                        reported: props.reported,
                        unread: props.unread,
                    })
                );
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // --------- Swiping -------------



    function deleteNameSwiping() {
        const { uid } = posts[activePost];

        if (uid) {

            var mod_res_data = {
                name: true,
                // res: 2
            }
            combineData(mod_res_data)
            // let response = await res_profile_report_mod_swiping({ refresh: false, reported_uid: uid, mod_res_data })

        }

    }

    function deleteAgeSwiping() {

        const { uid } = posts[activePost];

        if (uid) {

            var mod_res_data = {
                age: true,
                // res: 2
            }
            combineData(mod_res_data)

            // let response = await res_profile_report_mod_swiping({ refresh: false, reported_uid: uid, mod_res_data })

            // console.log(response, "response ---   delete Age ");

            // if (response.data.status) {
            //     await refreshDataSwiping(uid)
            // }

        }
    }

    function deleteVerifySwiping() {

        const { uid } = posts[activePost];

        if (uid) {

            var mod_res_data = {
                verified: true,
                // res: 2
            }

            combineData(mod_res_data)

            // console.log(mod_res_data, "mod_res_data ---   delete verified");

            // let response = await res_profile_report_mod_swiping({ refresh: false, reported_uid: uid, mod_res_data })

            // console.log(response, "response ---   delete Age ");

            // if (response.data.status) {
            //     await refreshDataSwiping(uid)
            // }

        }
    }

    function deleteImgSwiping(key) {

        const { uid } = posts[activePost];

        if (uid) {

            var mod_res_data = {
                // res: 2
            }

            if (key === "a") {
                mod_res_data.imgOne = true
            } else if (key === "s") {
                mod_res_data.imgTwo = true
            } else if (key === "d") {
                mod_res_data.imgThree = true
            }

            combineData(mod_res_data)

            // let response = await res_profile_report_mod_swiping({ refresh: false, reported_uid: uid, mod_res_data })

            // if (response.data.status) {
            //     await refreshDataSwiping(uid)
            // }

        }
    }


    function combineData(newData) {
        setEditData(prevData => {
            return { ...prevData, ...newData };
        });
    }


    async function SwipingBanProfile() {
        const reason = prompt("Reason for Ban")
        if (!reason || reason == "") {
            // console.log("Please Fill Reason");
        } else {

            const { uid } = posts[activePost];

            if (uid) {

                var mod_res_data = {
                    comment: reason,
                    ban: true,
                    res: 3
                }

                // dispatch(removePostSwiping({ id: uid }));
                combineData(mod_res_data)

                // let response = await res_profile_report_mod_swiping({ refresh: false, reported_uid: uid, mod_res_data })

                // console.log(response, "response 1");

                // if (response.data.status) {
                //     // next();
                //     // return;
                // }

            }
        }
    }

    async function SwipingSafeProfile() {
        var mod_res_data = {}

        const { uid } = posts[activePost];

        if (uid) {

            if (!editData) {
                mod_res_data = {
                    res: 1
                }
            } else if (editData && editData.ban === true) {
                mod_res_data = {
                    ...editData, ...{ res: 3 }
                }
            } else {
                mod_res_data = {
                    ...editData, ...{ res: 2 }
                }
            }
            setEditData()
            setLastKey("")
            console.log(mod_res_data, "mod_res_data----1");
            dispatch(removePostSwiping({ id: uid }));
            let response = await res_profile_report_mod_swiping({ refresh: false, reported_uid: uid, mod_res_data })
            console.log(response, "response 1");
            // setRefreshedData()

            // if (response.data.status) {
            //     dispatch(removePostSwiping({ id: uid }));
            // }
        }

    }

    function undoDataSwiping() {
        setEditData();
        setLastKey("")
    }


    async function refreshDataSwiping(index) {

        try {
            const { uid } = posts[index ?? activePost];

            if (uid) {
                // console.log("uid", uid);
                // setIsProfileLoading(true);
                const res = await res_profile_report_mod_swiping({ refresh: true, reported_uid: uid });
                console.log("reported user changed with uid")
                return setRefreshedData(res.data?.reported_user);
                // setIsProfileLoading(false);
            }
            if (isBack) {
                console.log("reported user changed with isback")
                setRefreshedData(backrefreshedData);
            } else {
                console.log("reported user changed with ispre")
                setRefreshedData(prerefreshedData);
            }

            // console.log("uid000000", uid, res.data?.reported_user);
            // if (isBack) {
            //     setRefreshedData(backrefreshedData || res.data?.reported_user);
            // } else {
            //     setRefreshedData(prerefreshedData || res.data?.reported_user);
            // }

        } catch (error) {
            console.error("Error refreshing data:", error);
            // Handle error state appropriately
        }
    }

    // Modify the preRefreshDataSwiping function to accept an index

    async function preRefreshDataSwiping(index) {

        try {
            console.log("this is preRefreshDataSwiping");
            var pre = posts[index + 1]?.uid
            var back = posts[index - 1]?.uid

            // const { uid } = posts[index];
            const res1 = await res_profile_report_mod_swiping({ refresh: true, reported_uid: pre });
            const res2 = await res_profile_report_mod_swiping({ refresh: true, reported_uid: back });

            // Update the respective data based on the index
            // if (index === activePost + 2) {
            setPrerefreshedData(res1.data?.reported_user);
            // } else if (index === activePost - 2) {
            setBackrefreshedData(res2.data?.reported_user);
            // }
            if (activePost == -1) {
                setPrerefreshedData(res1.data?.reported_user);
            }


        } catch (error) {
            console.error("Error pre-refreshing data:", error);
            // Handle error state appropriately
        }
    }

    // console.log(prerefreshedData, "--------- prerefreshedData ----------");
    // console.log(posts, "posts");
    // console.log(newArray,"newArray");
    // console.log(editData, "editData------------20");

    // --------- Swiping  End -------------  //


    function seePost() {
        db.collection(props.reported ? "moderation_post" : "public_post")
            .doc(posts[activePost].id)
            .set(
                {
                    post_seen_mod: true,
                    post_seen_by: firestore.FieldValue.arrayUnion(user.uid),
                    seen_mod_date: firestore.FieldValue.serverTimestamp(),
                },
                { merge: true }
            );
    }

    const sendBio = () => {
        // username,bioValue
        // bio_moderation
        // console.log("This is Bio Handle Function", bioValue, username, userId);
        bio_moderation({ userID: userId, username, location: 'profile', bio: bioValue }).then((res) => {
            console.log("Call bio_moderation function successfully.");
            console.log(res);
            setBioValue("")
        }).catch((err) => {
            console.log(err);
            window.alert("Please be sure to be Logged In")
        })
    }


    const keepPost = (docID) => {

        profile_moderation_response({ docID: docID, response: 1 }).then((res) => {
            console.log(res);
            next()
        }).catch((err) => {
            console.log(err);
        })

        return
        adminAction({ newPosts: props.unread });

        keepHistory({ post: posts[activePost], user, location });

        if (!props.reported) {
            seePost();
            next();
            return;
        }

        const postID = posts[activePost].id;

        keepNotification({ fcm_token: posts[activePost].fcm_token });

        db.collection("banned_moderation")
            .doc(postID)
            .set({ last_post_reported: false }, { merge: true });

        db.collection("public_post")
            .doc(postID)
            .set(
                {
                    finish_processing: true,
                    img_state: 5,
                    post_seen_mod: true,
                    datePost: firestore.FieldValue.serverTimestamp(),
                    post_seen_by: firestore.FieldValue.arrayUnion(user.uid),
                    seen_mod_date: firestore.FieldValue.serverTimestamp(),
                    ...(isActivePostBoosted ? { boost_safe: true } : {}),
                },
                { merge: true }
            )
            .then((res) => {
                log("keep_img_mod");
                console.log("keep success ", postID);
            })
            .catch((err) => {
                console.error(err);
            });

        if (props.reported) {
            db.collection("moderation_post")
                .doc(postID)
                .set(
                    {
                        moderated: true,
                        post_seen_mod: true,
                        post_seen_by: firestore.FieldValue.arrayUnion(user.uid),
                        seen_mod_date: firestore.FieldValue.serverTimestamp(),
                        last_post_reported: false,
                    },
                    { merge: true }
                )
                .then(() => { })
                .catch((err) => {
                    console.log(err);
                });
        }
        next(false);
        dispatch(removePost({ id: postID }));
    };


    const displayBan = (docID) => {
        const reason = prompt("Reason for Ban")
        if (!reason || reason == "") {
            console.log("Please Fill Reason");

        } else {
            const data = { docID: docID, response: 3, reason_ban: reason }
            profile_moderation_response(data).then((res) => {
                console.log(res);
                next();
            }).catch((err) => {
                console.log(err);
            })
        }
        return
        setBanPrompt(true);
    };

    function ban() {
        let uid = posts[activePost].userID;
        db.collection("banned_moderation")
            .doc(uid)
            .set({ banned: true }, { merge: true })
            .then(() => {
                adminAction({ newPosts: props.unread });
                lock();
                deletePost(null, null, true);
                log("ban_img_mod");
            })
            .catch((err) => {
                console.error("failed ban", err);
            });
        setBanPrompt(false);
    }

    function hide() {
        let uid = posts[activePost].userID;
        db.collection("banned_moderation")
            .doc(uid)
            .set({ hide: true }, { merge: true })
            .then(() => {
                log("ban_img_mod");
                lock();
                adminAction({ newPosts: props.unread });
                deletePost(null, null, true);
            })
            .catch((err) => {
                console.log("failed hide", err);
            });
        setBanPrompt(false);
    }

    const supreme = () => {
        let uid = posts[activePost].userID;
        db.collection("banned_moderation")
            .doc(uid)
            .set({ supreme: true }, { merge: true })
            .then(() => {
                log("ban_img_mod");
                lock();
                adminAction({ newPosts: props.unread });
                deletePost(null, null, true);
            })
            .catch(() => {
                console.log("failed supreme");
            });
        setBanPrompt(false);
    };

    const next = async (skip = true) => {

        if (pathname === '/swiping/profile') {
            setIsBack(false)
            // setRefreshedData(posts[activePost + 1].reported_user)
            // preRefreshDataSwiping(activePost + 2); // Preload data for the next post
        }

        seePost();
        if (!skip) return;

        if (activePost + 1 === posts.length) {
            nextPage();
            console.log("active Post 1");
            dispatch(setActivePost(-1));
        } else {
            console.log("next Post 1");
            dispatch(nextPost());
        }
    };

    const back = async () => {
        if (pathname === '/swiping/profile') {
            setIsBack(true)
            // setRefreshedData(posts[activePost - 1].reported_user)
            // preRefreshDataSwiping(activePost - 2); // Preload data for the previous post
        }
        dispatch(setActivePost(activePost - 1));
    }


    function handleSetCopy(id) {
        navigator.clipboard.writeText(id).then(() => console.log("ok"))
    }

    function handleKeyDown(e) {
        setKey(e.key);
    }

    function handleKeyUp(e) {
        setKey("");

        if (e.key === " ") {
            if (activePost === -1) {
                dispatch(setActivePost(0));
            } else {
                setIsPictureFullscreen(!isPictureFullscreen);
            }
        }
        if (e.key.toLowerCase() === "u") {
            handleDeleteUserName()
        }

        //          -------------------- swiping ---------------           //

        if (e.key === "n" && isProfilePage) {
            deleteNameSwiping()
        }

        if (e.key === "z" && isProfilePage) {
            undoDataSwiping()
        }


        if (lastKey !== 'f' && e.key === "a" && isProfilePage) {
            console.log("--- a -- called");
            deleteAgeSwiping()
        }

        // Sequence detection for 'f' followed by 'a'
        if (lastKey === 'f' && e.key === 'a' && isProfilePage) {
            console.log(" --ffff--aaaa called");
            deleteImgSwiping(e.key)
            // setLastKey(''); // Reset the sequence
        }

        else if (lastKey === 'f' && e.key === 's' && isProfilePage) {
            console.log(" --ffff--ssss called");
            deleteImgSwiping(e.key)

            // setLastKey(''); // Reset the sequence
        }
        else if (lastKey === 'f' && e.key === 'd' && isProfilePage) {
            console.log(" --ffff--dddd called");
            deleteImgSwiping(e.key)
            // setLastKey(''); // Reset the sequence
        }
        else if (e.key === 'f') {
            // If 'f' is pressed, set it as the last key
            setLastKey('f');
        } else {
            // If any other key is pressed, reset lastKey
            setLastKey('');
        }


        // Sequence detection for 'f' followed by 'a'
        if (lastKey === 'f' && e.key === 'f' && isProfilePage) {
            console.log(" --ffff--rrrr called");
            setLastKey("")
            // setLastKey(''); // Reset the sequence
        }


        if (e.key === "v" && isProfilePage) {
            console.log("--- v -- called");
            deleteVerifySwiping()
        }

        if (e.key === "b" && isProfilePage) {
            console.log("--- b -- called");
            SwipingBanProfile()
        }


        if (e.key === "j" && isProfilePage) {
            console.log("--- j -- called");
            SwipingSafeProfile()
        }


        //     --------------------Swiping  End---------------           //

        if (e.key === "Escape") {
            if (showPopup?.show) return setShowPopup(currentState => {
                return { show: !currentState.show, ID: undefined };
            })
            if (isPictureFullscreen) {
                setIsPictureFullscreen(false);
            } else {
                dispatch(setActivePost(-1));
            }
        }

        if (e.key === "q") {
            console.log("first")
            handleSetCopy(userId)
            if (!showPopup.show && userId) setShowPopup(currentState => {
                return { show: true, ID: userId };
            })
        }
        if (e.key === "ArrowLeft") {
            // previousPage()
            setEditData()
            setLastKey("")
        };
        if (e.key === "g") {

            previousPage()
        };

        if (e.key === "ArrowRight") {
            setEditData()
            setLastKey("")
            if (activePost == -1) {
                nextPage();

            }
        };
        if (e.key === "h") {
            nextPage();
        };
        if (activePost !== -1 && activePost < posts.length) {
            if (e.key === "ArrowLeft") back();
            if (e.key === "g") back();

            if (e.key === "ArrowRight") {
                next();
            }
            if (e.key === "h") next();

            if (e.key === "j" && !isProfilePage) keepPost(userId);
            if (e.key === "f" && !isProfilePage) deleteUser(userId);
            if (e.key === "i") displayBan(userId);
            if (e.key === "b" && !isProfilePage) sendBio();
            if (e.key === "t") {
                translates();
            }
            if (links[activePost].length > 0) {
                if (e.key === "l") {
                    // openLinks(links[activePost]);
                    setShowLinks(true)
                    // console.log("open links!!!!!!!",activePost)
                }
                if (e.key === "a") {
                    setShowLinks(false)
                }
                if (e.key === "1" && activePost !== -1) {
                    // setShowLinks(false)
                    console.log("active post from profile", activePost)
                    let link = links[activePost][0] ? `https://www.snapchat.com/add/${links[activePost][0]}` : false;
                    if (link) openLink(link)
                    // ADD WINDOW.OPEN!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                }
                if (e.key === "2") {
                    let link = links[activePost][1] ? `https://www.instagram.com/${links[activePost][1]}` : false;

                    if (link) openLink(link)
                }
                if (e.key === "3") {
                    let link = links[activePost][2] ? `https://twitter.com/${links[activePost][2]}` : false;

                    if (link) openLink(link)
                }
                if (e.key === "4") {
                    let link = links[activePost][3] ? `https://www.tiktok.com/@${links[activePost][3]}` : false;

                    if (link) openLink(link)
                }
            }

            if ((e.key === "3" && reasonPrompt) || (e.key === "z" && !isProfilePage)) {
                deleteForSelling(posts[activePost].uid);
                // console.log("Z:",posts[activePost].uid)
            }
            //     if (e.key === "i") setTextPrompt(true);
            //     if (e.key === "x")
            //         deletePost({
            //             reason_report_int: 0,
            //             reason_report: "Against guidelines",
            //         });

            //     if (
            //         (e.key === "2" && reasonPrompt) ||
            //         (e.key === "a" && !reasonPrompt && !banPrompt && !textPrompt)
            //     )
            //         deleteForUnderage();

            //     if (e.key === "a") {
            //         setTextPrompt(false);
            //         setBanPrompt(false);
            //         setReasonPrompt(false);
            //     }

            //     if ((e.key === "1" && reasonPrompt) || e.key === "d") deleteForNudity();



            //     if ((e.key === "4" && reasonPrompt) || e.key === "s") deleteforSexual();
        }

        // if (banPrompt) {
        //     if (e.key === "1") ban();
        //     if (e.key === "2") hide();
        //     if (e.key === "3") supreme();
        // }

        // if (e.key === "1" && textPrompt) deleteBio();
        // if (e.key === "2" && textPrompt) deleteUsername();

        // if (e.key === "5" && reasonPrompt)
        //     deletePost({
        //         reason_report_int: 5,
        //         reason_report: "Scam",
        //     });
        // if (e.key === "6" && reasonPrompt)
        //     deletePost({
        //         reason_report_int: 6,
        //         reason_report: "Illegal",
        //     });
        // if (e.key === "0" && reasonPrompt)
        //     deletePost({
        //         reason_report_int: 0,
        //         reason_report: "Against guidelines",
        //     });
    }

    function deleteForNudity() {
        handleDelete({
            reason_report_int: 1,
            reason_report: "Nudity / Partial Nudity",
        });

        // deletePost({
        //   reason_report_int: 1,
        //   reason_report: "Nudity / Partial Nudity",
        // });
    }

    async function deleteForSelling(uid) {
        if (uid === posts[activePost].uid) {
            try {
                const result = await ban_user_mod({
                    uid: uid,
                    reason: 'selling',
                    action: 'ban_temp',
                    location: "profile",
                    // duration: 604800000
                });
                if(result.data.status !== 1){
                    alert(`Error in deleting: ${result.data.message}`)
                }
                console.log("delete for selling result:", result.data)
            } catch (error) {
                console.error("Error in deleteForSelling:", error);
            }
            console.log("userid1 prop", uid)
        }
        // console.log("userid1 profile",posts[activePost].user_id)
        // console.log("userid1 prop",uid)
    }

    function deleteforSexual() {
        deletePost({
            reason_report_int: 4,
            reason_report: "Suggestive / Sexual Suggestion",
        });
    }

    function deleteForUnderage() {
        handleDelete({
            reason_report_int: 2,
            reason_report: "Underage",
        });
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);

        return function () {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        };
    });

    useEffect(() => {
        // if (!switchS || switchS) {
        setDeactivateNext(false)
        handleHistoryClick();
        // }
    }, [switchS]);

    const handleHistoryClick = () => {
        // posts = []
        // let prePost = [];
        if (switchS) {
            setShowDropdown(!showDropdown);
            setLastDoc(null);
            // postsCategory = prePost;
            getPosts({

                // reported: reported,
                page: 1,
                // unread: unread,
                switchS: switchS
            })
                .then(({ lastSeen }) => {
                    setLastSeen(lastSeen);
                    // setIsLoading(false);
                })
                .catch((err) => {
                    console.error("Error with getting posts", err);
                });
        } else {
            setLastDoc(null);
            // setIsLoading(true);
            getPosts({
                // reported: reported,
                page: 1,
                // unread: unread,

            })
                .then(({ lastSeen }) => {
                    setLastSeen(lastSeen);
                    // setIsLoading(false);
                })
                .catch((err) => {
                    console.error("Error with getting posts", err);
                });
        }
    }

    const openLinks = (urls) => {
        // snap insta twitter tiktok
        if (urls?.length > 0) {
            // let tabs = [
            //     urls[0] ? `https://www.snapchat.com/add/${urls[0]}` : undefined,
            //     urls[1] ? `https://www.instagram.com/${urls[1]}` : undefined,
            //     urls[2] ? `https://twitter.com/${urls[2]}` : undefined,
            //     urls[3] ? `https://www.tiktok.com/@${urls[3]}` : undefined
            // ];

            let tabs = [
                urls[0] ? `https://www.snapchat.com/add/testing` : undefined,
                urls[1] ? `https://www.instagram.com/testing` : undefined,
                // urls[2] ? `https://twitter.com/${urls[2]}` : undefined,
                // urls[3] ? `https://www.tiktok.com/@${urls[3]}` : undefined
            ];

            // Filter out undefined values
            tabs = tabs.filter(url => url !== undefined);

            // Open each valid URL in a new tab
            tabs.forEach((url) => {
                // const newTab = window.open(url, '_blank');
                window.open(url, '_blank');
                // if (newTab) {
                //     newTab.blur();
                // }
                // window.focus();
            });
        }
    };

    const openLink = (url) => {
        if (url) window.open(url, '_blank')
    }

    function PageNavigation() {
        return (
            <>
                {/* <div className="verification-counter">
                    <span>{totalProfileCount}</span>
                </div> */}
                <div className="page-navigation-btn">
                    {user.uid === "H8rw3oWoEZZAcIAtVtUB5V82zZF2" && (
                        <Button
                            label="Create test"
                            onClick={() => createTest({ reported: props.reported })}
                        />
                    )}

                    <div className="paginate-btn">
                        <Button onClick={previousPage} label="Previous" />
                        <Button label={loadedPage} className="p-button-outlined " />
                        <Button onClick={nextPage} label="next" />
                    </div >


                </div>
                {activePost === -1 && isProfilePage && (
                    <div
                        ref={ref}
                        className="swiping_div"
                        style={{ top: '4.5vw', right: '6vw' }}
                    >

                        <button
                            onClick={() => {
                                // setPage(0);
                                setSwitchS(!switchS);
                                // dispatch(changePage(1));
                                // console.log("switchS", switchS);
                                // handleHistoryClick()
                            }}

                            style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                                cursor: 'pointer',
                            }}
                        >
                            <img src="/history.png" width="25px" />
                        </button>

                    </div>
                )}
            </>
        );
    }

    function handleDropdownFilter(e) {
        setLastDoc(null);
        // setIsLoading(true);
        setActionFilter(e.value);
        setDeactivateNext(false)
        console.log("this is handleDropdownFilter +++")
        getPosts({
            reported: reported,
            page: 1,
            unread: unread,
            mod_res: e.value,
            switchS: switchS,
            range: range,
            uid: selected_mod_uid
        })
            .then(({ lastSeen }) => {
                setLastSeen(lastSeen);
                // setIsLoading(false);
            })
            .catch((err) => {
                console.error("Error with getting posts", err);
            });
    }
    function handleDropdownChange() {
        // console.log("aboudi",props.reported)
        setDeactivateNext(false)
        const query = {
            page: 1,
            switchS,
            mod_res: actionFilter,
            range: range,
        }
        if (selected_mod_uid) {
            query.uid = selected_mod_uid
        }
        setIsLoading(true);
        getPosts(query)
            .then(({ lastSeen }) => {
                // console.log("Got posts correctly ++++")
                setLastSeen(lastSeen);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error("Error with getting posts", err);
            });

    }

    function deleteUser(docID) {
        profile_moderation_response({ docID: docID, response: 2 }).then((res) => {
            console.log(res);
            next();
        }).catch((err) => {
            console.log(err);
        })
        // alert("Delete User")
    };

    function setUId(uid) {
        // console.log(uid, "=================Set UId=================");
        setUserId(uid)
    };
    function setbiovalue(bio) {
        // console.log(bio, "=================Set bio=================");
        setBioValue(bio)
    };
    function setusername(username) {
        // console.log(username, "=================Set username=================");
        setUsername(username)
    };

    function setage(age) {
        setAge(setage)
    };


    const getCountOfData = async () => {
        let count;
        if (pathname === '/swiping/profile') {
            count = await get_counter_mod({ app: "swiping", section: "swiping", subsection: "profile" });
        } else {
            count = await get_counter_mod({ app: "addchat", section: "profile" });
        }
        if (count?.data?.status === 1) {
            setCountResponse(count.data.counter);
        }
    }

    useEffect(() => getCountOfData(), [isProfilePage]);

    // console.log(refreshedData, "refreshedData-----------------------max");
    // console.log(posts[activePost]?.uid,"activePost");

    // This useEffect hook will run once on component mount or when `newArray` or `posts` change
    useEffect(() => {
        let isMounted = true;
        const arrayToProcess = newArray?.length ? newArray : posts;

        // Define the fetchLinks function inside useEffect or ensure it's available in this scope
        const fetchLinks = async (postsArray) => {
            const getBannedModerationData = async (post) => {
                try {
                    const docSnapshot = await db.collection("banned_moderation").doc(post.uid).get();
                    const res = docSnapshot.data();
                    const subArray = [res?.snap, res?.insta, res?.twitter, res?.tiktok];
                    const allUndefined = subArray.every(item => item === undefined);
                    return allUndefined ? [] : subArray;
                } catch (err) {
                    console.log("failed hide", err);
                    return [];
                }
            };

            // Map each post to a promise that resolves with its corresponding data
            const promises = postsArray.map(post => getBannedModerationData(post));

            // Use Promise.all to wait for all of the promises to resolve
            Promise.all(promises).then((results) => {
                // results will be an array of the resolved values from each promise in the order they were passed in
                const resultArray = results;
                if (isMounted) {
                    setLinks(resultArray);
                    console.log(resultArray);
                }
            }).catch(error => {
                // Handle any error from any promise here
                console.log(error)
            });

            // Update the state with fetched data
        };


        // Call fetchLinks with the determined array
        fetchLinks(arrayToProcess);

        return () => {
            isMounted = false;
        };
    }, [newArray, posts]);

    useEffect(() => {
        console.log("LLLINKS", links)
        if (links.length > 0) {
            setShowLinksButton(true)
        }
        else setShowLinksButton(false)
    }, [links])
    // fetchLinks(arrayToProcess);

    // useEffect (()=> {
    //     console.log(links)
    // },[links])
    // const processArray = async () => {
    //     await fetchLinks(arrayToProcess)
    //         .then(res => { return res })
    // }
    // processArray();
    // // Call the async function with the chosen array
    // let links = processArray();
    // console.log("these are the links", links)

    return (
        <>
            {showPopup.show && showPopup.ID &&
                <div style={showPopup?.show && showPopup?.ID ? { backgroundColor: "rgba(0, 0, 0, 0.4)", width: "100vw", height: "100vh", zIndex: 900, position: "fixed", top: 0, left: 0 } : {}}>
                    <div className="chatpopup_container" style={{
                        position: 'fixed', // Or 'absolute', if you prefer
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', // Centers the div
                        width: '80vw',
                        border: '2px solid grey',
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        zIndex: 900,
                        // Ensure content is scrollable if it overflows
                        height: '90vh',
                        overflowY: 'scroll'
                    }}>
                        <button className="close" style={{ position: "sticky" }} onClick={() =>
                            setShowPopup(currentState => {
                                return { show: !currentState.show, ID: undefined };
                            }
                            )}>
                            Close
                        </button>
                        <SearchUser deletePosts={false} isDevelopment={false} userID={showPopup.ID ? showPopup.ID : undefined} />
                    </div>
                </div>
            }
            <div className="verification-counter">
                {countResponse}
            </div>
            {showDropdown && pathname == "/swiping/profile" &&
                <div className="action-filter-swiping">
                    <Dropdown
                        options={actionOptions}
                        value={actionFilter}
                        onChange={(e) => {
                            // console.log("change ++")
                            setPage(0);
                            setLastDoc(null);
                            handleDropdownFilter(e)
                        }}
                        placeholder="All"
                        showClear={showClearIcon}
                    />
                </div>
            }
            {<PageNavigation />}
            {showDropdown && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginRight: '30px',
                    }}
                >
                    <div
                        className="admin-filter"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '400px',
                        }}
                    >
                        <Dropdown
                            options={admins}
                            onChange={(e) => {
                                // console.log("this is dropdown Change:",e.value)
                                selected_mod_uid = e.value;
                                console.log('New selection:', e.value);
                                setAdminFilter(e.value);
                                setLastDoc(null);
                                handleDropdownChange();
                            }}
                            value={adminFilter}
                            showClear
                            placeholder="All Admins"
                        />

                        <input
                            placeholder="All Admins"
                            style={{ marginTop: "10px", height: "28px" }}
                            type="datetime-local"
                            onChange={(e) => {
                                const selectedDate = new Date(e.target.value).getTime();
                                const currentDate = new Date().getTime();

                                if (selectedDate <= currentDate) {
                                    setRange(e.target.value);
                                    handleDropdownChange();
                                } else {
                                    // Handle case where selected date is in the future
                                    console.log('Selected date is in the future.');
                                }
                                // Convert to Date object

                            }}
                            value={range}

                        />
                        {/* {console.log("range", range)} */}
                    </div>
                </div>
            )}
            <p style={{ position: "absolute", left: "5vw", top: "72px", fontWeight: "bold", fontSize: "15px" }}> {dayjs.unix(profileInfo?.last_mod_date?.seconds).fromNow()} • {profileInfo?.last_mod_uid} </p>
            <div className="block posts">
                {/* {console.log("newArray", newArray)} */}
                {isLoading ?
                    <ProgressSpinner />
                    :
                    (newArray?.length ? newArray : posts)
                        ?.map((post, index) => (
                            <Post
                                showModUID={switchS}
                                key={post.id}
                                setUsernameForProfile={setUsernameForProfile}
                                postId={index}
                                bio={bio}
                                reported={props.reported}
                                verifyImg={verifImg}
                                isPictureFullscreen={isPictureFullscreen}
                                setIsPictureFullscreen={setIsPictureFullscreen}
                                setEditData={setEditData}
                                setLastKey={setLastKey}                           // docId= {post.userID}
                                setUId={setUId}
                                setBioValue={setbiovalue}
                                translate={translate}
                                setTranslate={setTranslate}
                                setUsername={setusername}
                                age={setage}
                                displayBio={bioValue}
                                reported_User={refreshedData?.userID === posts[activePost]?.uid ? refreshedData : post.reported_user}
                                // reported_User={refreshedData ? refreshedData : post.reported_user}

                                isProfileLoading={isProfileLoading}


                                {...post}
                                controls={
                                    <Controls
                                        activePost={activePost}
                                        posts={posts}
                                        reported={reported}
                                        unread={unread}
                                        reasonPrompt={reasonPrompt}
                                        banPrompt={banPrompt}
                                        textPrompt={textPrompt}
                                        key={key}
                                        deleteForUnderage={deleteForUnderage}
                                        deleteforSexual={deleteforSexual}
                                        handleDeleteUserName={handleDeleteUserName}
                                        handleBio={() => { sendBio() }}
                                        deleteForNudity={deleteForNudity}
                                        deleteForSelling={() => { deleteForSelling(post.uid) }}
                                        keepPost={() => { keepPost(post.uid) }}
                                        displayBan={() => { displayBan(post.uid) }}
                                        setReasonPrompt={setReasonPrompt}
                                        setTextPrompt={setTextPrompt}
                                        setBanPrompt={setBanPrompt}
                                        deleteBio={deleteBio}
                                        deleteUsername={() => deleteUsername(post)}
                                        deleteVerificationImg={deleteVerificationImg}
                                        deletePost={deletePost}
                                        ban={ban}
                                        hide={hide}
                                        supreme={supreme}
                                        translates={translates}
                                        deleteUser={() => { deleteUser(post.uid) }}
                                        docID={post.userID}
                                        handleSetCopy={() => handleSetCopy(post.id)}
                                        deleteNameSwiping={deleteNameSwiping}
                                        deleteAgeSwiping={deleteAgeSwiping}
                                        deleteImgSwiping={deleteImgSwiping}
                                        deleteVerifySwiping={deleteVerifySwiping}
                                        SwipingBanProfile={SwipingBanProfile}
                                        SwipingSafeProfile={SwipingSafeProfile}
                                        editData={editData}
                                        undoDataSwiping={undoDataSwiping}
                                        isImage={lastKey}
                                        setLastKey={setLastKey}
                                        switchS={switchS}
                                        openLink={openLink}
                                        links={links[index]}
                                        showLinksButton={links[index]?.length > 0 ? true : false}
                                        showLinks={showLinks}
                                        setShowLinks={setShowLinks}
                                    />
                                }
                            />
                        ))
                }

            </div>

            {<PageNavigation />}
        </>
    );
}

export default Profile;
