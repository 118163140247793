export function parseUrl(url: string) {
  let start = url.search("posts%2F") + "posts%2F".length;
  if (start === -1) return url;
  let end = url.search(/\.jpg/);
  if (end === -1) end = url.length;

  let parsed = url.slice(start, end);

  return parsed;
}

export function removeUndefined(obj: { response_time: any }) {
  let newObj = { ...obj };
  if (obj?.response_time === undefined) {
    delete newObj?.response_time;
  }
  return newObj;
}

export function removeUndefined2(obj: { [key: string]: any }) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined));
}


export function isStableVersion(version: string) {
  const [major, minor, patch] = version.split('.').map(Number);
  return major >= 2;
}
