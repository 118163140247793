import { ban_user_mod, db } from "../../../app/firebase";
import firebase from "firebase";
import { setPostText } from "../postsSlice";
import store from "../../../app/store";

interface Props {
  collection: "public_post" | "moderation_post";
  uid: string;
  unread: boolean;
  reported: boolean;
  userName: string,
  postText: string;
  location: string;
}

export function deletePostText({ collection, uid, unread, reported, userName, postText, location }: Props) {

  const data = {
    userID: uid,
    username: userName,
    caption: postText,
    location: location
    // location:"all_reported", "new_posts" , "all_posts" , "history"
  }
  ban_user_mod({
    uid: uid,
    action: "ban_temp",
    reason: "caption",
    location: "post",
  })
  .then((res)=>{
    console.log("ban_user_mod response:", res.data);
    if(res.data.status!==1){
      alert(`problem banning user:${res.data.message}`);
    }
  })
  .catch((error) => {
    console.error("Error banning user", error);
    alert(`Error banning user:${error}`);
    // You can also add any additional error handling logic here
  });

  console.log("ban_user_mod called");

  const caption_delete_moderation = firebase
    .functions()
    .httpsCallable("caption_delete_moderation");

  return caption_delete_moderation(data)
    .then((res: any) => {

      console.log('res', res);
      if (res.data.status === 1) {
        if (collection === "public_post") {
          store.dispatch(
            setPostText({
              postText: "",
              reported: reported,
              unread: unread,
            })
          );
        }

        if (location === "all_reported") {
          db.collection(collection)
            .doc(uid)
            .set({ postText: "" }, { merge: true })
            .then(() => {
              //   caption_delete_moderation();
              store.dispatch(
                setPostText({
                  postText: "",
                  reported: reported,
                  unread: unread,
                })
              );
            })
            .catch(err => {
              console.log(err);
              return err;
            })

        }
      } else {
        console.log(res);
        return res;

      }
    })
    .catch(err => {
      console.log(err);
    })



}
