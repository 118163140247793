import React, { useCallback, useEffect, useRef, useState } from "react";


// the update is working well

// however, I would like to remove the button that is added as a tab everywhere, just the short cut alone is enough

// [] delete this button from All Posts, New Posts, All Reported, Chat, History, Profile but keep the key short cut
import { useDispatch } from "react-redux";
import { Switch, Route, Link, Redirect, useLocation, useHistory } from "react-router-dom";
import { selectUser, setUser } from "./features/auth/userSlice";

import { useSelector } from "react-redux";

import SignInComponent from "./features/auth/signIn";
import SearchUser from "./features/search/SearchUser";
import PostsComponent from "./features/posts/PostsComponent";
import ParentVerificationComponent from "./features/verification/ParentVerificationComponent";
import SwipingComponent from "./features/swiping/SwipingComponent";
import HistoryComponent from "./features/history/HistoryComponent";
import { DevelopmentConfiguration } from "./features/development/DevelopmentConfiguration";
import SignOutComponent from "./features/auth/signOut";
import { auth } from "./app/firebase";

import { ProgressSpinner } from "primereact/progressspinner";
import { selectActivePost as selectActivePostPosts } from "./features/posts/postsSlice";
import { selectActivePost as selectActivePostProfile } from "./features/profile/postsSlice";

import ProfileComponent from './features/profile/profileComponent'
import ChatComponent from './features/chat/chatComponent'
import { version } from '../package.json'
import { isStableVersion } from "./helpers/helpers";
// import { ReactInternetSpeedMeter } from 'react-internet-meter'
import { setInternetSpeed } from "./app/globaleSlice";
import { performSpeedTest } from "./helpers/functions";
// import 'react-internet-speed-meter/dist/index.css'

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const activePostPosts = useSelector(selectActivePostPosts);
  const activePostProfile = useSelector(selectActivePostProfile);
  const [admin, setAdmin] = useState(false)

  const user = useSelector(selectUser);
  const location = useLocation();

  const admins = ["G8dOCB2xcUS99mdYLaYY99nFfsj2", "8InOiDv72waRp4Nul3aFddOS1ng2", "BuT86HiYu1b14bmbffvLv5w7D362"]

  useEffect(() => {
    console.log("APP VERSION: ", version)
    if (user?.uid && admins.includes(user?.uid)) {
      setAdmin(true)
    }
  }, [user])

  // useEffect(() => {
  //   const runSpeedTest = async () => {
  //     try {
  //       const speedKbps = await performSpeedTest();
  //       dispatch(setInternetSpeed(speedKbps));
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };

  //   runSpeedTest();
  // }, []);

  const developerId = "H8rw3oWoEZZAcIAtVtUB5V82zZF2";
  const isDevelopment = false;

  const developConfig = {
    isDevelopment,
    deletePosts: false,
  };

  // console.log(user,"user")

  const handleKeyDown = useCallback(
    (e) => {
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
        return //The key was pressed inside an input or textarea.
      }

      else if (activePostPosts !== -1 || activePostProfile !== -1) {
        return console.log("active post is not -1");
      }

      else if (e.ctrlKey || e.metaKey) {
        return console.log("ctrl or command pressed")
      }

      else if ((e.key === "1" || e.key === "a") && activePostPosts === -1 && activePostProfile === -1) {
        history.push("/allPosts");
        console.log("active post from App.js", activePostPosts);
      }
      else if ((e.key === "2" || e.key === "n") && activePostPosts === -1 && activePostProfile === -1) {
        history.push("/newPosts");
      }
      else if ((e.key === "3" || e.key === "r") && activePostPosts === -1 && activePostProfile === -1) {
        history.push("/reportedPosts");
      }
      //decide on c !!!
      else if ((e.key === "4" || (e.key === "c" && location.pathname !== "/searchUser")) && activePostPosts === -1 && activePostProfile === -1) {
        history.push("/chat");
      }
      else if (e.key === "5" || e.key === "p") {
        history.push("/profile");
      }
      else if (e.key === "6" || (e.key === "h" && location.pathname !== "/searchUser")) {
        history.push("/history");
      }
      else if (e.key === "7" || e.key === "v") {
        history.push("/verification");
      }
      else if (e.key === "8" || e.key === "u") {
        history.push("/searchUser");
      }
      else if (e.key === "9" || e.key === "s") {
        history.push("/swiping");
      }
      else if (e.key === "Tab") {
        if (location.pathname.includes("swiping")) {
          // console.log("Tab pressed in:", location.pathname); 
          switch (location.pathname) {
            case "/swiping/verification":
              history.push("/swiping/profile")
              break;
            case "/swiping/profile":
              history.push("/swiping/chat")
              break;
            case "/swiping/chat":
              history.push("/swiping/verification")
              break;
            default:
              return;
          }
        }
        if (location.pathname.includes("verification")) {
          // console.log("Tab pressed in:", location.pathname); 
          switch (location.pathname) {
            case "/verification/verification":
              history.push("/verification/age")
              break;
            case "/verification/age":
              history.push("/verification/verification")
              break;
            default:
              return;
          }
        }
      }
    },
    [activePostPosts, activePostProfile, history, location.pathname]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return function () {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  function LinkItem({ path, children }) {
    const location = useLocation();

    // Check if the current location starts with the specified path
    const isActive = location.pathname.startsWith(path);
    return (
      <Link to={path} className={isActive ? "active" : ""}>
        {children}
      </Link>
    );
  }
  function getToken() {
    return auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        return idTokenResult.claims.isAdmin;
      })
      .catch((error) => {
        console.error("Error getting token", error);
        return false;
      });
  }

  useEffect(() => {
    auth().onAuthStateChanged(async (user) => {
      if (!user) {
        dispatch(setUser({}));
        return;
      } else {
        let moderator = await getToken();

        if (!moderator) {
          auth().signOut();
          return;
        }

        dispatch(
          setUser({
            uid: user.uid,
            email: user.email,
            moderator,
          })
        );
      }
    });
  }, [dispatch]);

  if (user.loading) return <ProgressSpinner />;

  return (
    <>
      {user.uid && (
        <>
          {/* <ReactInternetSpeedMeter
            txtSubHeading="Internet is too slow"
            outputType="alert"
            customClassName={null}
            txtMainHeading="Opps..."
            pingInterval={4000} // milliseconds 
            thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
            threshold={0.5}
            imageUrl="https://via.placeholder.com/1000"
            downloadSize="7581.0546875"  //bytes
            callbackFunctionOnNetworkDown={(speed) => console.log(`Internet speed is down ${speed}`)}
            callbackFunctionOnNetworkTest={(speed) => dispatch(setInternetSpeed(speed))}
          /> */}
          <nav>
            <LinkItem
              // path="/posts/all"
              path="/allPosts"
            >
              All Posts
            </LinkItem>

            <LinkItem
              // path="/posts/new"
              path="/newPosts"
            >
              New Posts
            </LinkItem>

            <LinkItem
              // allPosts
              // path="/posts/reported"
              path="/reportedPosts"
            >
              All Reported
            </LinkItem>

            <LinkItem
              // allPosts
              // path="/posts/reported"
              path="/chat"
            >
              Chat
            </LinkItem>

            <LinkItem
              // allPosts
              // path="/posts/reported"
              path="/profile"
            >
              Profile
            </LinkItem>

            <LinkItem path="/history">History</LinkItem>

            <LinkItem
              // allPosts
              path="/verification"
            >
              Verification
            </LinkItem>

            <LinkItem path="/searchUser">Search User</LinkItem>

            <LinkItem
              path="/swiping"
            >
              Swiping
            </LinkItem>

            {/* <LinkItem
            // allPosts
            path="/development"
          >
            Development
          </LinkItem> */}

            <Link to="/sign-out" className="right">
              Sign Out
            </Link>
          </nav>
        </>
      )}

      {user.uid ? (
        <Switch>
          <Redirect from="/sign-in" to="/allPosts" />

          <Route exact path="/">
            <PostsComponent {...developConfig} key={location.pathname} />
          </Route>

          <Route path={["/posts/all", "/allPosts"]}>
            <PostsComponent {...developConfig} key={location.pathname} /> {/** USING THE KEY PROP WE ENSURE UNIQUENESS SO EACH TIME THE LOCATION CHANGES WE GET A NEW INSTANCE OF THE POSTSCOMPONENT */}
          </Route>

          <Route path={["/reportedPosts", "/posts/reported"]}>
            <PostsComponent reported={true} {...developConfig} key={location.pathname} />
          </Route>

          <Route path={["/chat/:userIdURLParam", "/chat"]}>
            <ChatComponent reported={true} {...developConfig} key={location.pathname} />
          </Route>

          <Route path={["/profile"]}>
            <ProfileComponent unread={true} {...developConfig} key={location.pathname} />
          </Route>

          <Route path={["/newPosts", "/posts/new"]}>
            <PostsComponent unread={true} {...developConfig} key={location.pathname} />
          </Route>

          <Route exact path={["/searchUser", "search-user"]}>
            <SearchUser {...developConfig} key={location.pathname} />
          </Route>
          {/* 
          this is for popup
          <Route path={["/searchUser/:userID?", "search-user/:userID?"]}>
            <SearchUser {...developConfig} />
          </Route> */}

          <Route path={"/verification"}>
            <ParentVerificationComponent key={location.pathname} />
          </Route>

          <Route path={"/swiping"}>
            <SwipingComponent {...developConfig} key={location.pathname} />
          </Route>

          <Route path={["/history/:userIdURLParam", "/history"]}>
            <HistoryComponent {...developConfig} key={location.pathname} />
          </Route>

          <Route path={"/development"}>
            <DevelopmentConfiguration {...developConfig} key={location.pathname} />
          </Route>

          <Route path={"/sign-out"}>
            <SignOutComponent key={location.pathname} />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route path={"/sign-in"}>
            <SignInComponent key={location.pathname} />
          </Route>
          <Redirect from="/" to="/sign-in" />
        </Switch>
      )}
      {admin && <p style={{ position: 'absolute', down: 0, right: 10 }}>Version: {version} </p>} {/* add after version to enable stable flag  {isStableVersion(version) && <span style={{ color: 'grey' }}>- Stable</span>} */}
    </>
  );
}

export default App;
