import { useState, useEffect, useMemo, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { v1 as uuid1 } from 'uuid';

import {
  firebaseDeletePost,
  db,
  firestore,
  logAnalytics,
  profile_pic_moderation,
  bio_moderation,
  request_verif_moderation,
  explain_text_mod,
  view_verif_img,
  ban_user_mod,
  get_counter_mod
} from "../../app/firebase";

import { getPosts, deleteNotification, keepNotification } from "./model";

import sendDeleteMessage from "./functions/sendDeleteMessage";

import { selectUser } from "../auth/userSlice";
import {
  nextPost,
  selectPosts,
  setActivePost,
  selectActivePost,
  selectPostsLoading,
  selectReportedPosts,
  selectPage,
  changePage,
  removePost,
  setPostUsername,
  selectUnreadPosts,
  setPosts,
  clearPosts,
  setUuid,
  selectUuid,
  selectIsCleared,
  setIsCleared
} from "./postsSlice";

import store from "../../app/store";


import Post from "./Post";
import { deleteHistory } from "./functions/deleteHistory";
import { Button } from "primereact/button";
import { createTest } from "./functions/createTest";
import { Controls } from "./Controls";
import { keepHistory } from "./functions/Keep.functions";
import { ProgressSpinner } from "primereact/progressspinner";
import firebase from "firebase";
import { deletePostText } from "./functions/deletePostText";
import { parseUrl, removeUndefined } from "../../helpers/helpers";
import { current } from "@reduxjs/toolkit";
import SearchUser from "../search/SearchUser";
import { performSpeedTest } from "../../helpers/functions";

function PostsComponent(props) {
  let dispatch = useDispatch();

  const initTimeRef = useRef(Date.now());
  const genderRef = useRef();

  const [key, setKey] = useState("");
  const [banPrompt, setBanPrompt] = useState(false);
  const [textPrompt, setTextPrompt] = useState(false);
  const [reasonPrompt, setReasonPrompt] = useState(false);
  const [bioCheck, setBioCheck] = useState("");
  const [verifImg, setVerifyImg] = useState("");
  const [isPictureFullscreen, setIsPictureFullscreen] = useState(false);
  const [ID, setID] = useState("");
  const [username, setUsername] = useState("");
  const [lastModeIndicator, setLastModeIndicator] = useState({});
  const [bio, setBio] = useState("");
  const [lastSeen, setLastSeen] = useState({});
  const [userData, setUserData] = useState(false);
  const [pre_fetch_user, setPre_fetch_user] = useState(false);
  const [profilepicShow, setProfilepicShow] = useState("");
  const [nextPostDetails, setNextPostDetails] = useState({});
  const [prevPostDetails, setPrevPostDetails] = useState({});
  const [currentData, setCurrentData] = useState({});
  const [previousData, setPreviousData] = useState({});
  const [nextData, setNextData] = useState({});
  const [gender, setGender] = useState();
  const [updatedPosts, setUpdatedPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  // const [isLoading3, setIsLoading3] = useState(true);
  const [preventUpdatePostArray, setPreventUpdatePostArray] = useState(true);
  const [countResponse, setCountResponse] = useState("-");
  const [clearedOnce, setClearedOnce] = useState(false); //this is used to know if this component has already cleared the post at least once to make sure it's not updating the previous component's state
  const [prefetchedAllPosts, setPrefetchedAllPosts] = useState(false);

  const [links, setLinks] = useState('');
  const [showLinksButton, setShowLinksButton] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [allDataReady, setAllDataReady] = useState(false);
  const isMounted = useRef(true);
  const [showPopup, setShowPopup] = useState({ show: false, ID: undefined });

  const allDataReadyRef = useRef(false);
  const loadedPageRef = useRef();
  const latestPostsRef = useRef([]);

  const [imgUrlPost, setImgUrlPost] = useState("");

  const location1 = useLocation();
  const pathname = location1.pathname
  const location = props.reported
    ? "all_reported"
    : props?.unread
      ? "new_posts"
      : "all_posts";

  function handleDelete(reason) {
    deletePost(reason);
  }

  let user = useSelector(selectUser);

  let allPosts = useSelector(selectPosts);
  // let unreadPosts = useSelector(selectUnreadPosts);
  // let reportedPosts = useSelector(selectReportedPosts);

  let isCleared = useSelector(selectIsCleared)

  let loadedPage = useSelector(selectPage);
  // let postsCategory = props.unread
  //   ? unreadPosts
  //   : props.reported
  //     ? reportedPosts
  //     : allPosts;

  const posts = useMemo(() => {
    // setPreventUpdatePostArray(true)
    latestPostsRef.current = allPosts[loadedPage] || [];
    console.log("allPosts", allPosts[loadedPage], "latestPostRef", latestPostsRef.current);
    return allPosts[loadedPage] || [];
  }, [loadedPage, allPosts, allPosts.length]); //[loadedPage, postsCategory]

  useEffect(() => {

    // performSpeedTest();

    if (allPosts.length === 0 && !isCleared) {
      console.log("allPosts.length, isCleared", allPosts.length, isCleared)
      dispatch(setIsCleared(true))
      setClearedOnce(true)
    }
  }, []) //This is used when the first component mounts meaning when we are not routing in from another component

  // This useEffect hook will run once on component mount or when `posts` change
  useEffect(() => {
    let isMounted = true;
    const arrayToProcess = posts.length > 0 ? posts : [];

    // Define the fetchLinks function inside useEffect or ensure it's available in this scope
    const fetchLinks = async (postsArray) => {
      const getBannedModerationData = async (post) => {
        try {
          const docSnapshot = await db.collection("banned_moderation").doc(post.userID).get();
          const res = docSnapshot.data();
          const subArray = [res?.snap, res?.insta, res?.twitter, res?.tiktok];
          const allUndefined = subArray.every(item => item === undefined);
          return allUndefined ? [] : subArray;
        } catch (err) {
          console.log("failed hide", err);
          return [];
        }
      };

      // Map each post to a promise that resolves with its corresponding data
      const promises = postsArray?.map(post => getBannedModerationData(post));

      // Use Promise.all to wait for all of the promises to resolve
      Promise.all(promises).then((results) => {
        // results will be an array of the resolved values from each promise in the order they were passed in
        const resultArray = results;
        if (isMounted) {
          setLinks(resultArray);
          console.log("Links:", resultArray);
        }
      }).catch(error => {
        // Handle any error from any promise here
        console.log(error)
      });

      // Update the state with fetched data
    };


    // Call fetchLinks with the determined array
    if (arrayToProcess) fetchLinks(arrayToProcess);

    return () => {
      isMounted = false;
    };
  }, [posts, dispatch]);


  const { reported, unread } = props;

  useEffect(() => {
    const warmUp = async () => {
      let userID = 'NMUNrL5HnlSN9FOoXhJEF6MagfC3';
      const url = await view_verif_img({ uid: userID, from: 'posts' });
      console.log("warmed up view_verif_img with this userID", userID);
    }
    if (posts.length < 1) warmUp();
    allDataReadyRef.current = false; //to cause update function
    if (isCleared) {
      console.log("Calling get posts from useEffect (warmup)")
      getPosts({ reported: reported, page: 1, unread: unread, gender }).then(
        (res) => {
          setLastSeen(res.lastSeen);
          setClearedOnce(true) //This is used When we assure that this component has its posts cleared once so now we allow updating the posts when we get them through this get posts function
          // add a check to see if the data is empty => setIsLoading(false)
          if (res?.noData) {
            console.log("no data")
            setIsLoading(false)
          }
        }
      );
    }
    // return () => {
    //   isMounted.current = false;
    //   console.log("unmounting");
    //   dispatch(clearPosts());
    // };
  }, [reported, unread, gender, isCleared]);

  useEffect(() => {
    // dispatch
    return () => {
      isMounted.current = false;
      console.log("unmounting");
      dispatch(clearPosts());
      // dispatch(setActivePost(-1)); // do not need it because clearPosts does it also
    };
  }, []);


  useEffect(() => {
    // console.log("LLLINKS", links)
    if (links.length > 0) {
      setShowLinksButton(true)
    }
    else setShowLinksButton(false)
  }, [links])

  useEffect(() => {
    let ignore = false;
    if (allPosts[loadedPage]?.length > 0) {
      for (const post of allPosts[loadedPage]) {
        if (!('first_profile_url' in post) && !('verifyImageURL' in post) && allDataReadyRef.current && allDataReadyRef.current !== 'in_progress') {
          console.log("updating because no urls fields available")
          return updatePostArray(ignore);
        }
      }
    }
    return () => {
      ignore = true;
    }
  }, [loadedPage, allPosts])

  const openLink = (url) => {
    if (url) window.open(url, '_blank')
  }

  const openLinks = (links) => {
    if (links.length === 0) return;

    links.forEach((user, index) => {
      if (!user) return; // Skip if no user data

      let link = '';
      switch (index) {
        case 0:
          link = `https://www.snapchat.com/add/${user}`;
          break;
        case 1:
          link = `https://www.instagram.com/${user}`;
          break;
        case 2:
          link = `https://twitter.com/${user}`;
          break;
        case 3:
          link = `https://www.tiktok.com/@${user}`;
          break;
        default:
          console.log(`No social media defined for index ${index}`);
          return;
      }

      console.log(`Opening link for ${user} at index ${index}: ${link}`);
      setTimeout(() => {
        window.open(link, '_blank');
      }, 100);
    });
  };


  // useEffect(() => {
  //   if (updatedPosts?.length === 0) store.dispatch(setPosts([]));
  // }, [updatedPosts]);

  const getCountOfData = async () => {
    let count;
    if (location == "all_posts") {
      setCountResponse("-")
      return;
    }
    if (location == "all_reported") {
      count = await get_counter_mod({ app: "addchat", section: "all_reported" });
    } else if (location == "new_posts") {
      count = await get_counter_mod({ app: "addchat", section: "new_posts" });
    }
    if (count?.data?.status === 1) {
      setCountResponse(count.data.counter);
    }
    // console.log(count, "count");
  }

  useEffect(() => {
    getCountOfData();
  }, [location]);

  useEffect(() => {
    let ignore = false;
    if (posts.length && preventUpdatePostArray && posts[0]?.userID && !allDataReadyRef.current && allDataReadyRef.current !== 'in_progress' && !isCleared && clearedOnce) {
      // localStorage.setItem("callPath", pathname);
      // dispatch(setUuid(uuid1()))
      console.log("Updating!!!! started after", (Date.now() / 1000 - initTimeRef.current / 1000).toFixed(2), "seconds with allDataReadyRef =", allDataReadyRef.current);
      updatePostArray(ignore);
    }
    return () => {
      ignore = true
    }
  }, [posts, clearedOnce]);

  // useEffect(() => {
  //   setUpdatedPosts([]);  // THIS IS NOT NEEDED ANYMORE BECAUSE WE ADDED THE KEY PROP TO EACH COMPONENT IN THE app.js
  // }, [location]);         // SO EACH TIME THE LOCATION CHANGES WE GET A NEW INSTANCE OF THE POSTSCOMPONENT

  useEffect(() => {
    if (posts.length > 0 && allDataReadyRef.current) {
      console.log("UPDATED POSTS:", posts)
      console.log(`updatedPosts[0].id from ${pathname}`, posts[0]?.id);
      console.log(`posts[0].id: ${pathname}`, posts[0]?.id);
      console.log(`updates Arrived AFTER ${(Date.now() / 1000 - initTimeRef.current / 1000).toFixed(2)} Seconds`);
      if (activePost === -1) {
        // this way when we remove a post the posts array is modified and the useeffect is triggered but
        // since it prefetchs only when active post === -1, and, when we remove a post, active post is not -1 so it will not run  
        let startTime = Date.now();
        let imagesToPreload = [
          posts[0]?.imageUrlPost,
          posts[0]?.first_profile_url,
          posts[0]?.verifyImageURL,
          posts[1]?.imageUrlPost,
          posts[1]?.first_profile_url,
          posts[1]?.verifyImageURL,
        ].filter(url => !!url); // Filter out any undefined or null values

        if (imagesToPreload.length > 0) {
          // console.log("prefetching :")
          console.log("urls:", imagesToPreload)
          preloadImages(imagesToPreload).then(() => {
            console.log(`Successfully prefetched initial set of images and it took: ${Date.now() - startTime} seconds`);
          })
            .catch((error) => {
              console.error("Failed to preload some of the initial set of images:", error);
            });
        }
        if (!allDataReady) return;
        startTime = Date.now();
        imagesToPreload = posts.reduce((acc, post) => {
          const postUrls = [post?.imageUrlPost,
          post?.first_profile_url,
          post?.verifyImageURL].filter(url => !!url);
          return acc.concat(postUrls)
        }, []); // Filter out any undefined or null values


        ///*****!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ******/
        // TODO: TRY DOING IT SEQUENTIALLY
        if (imagesToPreload.length > 0) {
          // console.log("prefetching :")
          console.log("urls:", imagesToPreload)
          preloadImages(imagesToPreload).then(() => {
            console.log(`Successfully prefetched all images and it took: ${Date.now() - startTime} seconds`);
            setPrefetchedAllPosts(true)
          })
            .catch((error) => {
              console.error("Failed to preload some images:", error);
            });
        }
      }
    }
  }, [posts]);


  if (preventUpdatePostArray === false && !posts.length) {
    setPreventUpdatePostArray(true);
  }

  const updateOnePost = async (index, ignore) => {

    if (!posts[index]) {
      console.error('Invalid index');
      return;
    }

    const userID = posts[index]?.userID;
    console.log("updating this user:", userID)

    if (userID) {
      try {
        const additionalData = await getWaitData(userID);

        let updatedPosts = [
          ...posts
        ]; //this is creating a new reference to only the first layer : allPosts = [[],[],[]] the inner arrays still reference to the original allPosts
        // console.log("UPDATE ONE POST: this is updated posts 1",updatedPosts);
        // updatedPosts[loadedPage] = {
        //   ...updatedPosts[loadedPage]
        // }; // this way we have created copies of each page so we wont directly mutate the unmutable state variable
        // console.log("UPDATE ONE POST: this is updated posts 2",updatedPosts);
        updatedPosts[index] = {
          ...posts[index], //this way we ensure creating a copy if the original post and not directly mutating it
          ...additionalData
        }
        // console.log("UPDATE ONE POST: this is updated posts 3",updatedPosts);

        // if the mod on a button that removes the post from the original array, when updates arrive we should exclude the removed post
        let originalIds = latestPostsRef.current.map(post => post.id);
        // console.log("updated_posts_array before filter", updated_posts_array, "posts before filter", latestPostsRef.current);
        updatedPosts = updatedPosts.filter(post => originalIds.includes(post.id));
        console.log("Updating One Post: updatedPosts:", updatedPosts)
        if (!ignore) dispatch(setPosts({ posts: updatedPosts, page: loadedPage }));
      } catch (error) {
        console.error("error fetching additional data one post", error);
      }
    } else {
      console.log("UserID not found");
    }

  }

  // check why it is not triggered when gender changes
  const updatePostArray = async (ignore) => {
    // setAllDataReady(false);
    console.log("starting update for those posts:", posts)
    allDataReadyRef.current = 'in_progress';
    initTimeRef.current = Date.now();
    let localTime = initTimeRef.current;
    loadedPageRef.current = loadedPage;
    let currentPage = loadedPageRef.current; // save the page number if we move to another page before the updates have finished
    genderRef.current = Date.now();
    let currentGender = genderRef.current; // save the gender if we move to another gender/page before the updates have finished


    // *********************** get the first user then PROMISE the rest
    // const firstUser = posts[0].userID;
    // const additional_data = await getWaitData(firstUser);
    // console.log("First user addition data:", additional_data)
    // let updated_posts_array = [{ ...posts[0], ...additional_data }];
    // setUpdatedPosts(updated_posts_array);
    // const sliced_posts = posts.slice(1);
    // const updatedPostsArray1 = await Promise.all(
    //   sliced_posts.map(async (post) => {
    //     const userID = post.userID;
    //     const additionalData = await getWaitData(userID);
    //     console.log(`UserID: ${userID}`);
    //     return { ...post, ...additionalData };
    //   })
    // );
    // updated_posts_array = updated_posts_array.concat(updatedPostsArray1);
    // ***********************  

    // *********************** get the first 3 users sequentially then PROMISE the rest
    let updated_posts_array = []
    let n = posts.length < 3 ? posts.length : 3; //not needed anymore
    for (let i = 0; i < posts.length; i++) {
      // if (i >= posts.length) break; //to not fetch things that doesn't exist
      if (i < n) {
        const firstUser = posts[i]?.userID;
        const additional_data = await getWaitData(firstUser);
        console.log(`User ${i} addition data:`, additional_data)
        updated_posts_array.push({ ...posts[i], ...additional_data });
      }
      else updated_posts_array.push({ ...posts[i] })
    }
    if (ignore) return;
    if (localTime !== initTimeRef.current && currentPage === loadedPageRef.current) { // We add this check of pages to know if there are time changed because we initiated a new call in the next page or in another component so if it is in another component we dont want To update but it fits in the same component but different page we want the previous page to indeed get its update and then fetch the next components update
      return console.log("Canceled in first three because time diff")
    }
    if (isMounted.current) {
      // if the mod on a button that removes the post from the original array, when updates arrive we should exclude the removed post
      let originalIds = latestPostsRef.current.map(post => post.id);
      // console.log("updated_posts_array before filter", updated_posts_array, "posts before filter", latestPostsRef.current);
      updated_posts_array = updated_posts_array.filter(post => originalIds.includes(post.id));
      // console.log("updated_posts_array after filter", updated_posts_array);
      dispatch(setPosts({ posts: updated_posts_array, page: currentPage }));
    }
    else return console.log("Canceled in first three because unmounted");
    const sliced_posts = posts.slice(3);
    const updatedPostsArray1 = await Promise.all(
      sliced_posts.map(async (post) => {
        const userID = post.userID;
        const additionalData = await getWaitData(userID);
        console.log(`UserID: ${userID}`);
        return { ...post, ...additionalData };
      })
    );
    updated_posts_array = updated_posts_array.slice(0, 3).concat(updatedPostsArray1);
    // ***********************  


    // *********************** fetch without promises
    // let updated_posts_array = []
    // for (let post in posts) {
    //   const userId = posts[post].userID;
    //   const additional_data = await getWaitData(userId);
    //   console.log("got the data of user:", userId)
    //   updated_posts_array.push({ ...post, ...additional_data });
    // }
    // ***********************

    // ***********************
    // let updated_posts_array = posts.map(async (post) => {
    //   const userID = post.userID;
    //   const additional_data = await getWaitData(userID);
    //   console.log(`UserID: ${userID} Additional Data: ${JSON.stringify(additional_data, null, 2)}`);
    //   return { ...post, ...additional_data };
    // });
    // updated_posts_array = await Promise.all(updated_posts_array);
    // ***********************

    // *********************** get the first user then no promise the rest
    // const firstUser = posts[0].userID;
    // const additional_data = await getWaitData(firstUser);
    // console.log("First user addition data:", additional_data)
    // let updated_posts_array = [{ ...posts[0], ...additional_data }];
    // setUpdatedPosts(updated_posts_array);
    // for (let i = 1; i < posts.length; i++) {
    //   const post = posts[i];
    //   const userId = post.userID;
    //   const additional_data = await getWaitData(userId);
    //   console.log("got the data of user:", userId)
    //   updated_posts_array.push({ ...post, ...additional_data });
    // }
    // ***********************

    if (localTime !== initTimeRef.current && currentPage === loadedPage) {
      return console.log("Canceled")
    }
    if (ignore) return;
    if (isMounted.current) {
      setAllDataReady(true);
      allDataReadyRef.current = true;
      // if the mod on a button that removes the post from the original array, when updates arrive we should exclude the removed post

      let originalIds = latestPostsRef.current.map(post => post.id);
      // console.log("updated_posts_array before filter", updated_posts_array, "posts before filter", latestPostsRef.current);
      updated_posts_array = updated_posts_array.filter(post => originalIds.includes(post.id));
      // console.log("updated_posts_array after filter", updated_posts_array);
      dispatch(setPosts({ posts: updated_posts_array, page: currentPage }))
      setIsLoading(false);
      // setIsLoading2(false); //because we need to wait for the image to be loaded in ui, not the url, check post => img => onLoad
    }
    else {
      console.log("isMounted.current", isMounted.current);
    }
  };

  const getWaitData = async (userID) => {
    let wait_data = {};
    let verifyImageURL = ''
    const verifyImageResult = await view_verif_img({ uid: userID, from: 'posts_max' })
    wait_data = {
      bio: verifyImageResult?.data?.bio,
      first_profile_url: verifyImageResult?.data?.first_profile_url,
    };
    // console.log("verifyImageResult",verifyImageResult);
    verifyImageURL = verifyImageResult?.data?.url;
    return { ...wait_data, verifyImageURL };
  };

  useEffect(() => {
    console.log("isLoading2", isLoading2)
  }, [isLoading2])

  let postsLoading = useSelector(selectPostsLoading);
  let activePost = useSelector(selectActivePost);
  const isActivePostBoosted = posts[activePost]?.boost_request;

  // useEffect(() => {
  //   dispatch(setActivePost(-1));
  //   dispatch(clearPosts());
  // }, [location]);

  useEffect(() => {
    db.collection("admin")
      .doc("info")
      .get()
      .then((res) => {
        setLastModeIndicator(res.data());
      })
      .catch((err) => {
        console.log(err, "==========Err==========");
      });
  }, [allPosts]); //was postsCategory

  const guyFunction = () => {
    if (gender === 0) return;
    initTimeRef.current = Date.now(); // because when the previous updated posts arrive and the gender updatePostArray function was not initiated yet, we update this value to force cancel the previous response
    console.log("clearing posts inside guy Function")
    dispatch(clearPosts())
    setGender(0);
    // store.dispatch(setPosts());
  };

  const girlFunction = () => {
    if (gender === 1) return;
    initTimeRef.current = Date.now();// because when the previous updated posts arrive and the gender updatePostArray function was not initiated yet, we update this value to force cancel the previous response
    console.log("clearing posts inside girl Function")
    dispatch(clearPosts())
    setGender(1);
    // store.dispatch(setPosts());
  };

  // useEffect(() => {
  //   if (gender === 0 || gender === 1) {
  //     store.dispatch(setPosts({ posts: [], page: -1 }));
  //     console.log("Gender set, clearing posts")
  //   }
  // }, [gender])

  function nextPage() {
    // setUpdatedPosts([]); //Makes problem when we get to the last post of the last available page and click next and get an empty response for the next page the updated data won't show and won't be refetched
    setIsLoading2(false)
    setAllDataReady(false);
    allDataReadyRef.current = false; //to cause calling update posts
    if (loadedPage < allPosts?.length - 1) { // was postsCategory
      dispatch(changePage(loadedPage + 1));
      if (activePost === posts?.length - 1) dispatch(setActivePost(0));
    } else {
      console.log("Calling get posts from nextPage")
      getPosts({
        reported: props.reported,
        page: loadedPage + 1,
        unread: props.unread,
        startAfter: lastSeen,
        gender,
        activePost
      })
        .then(({ lastSeen, firstSeen }) => {
          setLastSeen(lastSeen);
        })
        .catch((err) => console.error("Error with getting posts", err));
    }
  }

  function previousPage() {
    // dispatch(setPosts([]));
    if (loadedPage - 1) {
      dispatch(changePage(loadedPage - 1));
      if (activePost === 0) dispatch(setActivePost(allPosts[loadedPage - 1]?.length - 1));
    }
  }

  function lock(reason = {}, post = {}) {
    if (!post?.userID) return;
    db.collection("banned_moderation")
      .doc(post?.userID)
      .set(
        {
          ...reason,
          unlock_all: false,
          report_counter_mod: firestore.FieldValue.increment(1),
          report_mod_uid: user?.uid,
          report_mod_date: firestore.FieldValue.serverTimestamp(),
          last_post_reported: true,
          postText: post?.postText ?? "",
          imageUrlPost: post?.imageUrlPost ?? "",
        },
        { merge: true }
      );
  }

  async function deletePost(reason, post, ban = false) {
    setPreventUpdatePostArray(false);
    let postToDelete = post ?? posts[activePost];

    const new_updated = posts.filter(
      (post) => post.id != posts[activePost].id
    );
    dispatch(setPosts({ posts: new_updated, page: loadedPage }));


    const verif_pic_url = posts[activePost].verifyImageURL
      ? posts[activePost].verifyImageURL
      : "";
    const profile_pic_url = posts[activePost].first_profile_url
      ? posts[activePost].first_profile_url
      : "";

    var modifiedPost = { ...postToDelete };  // Shallow copy of the lastPost object

    if (posts[activePost].bio) {
      modifiedPost.bio = posts[activePost].bio;
    };
  //  console.log("old activepost", activePost)
    deleteHistory({
      post: modifiedPost,
      user,
      reason,
      location,
      verification_url: verif_pic_url,
      profile_pic_url: profile_pic_url,
      ...(ban ? { action: "ban" } : {}),
    }).then(() => {
      // async function sleep(ms) {
      //   return new Promise(resolve => setTimeout(resolve, ms));
      // }
      // sleep(2000);
      // return console.log("new activepost", activePost)
      let banUserModReason = '';
      if (reason) {
        switch (reason.reason_report) {
          case 'Selling / Self-Promotion':
            banUserModReason = 'selling';
            break;
          case 'Nudity / Partial Nudity':
            banUserModReason = 'nudity';
            break;
          case 'Suggestive / Sexual Suggestion':
            banUserModReason = 'sexual';
            break;
          case 'Underage':
            banUserModReason = 'underage';
            break;
          case 'Against guidelines':
            banUserModReason = 'guidelines';
            break;
          case 'Illegal':
            banUserModReason = 'illegal';
            break;
          case 'Scam':
            banUserModReason = 'scam';
            break;
          default:
            banUserModReason = 'guidelines';
            break;
        }
        console.log("banUserModReason", banUserModReason)
        if (banUserModReason) {
          ban_user_mod({
            uid: postToDelete.userID,
            action: "ban_temp",
            reason: banUserModReason,
            location: "post",
          })
            .then((res) => {
              console.log("ban_user_mod response:", res?.data?.message)
              // alert(`Deleted: ${res?.data?.message}`)

              if (res.data.status !== 1) {
                alert(`problem deleting: ${res?.data?.message}`)
              }
            })
            .catch((err) => {
              return alert(`problem deleting for ${err}`);
            })
        }
      }
    })
      .catch((err) => {
        console.error("Error: deletePost: ", err);
      })

    log("remove_img_mod", reason?.reason_report);

    deleteNotification({ fcm_token: postToDelete.fcm_token });

    sendDeleteMessage({
      username: postToDelete.username,
      uid: postToDelete.userID,
      emoji: postToDelete.emoji,
      reason,
    });

    lock(reason, postToDelete);
    if (location !== "all_posts") {
      adminAction({ newPosts: props.unread });
    }

    firebaseDeletePost(postToDelete.id, postToDelete.userID, props.reported);

    if (reason) {
      setReasonPrompt(false);
      setTextPrompt(false);
      setBanPrompt(false);
    }

    if (activePost + 1 === posts.length) {
      nextPage();
      setUserData({});
      dispatch(setActivePost(0));
      setPre_fetch_user(false);
    } else {
      next(true);
      setPre_fetch_user(false);
      dispatch(setActivePost(activePost));
    }
  }

  function adminAction({ newPosts = false }) {
    const actionObject = newPosts
      ? {
        new_post_uid: user.uid,
        new_post_date: firestore.FieldValue.serverTimestamp(),
      }
      : {
        last_action: Date.now(),
        userID: user.uid,
        last_action_timestamp: firestore.FieldValue.serverTimestamp(),
      };
    db.collection("admin").doc("info").set(actionObject, { merge: true });
  }

  function log(event, reason = null) {
    const logObject = {
      event_type: event,
      email: "email@gmail.com",
      userID: posts[activePost].userID,
      mod_location: props.unread
        ? "new_posts"
        : props.reported
          ? "all_reported"
          : "all_posts",
      imageUrlPost: posts[activePost].imageUrlPost,
      postText: posts[activePost].postText,

      data: {
        reason,
        ...(posts[activePost]?.response_time &&
          (event === "skip_post" ||
            event === "keep_img_mod" ||
            event === "remove_img_mod")
          ? {
            response_time: dayjs().unix() - posts[activePost]?.response_time,
          }
          : {}),
      },
    };

    logAnalytics(logObject).catch((err) => console.error("log err", err));
  }

  function deleteBio() {
    db.collection("users")
      .doc(posts[activePost].userID)
      .set(
        {
          bio: "",
        },
        { merge: true }
      )
      .then(() => setBio(""));
  }

  function deleteVerificationImg() {
    setTextPrompt(false);
    setVerifyImg("");
    db.collection("users").doc(posts[activePost].userID).set(
      {
        verifImgUrl: "",
      },
      { merge: true }
    );
  }
  function verifyProfile() {
    request_verif_moderation({
      uid: ID,
      username: username,
      location: location,
    })
      .then((res) => {
        if (res.data.status === 1) {
          // console.log(res.data);
          // window.alert(res.data.message);
        } else {
          window.alert(`problem with verifyProfile() ${res.message}`);
        }
      })
      .catch((err) => {
        console.log(err, "======Error=======");
      });
  }

  async function deleteUsername() {
    const oldUsername = posts[activePost].username;
    const docID = parseUrl(posts[activePost].imageUrlPost);

    const userName = await handleDeleteUserName();

    // return
    if (userName) {
      db.collection(props.reported ? "moderation_post" : "public_post")
        .doc(posts[activePost].userID)
        .set({ username: userName }, { merge: true })
        .then(() => { });

      if (props.reported) {
        db.collection("public_post")
          .doc(posts[activePost].userID)
          .set({ username: userName }, { merge: true })
          .then(() => { });
      }

      await logHistoryDeleteUsername(oldUsername, docID);

      db.collection("users")
        .doc(posts[activePost].userID)
        .set(
          {
            username: userName,
          },
          { merge: true }
        )
        .then(() => {
          setTextPrompt(false);
          dispatch(
            setPostUsername({
              username: userName,
              reported: props.reported,
              unread: props.unread,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async function logHistoryDeleteUsername(oldUsername = String, docID) {
    await db.collection('history').doc(docID).set({ 'edit_text': { 'username': oldUsername } }, { merge: true });
    console.log("oldUserName", oldUsername, docID);
  };

  async function logHistoryDeleteCaption(oldCaption = String, docID) {
    await db.collection('history').doc(docID).set({ 'edit_text': { 'caption': oldCaption } }, { merge: true });
    console.log("oldCaption", oldCaption, docID);
  };

  async function logHistoryDeleteBio(oldBio = String, docID) {

    await db.collection('history').doc(docID).set({ 'edit_text': { 'bio': oldBio } }, { merge: true });
    console.log("oldBio", oldBio, docID);
  };

  async function deletePostTextFunction() {
    const docID = parseUrl(posts[activePost].imageUrlPost);

    const data = {
      uid: posts[activePost].id,
      collection: reported ? "moderation_post" : "public_post",
      unread: !props.unread ? false : props.unread,
      reported: !props.reported ? false : props.reported,
      userName: posts[activePost].username,
      postText: posts[activePost].postText,
      location,
    };
    setPreventUpdatePostArray(false);
    const deleteResponse = await deletePostText(data);
    await logHistoryDeleteCaption(data.postText, docID);
    return deleteResponse;
  }

  async function handleDeleteUserName() {
    try {
      const data = {
        userID: posts[activePost].userID,
        username: posts[activePost].username,
        location: location,
      };
      const usernameModeration = firebase
        .functions()
        .httpsCallable("username_moderation");
      const result = await usernameModeration(data);
      if (result) {
        const { message, status, username } = result.data;
        return username;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  }

  function seePost() {
    db.collection(props.reported ? "moderation_post" : "public_post")
      .doc(posts[activePost].id)
      .set(
        {
          post_seen_mod: true,
          post_seen_by: firestore.FieldValue.arrayUnion(user.uid),
          seen_mod_date: firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  }

  function handleSetCopy(id) {
    navigator.clipboard.writeText(id).then(() => console.log("ok"));
  }
  const keepPost = () => {
    const postID = posts[activePost].id;

    if (posts.length) {
      if (location !== "all_posts") {
        adminAction({ newPosts: props.unread });
      }
      const verif_pic_url = posts[activePost]?.verifyImageURL
        ? posts[activePost]?.verifyImageURL
        : "";
      const profile_pic_url = posts[activePost]?.first_profile_url
        ? posts[activePost]?.first_profile_url
        : "";

      var modifiedPost = { ...posts[activePost] };  // Shallow copy of the lastPost object

      if (posts[activePost].bio) {
        modifiedPost.bio = posts[activePost].bio;
      };
      console.log(verif_pic_url, profile_pic_url)
      keepHistory({
        post: modifiedPost,
        user,
        location,
        verification_url: verif_pic_url,
        profile_pic_url: profile_pic_url,
      });

      if (!props.reported) {
        seePost();
        if (activePost + 1 === posts.length) {
          nextPage();
          setUserData({});
          dispatch(setActivePost(0));
          setPre_fetch_user(false);
        } else {
          setPreventUpdatePostArray(false);
          next();
          setPre_fetch_user(false);
          dispatch(setActivePost(activePost));
        }
        dispatch(removePost({ id: postID }));
        // const new_updated = posts.filter((post) => post.id != postID);
        // dispatch(setPosts({ posts: new_updated, page: loadedPage }));
        return;
      }
      // setIsLoading(true); // this caused the weird bug when clicking j randomly does an infinite spinner and still we dont know why the previous dev put it here
      if (activePost + 1 === posts.length) {
        nextPage();
        setUserData({});
        dispatch(setActivePost(0));
        setPre_fetch_user(false);
      } else {
        setPreventUpdatePostArray(false);
        next();
        setPre_fetch_user(false);
        dispatch(setActivePost(activePost));
      }



      keepNotification({ fcm_token: posts[activePost].fcm_token });

      db.collection("banned_moderation")
        .doc(postID)
        .set({ last_post_reported: false }, { merge: true });

      db.collection("public_post")
        .doc(postID)
        .set(
          {
            finish_processing: true,
            img_state: 5,
            post_seen_mod: true,
            datePost: firestore.FieldValue.serverTimestamp(),
            post_seen_by: firestore.FieldValue.arrayUnion(user.uid),
            seen_mod_date: firestore.FieldValue.serverTimestamp(),
            ...(isActivePostBoosted ? { boost_safe: true } : {}),
          },
          { merge: true }
        )
        .then((res) => {
          log("keep_img_mod");
        })
        .catch((err) => {
          console.error(err);
        });

      if (props.reported) {
        db.collection("moderation_post")
          .doc(postID)
          .set(
            {
              moderated: true,
              post_seen_mod: true,
              post_seen_by: firestore.FieldValue.arrayUnion(user.uid),
              seen_mod_date: firestore.FieldValue.serverTimestamp(),
              last_post_reported: false,
            },
            { merge: true }
          )
          .then(() => { })
          .catch((err) => {
            console.log(err);
          });
      }
      console.log("REMOVING POST", postID)
      dispatch(removePost({ id: postID }));
      const new_updated = posts.filter((post) => post.id != postID);
      dispatch(setPosts({ posts: new_updated, page: loadedPage }));
    }
  };

  const displayBan = () => {
    setBanPrompt(true);
  };

  function ban() {
    let uid = posts[activePost]?.userID;
    if(!uid){
      return alert("no user selected")
    }
    db.collection("banned_moderation")
      .doc(uid)
      .set({ banned: true }, { merge: true })
      .then(() => {
        adminAction({ newPosts: props.unread });
        lock();
        deletePost(null, null, true);
        log("ban_img_mod");
      })
      .catch((err) => {
        console.error("failed ban", err);
      });
    setBanPrompt(false);
  }

  function hide() {
    let uid = posts[activePost].userID;

    db.collection("banned_moderation")
      .doc(uid)
      .set({ hide: true }, { merge: true })
      .then(() => {
        log("ban_img_mod");
        lock();
        adminAction({ newPosts: props.unread });
        deletePost(null, null, true);
      })
      .catch((err) => {
        console.log("failed hide", err);
      });
    setBanPrompt(false);
  }

  const supreme = () => {
    let uid = posts[activePost].userID;

    db.collection("banned_moderation")
      .doc(uid)
      .set({ supreme: true }, { merge: true })
      .then(() => {
        log("ban_img_mod");
        lock();
        adminAction({ newPosts: props.unread });
        deletePost(null, null, true);
      })
      .catch(() => {
        console.log("failed supreme");
      });
    setBanPrompt(false);
  };

  const next = (skip = true) => {
    log("skip_post");
    seePost();
    if (!skip) {
      setUserData({});

      dispatch(nextPost());
      return;
    }

    if (props?.unread) {
      if (activePost + 1 === posts.length) {
        nextPage();
        setUserData({});
        // dispatch(setActivePost(0));
      } else {
        setUserData(pre_fetch_user);
        dispatch(nextPost());
        setPre_fetch_user(false);
      }
    } else {
      if (activePost + 1 === posts.length) {
        setUserData({});

        nextPage();
        // dispatch(setActivePost(0));
      } else {
        setUserData(nextPostDetails);
        dispatch(nextPost());
        setPre_fetch_user(false);
      }
    }
  };

  const back = () => {
    if (activePost === 0) return previousPage();
    setUserData({});
    dispatch(setActivePost(activePost - 1));
    setUserData(prevPostDetails);
  };

  function handleKeyDown(e) {
    setKey(e.key);
  }

  async function updateprifilepic() {
    try {
      await profile_pic_moderation({
        userID: posts[activePost].userID,
        username: posts[activePost].username,
        location: location,
        imgUrl: posts[activePost].imageUrlPost,
      }).then((res) => {
        if (res.data.status == 1) {
          setProfilepicShow(posts[activePost].userID);
        } else {
          window.alert(`${res?.data?.message}`);
        }
      });
    } catch (error) { }
  }

  async function newbioapi() {
    const oldBio = posts[activePost].bio;
    const docID = parseUrl(posts[activePost].imageUrlPost);

    bio_moderation({
      userID: posts[activePost].userID,
      username: posts[activePost].username,
      location: location,
      bio: posts[activePost].bio,
    }).then((res) => {
      if (res.data.status == 1) {
        setBioCheck(posts[activePost].userID);
      } else {
        window.alert(res.data.message);
      }
      console.log("res new--", res);
    });

    await logHistoryDeleteBio(oldBio, docID);
  };

  function explainTextMode() {
    explain_text_mod({ caption: posts[activePost].postText, location })
      .then((res) => {
        alert(res?.data?.message);
      })
      .catch((err) => {
        console.log(err, "=======Err=========");
      });
  }

  function handleKeyUp(e) {
    setKey("");

    if (e.key === "q") {
      handleSetCopy(ID);
      if (!showPopup.show && ID) setShowPopup(currentState => {
        return { show: true, ID: ID };
      })
    }

    if (e.key === " ") {
      if (activePost === -1) {
        console.log("Space: setting active post 0 using spacebar")
        setUserData({});
        dispatch(setActivePost(0));
      } else {
        console.log("Space: setting full screen")
        setIsPictureFullscreen(!isPictureFullscreen);
      }
    }

    if (e.key === "Escape") {
      if (showPopup?.show) return setShowPopup(currentState => {
        return { show: !currentState.show, ID: undefined };
      })
      if (isPictureFullscreen) {
        setIsPictureFullscreen(false);
      } else {
        setUserData({});
        setID("");
        dispatch(setActivePost(-1));
      }
    }

    if (activePost == -1) {
      if (e.key === "ArrowLeft") {
        previousPage();
      }

      if (e.key === "g") {
        previousPage();
      }

      if (e.key === "ArrowRight") {
        nextPage();
      }

      if (e.key === "h") {
        nextPage();
      }
    }
    if (activePost !== -1 && activePost < posts.length && !showPopup.show) {
      if (e.key === "ArrowLeft") back();
      if (e.key === "g") back();

      if (e.key === "ArrowRight") next();
      if (e.key === "h") next();




      if (links[activePost].length > 0) {
        if (e.key === "l") {
          setShowLinks(true)
          // console.log("open links!!!!!!!",activePost)
        }
        if (e.key === "a") {
          setShowLinks(false)
        }
        if (e.key === "1" && activePost !== -1 && showLinks) {
          // setShowLinks(false)
          // console.log("active post from profile", activePost)
          let link = links[activePost][0] ? `https://www.snapchat.com/add/${links[activePost][0]}` : false;
          if (link) openLink(link)
          // ADD WINDOW.OPEN!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        }
        if (e.key === "2" && showLinks) {
          let link = links[activePost][1] ? `https://www.instagram.com/${links[activePost][1]}` : false;

          if (link) openLink(link)
        }
        if (e.key === "3" && showLinks) {
          let link = links[activePost][2] ? `https://twitter.com/${links[activePost][2]}` : false;

          if (link) openLink(link)
        }
        if (e.key === "4" && showLinks) {
          let link = links[activePost][3] ? `https://www.tiktok.com/@${links[activePost][3]}` : false;

          if (link) openLink(link)
        }
        if (e.key === "l" && showLinks) {
          openLinks(links[activePost]);
        }
      }

      if (e.key === "c" && !showLinks) {
        console.log(`e.key === "c" && !showLinks`)
        deletePostTextFunction();
      }
      if (e.key === "f" && !showLinks) setReasonPrompt(true);
      if (e.key === "j" && !showLinks) keepPost();
      if (e.key === "k" && !showLinks) displayBan();
      if (e.key === "i" && !showLinks) setTextPrompt(true);
      if (e.key === "u" && !showLinks) deleteUsername();
      if (e.key === "e" && !showLinks) explainTextMode();
      if (e.key === "x" && !showLinks)
        deletePost({
          reason_report_int: 0,
          reason_report: "Against guidelines",
        });

      if (
        (e.key === "2" && reasonPrompt && !showLinks) ||
        (e.key === "a" && !reasonPrompt && !banPrompt && !textPrompt && !showLinks)
      )
        deleteForUnderage();

      if (e.key === "a" && !showLinks) {
        setTextPrompt(false);
        setBanPrompt(false);
        setReasonPrompt(false);
      }
      if ((e.key === "1" && reasonPrompt) || e.key === "d") if (!showLinks) deleteForNudity();
      if ((e.key === "3" && textPrompt && !showLinks) || e.key === "p") {
        if (!showLinks) {
          updateprifilepic();
          console.log("P clicked, deleting profile")
        }
      }

      if ((e.key === "3" && reasonPrompt) || e.key === "z") if (!showLinks) deleteForSelling();

      if ((e.key === "4" && reasonPrompt) || e.key === "s") if (!showLinks) deleteforSexual();
    }
    if (!showPopup.show) {
      if (banPrompt && !showLinks) {
        if (e.key === "1") ban();
        if (e.key === "2") hide();
        if (e.key === "3") supreme();
      }

      if ((e.key === "1" && textPrompt) || e.key === "b") if (!showLinks) newbioapi();
      if (e.key === "2" && textPrompt && !showLinks) deleteUsername();
      if (e.key === "v" && !textPrompt && !showLinks) verifyProfile();
      if ((textPrompt && e.key === "4") || e.key === "c") {
        console.log(`(textPrompt && e.key === "4") || e.key === "c"`)
        if (!showLinks) deletePostTextFunction();
      }

      if (e.key === "5" && reasonPrompt && !showLinks)
        deletePost({
          reason_report_int: 5,
          reason_report: "Scam",
        });
      if (e.key === "6" && reasonPrompt && !showLinks)
        deletePost({
          reason_report_int: 6,
          reason_report: "Illegal",
        });
      if (e.key === "0" && reasonPrompt && !showLinks)
        deletePost({
          reason_report_int: 0,
          reason_report: "Against guidelines",
        });
    }
  }

  function deleteForNudity() {
    handleDelete({
      reason_report_int: 1,
      reason_report: "Nudity / Partial Nudity",
    });
  }

  // async function deleteForSelling() {
  //   try {
  //     const result = await ban_user_mod({
  //       uid: ID,
  //       reason: 'Selling',
  //       action: 'ban',
  //       location: "all_post",
  //       duration: 604800000
  //     });

  //     window.alert(result.data.message);
  //     await deletePost({
  //       reason_report_int: 3,
  //       reason_report: "Selling / Self-Promotion",
  //     });


  //   } catch (error) {
  //     console.error("Error in deleteForSelling:", error);
  //   }
  // }

  function deleteForSelling() {
    deletePost({
      reason_report_int: 3,
      reason_report: "Selling / Self-Promotion",
    });
  }

  function deleteforSexual() {
    deletePost({
      reason_report_int: 4,
      reason_report: "Suggestive / Sexual Suggestion",
    });
  }

  function deleteForUnderage() {
    handleDelete({
      reason_report_int: 2,
      reason_report: "Underage",
    });
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return function () {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  });

  // function preloadImages(urls) {
  //   if (urls.length === 0) return;
  //   urls.forEach(url => {
  //     const img = new Image();
  //     img.src = url;
  //   });
  // }
  // !!!!!!!!!!!!!!!!**********!!!!!!!!!!!!!!!!
  // https://www.dhiwise.com/post/guide-to-optimizing-image-loading-with-react-preload
  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  const preloadImages = (imagesArray) => {
    const promises = imagesArray.map((src) => preloadImage(src));
    return Promise.all(promises);
  };
  // !!!!!!!!!!!!!!!!**********!!!!!!!!!!!!!!!!

  useEffect(() => {
    // if ((activePost === 0 || activePost === 1 || activePost === 2)) { // && !prefetchedAllPosts
    if (activePost !== -1) {
      let imagesToPreload = [
        posts[activePost + 1]?.imageUrlPost,
        posts[activePost + 1]?.first_profile_url,
        posts[activePost + 1]?.verifyImageURL,
        posts[activePost + 2]?.imageUrlPost,
        posts[activePost + 2]?.first_profile_url,
        posts[activePost + 2]?.verifyImageURL,
      ].filter(url => !!url); // Filter out any undefined or null values

      if (imagesToPreload.length > 0) {
        // console.log("prefetching :")
        console.log("urls:", imagesToPreload)
        preloadImages(imagesToPreload).then(() => {
          console.log("Successfully prefetched next of images.");
        })
          .catch((error) => {
            console.error("Failed to preload next images:", error);
          });
      }
    }
    setShowLinks(false)
  }, [activePost])

  function PageNavigation() {
    return (
      <div className="page-navigation">
        {user.uid === "H8rw3oWoEZZAcIAtVtUB5V82zZF2" && (
          <Button
            label="Create test"
            onClick={() => createTest({ reported: props.reported })}
          />
        )}

        <Button onClick={previousPage} label="Previous" />
        <Button label={loadedPage} className="p-button-outlined " />
        <Button onClick={nextPage} label="next" />
      </div>
    );
  }

  function parseTest() {
    const parsed = parseUrl(imgUrlPost);
    alert(`parsed: ${parsed}`);
  }

  return (
    <>
      {/* <div style={{}}>
        <input placeholder="imageurlpost" value={imgUrlPost} onChange={(e) => setImgUrlPost(e.target.value)} />
        <button onClick={parseTest}>Parse</button>
      </div> */}
      {showPopup.show && showPopup.ID &&
        <div style={showPopup?.show && showPopup?.ID ? { backgroundColor: "rgba(0, 0, 0, 0.4)", width: "100vw", height: "100vh", zIndex: 900, position: "fixed", top: 0, left: 0 } : {}}>
          <div className="chatpopup_container" style={{
            position: 'fixed', // Or 'absolute', if you prefer
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Centers the div
            width: '80vw',
            border: '2px solid grey',
            borderRadius: '10px',
            backgroundColor: 'white',
            zIndex: 900,
            // Ensure content is scrollable if it overflows
            height: '90vh',
            overflowY: 'scroll'
          }}>
            <button className="close" style={{ position: "sticky" }} onClick={() =>
              setShowPopup(currentState => {
                return { show: false, ID: undefined };
              }
              )}>
              Close
            </button>
            <SearchUser deletePosts={false} isDevelopment={false} userID={showPopup.ID ? showPopup.ID : undefined} />
          </div>
        </div>
      }

      {
        location !== "all_posts" &&

        <div className="verification-counter">
          {countResponse}
        </div>
      }
      {<PageNavigation />}

      {!unread && !reported ? (
        <div className="gender-change">
          <button onClick={guyFunction}>Guys</button>
          <button onClick={girlFunction}>Girls</button>
        </div>
      ) : (
        ""
      )}

      {activePost === -1 ? (
        location == "all_reported" ? (
          <span
            style={{
              position: "absolute",
              top: "83px",
              left: "5vw",
              fontWeight: "bold",
              fontSize: "15px",
              zIndex: "9999",
            }}
          >
            {dayjs
              .unix(lastModeIndicator?.last_action_timestamp?.seconds)
              .fromNow() +
              " • " +
              lastModeIndicator.userID}{" "}
          </span>
        ) : location == "new_posts" ? (
          <span
            style={{
              position: "absolute",
              top: "83px",
              left: "5vw",
              fontWeight: "bold",
              fontSize: "15px",
              zIndex: "9999",
            }}
          >
            {dayjs.unix(lastModeIndicator?.new_post_date?.seconds).fromNow() +
              " • " +
              lastModeIndicator.new_post_uid}{" "}
          </span>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      <div className="block posts">
        {((!posts.length && !allDataReady) || isLoading) ? (
          <ProgressSpinner />
        ) : (
          posts.map((post, index) => {
            return (
              <Post
                allPosts={posts}
                key={post.id}
                userID={post.userId}
                ppp={post}
                postId={index}
                bio={bio}
                setPrevPostDetails={setPrevPostDetails}
                setNextPostDetails={setNextPostDetails}
                nextPostDetails={nextPostDetails}
                prevPostDetails={prevPostDetails}
                reported={props.reported}
                verifyImg={verifImg}
                isPictureFullscreen={isPictureFullscreen}
                setIsPictureFullscreen={setIsPictureFullscreen}
                setID={setID}
                setUsername={setUsername}
                setUserData={setUserData}
                bioCheck={bioCheck}
                userData={userData}
                profilepicShow={profilepicShow}
                setPre_fetch_user={setPre_fetch_user}
                pre_fetch_user={pre_fetch_user}
                currentData={currentData}
                previousData={previousData}
                nextData={nextData}
                setCurrentData={setCurrentData}
                setPreviousData={setPreviousData}
                setNextData={setNextData}
                isLoading={isLoading}
                isLoading2={isLoading2}
                setIsLoading2={setIsLoading2}
                setIsLoading={setIsLoading}
                updatePostArray={updatePostArray}
                allDataReadyRef={allDataReadyRef}
                links={links}
                updateOnePost={updateOnePost}
                {...post}
                controls={
                  <Controls
                    id={post.id}
                    activePost={activePost}
                    posts={posts}
                    reported={reported}
                    unread={unread}
                    reasonPrompt={reasonPrompt}
                    banPrompt={banPrompt}
                    textPrompt={textPrompt}
                    key={post.id}
                    deleteForUnderage={deleteForUnderage}
                    deleteforSexual={deleteforSexual}
                    deleteForNudity={deleteForNudity}
                    deleteForSelling={deleteForSelling}
                    keepPost={keepPost}
                    displayBan={displayBan}
                    setReasonPrompt={setReasonPrompt}
                    setTextPrompt={setTextPrompt}
                    setBanPrompt={setBanPrompt}
                    deleteBio={deleteBio}
                    deleteUsername={deleteUsername}
                    deletePostTextFunction={deletePostTextFunction}
                    deleteVerificationImg={deleteVerificationImg}
                    verifyProfile={verifyProfile}
                    deletePost={deletePost}
                    ban={ban}
                    hide={hide}
                    supreme={supreme}
                    handleSetCopy={handleSetCopy}
                    updateprifilepic={updateprifilepic}
                    newbioapi={newbioapi}
                    openLink={openLink}
                    openLinks={openLinks}
                    links={links[index]}
                    showLinksButton={links[index]?.length > 0 ? true : false}
                    showLinks={showLinks}
                    setShowLinks={setShowLinks}
                  />
                }
              />
            );
          })
        )}
      </div>

      {<PageNavigation />}
    </>
  );
}

export default PostsComponent;

// When going to next Post, it doesn’t remove the verif, profile pic, post images waiting to load the new images
