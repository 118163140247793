import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import VerificationComponent from "./VerificationComponent";
import AgeVerification from "./ageVerification/AgeVerification";

export default function ParentVerificationComponent() {

  const location = useLocation()
  const { path, url } = useRouteMatch();

  return <>
    <nav style={{paddingTop:0, marginTop: 0}}>
      <Link to={`${path}/verification`} className={(location.pathname === `${path}/verification` || location.pathname.startsWith(`${path}/verification`)) ? "active" : ""}> Verification </Link>
      <Link to={`${path}/age`} className={(location.pathname === `${path}/age` || location.pathname.startsWith(`${path}/age`)) ? "active" : ""}> Age </Link>
    </nav>

    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/verification`} />
      </Route>
      <Route path={`/verification/verification`}>
        <VerificationComponent />
      </Route>
      <Route path={`/verification/age`}>
        <AgeVerification />
      </Route>
    </Switch>
  </>
}
