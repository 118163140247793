import { Button } from "primereact/button";
import { useCallback, useEffect, useRef, useState } from "react";
import { convertToTimestamp, db } from "../../app/firebase";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import dayjs from "dayjs";
import "./history.scss";
import HistoryPost from "./HistoryPost";
import { selectUser } from "../auth/userSlice";
import { selectActivePost, setActivePost } from "../posts/postsSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function HistoryComponent() {
  let { uid } = useSelector(selectUser);

  const limit = 10;
  const [elements, setElements] = useState([]);
  const [page, setPage] = useState(0);
  const [admins, setAdmins] = useState([{ label: uid, value: uid }]);
  const [adminFilter, setAdminFilter] = useState(uid);
  const [lastPost, setLastPost] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [range, setRange] = useState('');
  const dispatch = useDispatch();
  const right = useRef(null);
  const left = useRef(null);
  const activePost = useSelector(selectActivePost);
  const [actionFilter, setActionFilter] = useState('all');
  const [reasonFilter, setReasonFilter] = useState('all');
  const [genderFilter, setGenderFilter] = useState('all');
  const actionOptions = [
    { label: 'All', value: 'all' },
    { label: 'Keep', value: 'keep' },
    { label: 'Delete', value: 'delete' },
    { label: 'Ban', value: 'ban' },
  ];
  const genderOptions = [
    { label: 'All', value: 'all' },
    { label: 'guys', value: 0 },
    { label: 'girls', value: 1 }
  ];
  const reasonOptions = [
    { label: 'All', value: 'all' },
    { label: 'Against Guidelines', value: 0 },
    { label: 'Nudity', value: 1 },
    { label: 'Underage', value: 2 },
    { label: 'Selling / Self-Promotion', value: 3 },
    { label: 'Sexually Suggestive', value: 4 },
    { label: 'Scam', value: 5 },
    { label: 'Illegal', value: 6 },
  ];
  const showClearIcon = actionFilter !== '';

  const { userIdURLParam } = useParams();
  const [userFilter, setUserFilter] = useState(userIdURLParam || "");

  // useEffect(() => {
  //   let myTimestamp = convertToTimestamp(new Date(range));
  //   let timestamp = convertToTimestamp(new Date(range));
  // }, [range]);
  // .where('date','>=', start).where('date','<=', end)
  //
  function fetchNext() {
    setIsLoading(true);

    let historyCollection = db.collection("history");

    if (userFilter) {
      setActionFilter('all')
      setAdminFilter('')
      setGenderFilter('all')
      historyCollection = historyCollection.where("post_data.userID", "==", userFilter);
    }
    else {
      if (adminFilter) {
        historyCollection = historyCollection.where(
          "mods",
          "array-contains",
          adminFilter ? adminFilter : "undefined"
        );
      }
      if (actionFilter != "all") {
        historyCollection = historyCollection.where("action", "==", actionFilter);
      }

      if (reasonFilter != "all") {
        historyCollection = historyCollection.where("reason_int", "==", reasonFilter);
      }

      if (genderFilter != 'all') {
        historyCollection = historyCollection.where("post_data.gender", "==", genderFilter);
        console.log("historyCollection", genderFilter);
      }

      if (range) {
        historyCollection = historyCollection
          .where("date", "<=", new Date(range))
      }
    }

    historyCollection = historyCollection.orderBy("date", "desc");
    if (lastPost) {
      console.log(lastPost, "--------work true when lastpost");
      historyCollection = historyCollection.startAfter(lastPost);
    }
    console.log(historyCollection, "hisss")

    historyCollection
      .limit(limit)
      .get()
      .then((snapshot) => {
        const historyData = [];

        snapshot.forEach((element) => {
          historyData.push(element.data());
        });

        setLastPost(snapshot.docs[snapshot.docs.length - 1]);
        console.log(
          historyData,
          "-----------net in history -----",
          snapshot.docs,
          snapshot.docs[snapshot.docs.length - 1]
        );
        setElements((elements) => [...elements, historyData]);
        setIsLoading(false);
      });
  }

  // console.log("-------------------main page last post--------", lastPost);

  function nextPage() {
    console.log("----------------------ooooo---------");
    if (page + 1 >= elements.length) {
      console.log("----------------------ooooo---- innnn-----", lastPost);
      fetchNext();
    }

    setPage((page) => page + 1);
  }

  function previousPage() {
    console.log("----------------------ooooo---------");
    setPage((page) => {
      if (page - 1 >= 0) {
        return page - 1;
      } else {
        return page;
      }
    });
  }

  // const handleKeyUp = useCallback(
  //   (e) => {
  //     console.log(activePost,"outer------ key down");
  //     if (e.key === " " && activePost === -1) {
  //       dispatch(setActivePost(0));
  //     };

  //     if (activePost === -1) {
  //       console.log(activePost,"outer------ key down---deep in");
  //       if (e.key === "g") {
  //         previousPage();
  //       }

  //       if (e.key === "h") {
  //         nextPage();
  //       }
  //       if (e.key === "ArrowRight") {
  //         nextPage();
  //       }

  //       if (e.key === "ArrowLeft") {
  //         previousPage();
  //       }
  //     }
  //   },
  //   [activePost, dispatch]
  // );
  const handleKeyUp = (e) => {
    console.log(activePost, "outer------ key down");
    if (e.key === " " && activePost === -1) {
      dispatch(setActivePost(0));
    }

    if (activePost === -1) {
      console.log(activePost, "outer------ key down---deep in");
      if (e.key === "g") {
        left.current.click();
        // previousPage();
      }

      if (e.key === "h") {
        right.current.click();
        // nextPage();
      }
      if (e.key === "ArrowRight") {
        right.current.click();
        // nextPage();
      }

      if (e.key === "ArrowLeft") {
        left.current.click();
        // previousPage();
      }
    }
  };

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  const preloadImages = (imagesArray) => {
    const promises = imagesArray.map((src) => preloadImage(src));
    return Promise.all(promises);
  };

  useEffect(() => {
    if (activePost == -1) {
      console.log("----event outer");
      document.addEventListener("keyup", handleKeyUp);
    } else {
      if (activePost !== -1) {
        let imagesToPreload = [
          elements[page][activePost + 1]?.imageUrlPost,
          elements[page][activePost + 1]?.profile_pic_url,
          elements[page][activePost + 1]?.verification_url,
          elements[page][activePost + 2]?.imageUrlPost,
          elements[page][activePost + 2]?.profile_pic_url,
          elements[page][activePost + 2]?.verification_url,
        ].filter(url => !!url); // Filter out any undefined or null values
        if (imagesToPreload.length > 0) {
          // console.log("prefetching :")
          console.log("urls:", imagesToPreload)
          preloadImages(imagesToPreload).then(() => {
            console.log("Successfully prefetched next images.");
          })
            .catch((error) => {
              console.error("Failed to preload next images:", error);
            });
        }
      }
    }

    return function () {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [activePost]);



  // console.log(elements, "----data for difront page-----------");
  const HistoryPosts = useCallback(() => {
    return (
      <>
        {(elements[page] ?? [])?.map((historyPost, index, ara) => {
          {
            // console.log(ara, "-------in maping---------", page);
          }
          return (
            <HistoryPost
              key={index}
              postId={index}
              history={historyPost}
              changed={historyPost.changed}
              {...historyPost.post_data}
              post={historyPost.post_data}
              imageID={historyPost.imageID}
              onChange={() => setChanged(historyPost.imageID)}
              limit={limit}
              nextPage={nextPage}
              previousPage={previousPage}
              page={page}
              userFilter={userFilter}
            />
          );
        })}
      </>
    );
  }, [elements, page]);

  const initialFetch = () => {
    setIsLoading(true);
    setPage(0)

    let historyCollection = db.collection("history");

    if (userFilter) {
      setActionFilter('all')
      setAdminFilter('')
      setGenderFilter('all')
      historyCollection = historyCollection.where("post_data.userID", "==", userFilter);
    }
    else {
      if (adminFilter) {
        historyCollection = historyCollection.where(
          "mods",
          "array-contains",
          adminFilter
        );
      }

      if (actionFilter != 'all') {
        historyCollection = historyCollection.where("action", "==", actionFilter);
        // console.log("historyCollection", historyCollection);
      }

      if (reasonFilter != 'all') {
        historyCollection = historyCollection.where("reason_int", "==", reasonFilter);
        // console.log("historyCollection", historyCollection);
      }

      if (genderFilter != 'all') {
        historyCollection = historyCollection.where("post_data.gender", "==", genderFilter);
        console.log("historyCollection", genderFilter);
      }

      if (range) {
        historyCollection = historyCollection
          .where("date", "<=", new Date(range))
      }
    }

    // historyCollection = historyCollection.orderBy("date", "desc");
    historyCollection = historyCollection.orderBy("date", "desc");
    // console.log(historyCollection, "hisss")

    historyCollection
      .limit(limit)
      .get()
      .then((snapshot) => {
        const historyData = [];

        snapshot?.forEach((element) => {
          historyData.push(element?.data());
        });
        console.log("these are the history posts:", historyData)
        setLastPost(snapshot.docs[snapshot.docs.length - 1]);
        setElements([historyData]);
        setIsLoading(false);
      });
  };

  const initialFetchByDate = () => {
    setIsLoading(true);
    setPage(0);
    let historyCollection = db.collection("history");

    if (userFilter) {
      setActionFilter('all')
      setAdminFilter('')
      setGenderFilter('all')
      historyCollection = historyCollection.where("post_data.userID", "==", userFilter);
    }
    else {
      if (adminFilter) {
        historyCollection = historyCollection.where(
          "mods",
          "array-contains",
          adminFilter
        );
      }

      if (actionFilter != 'all') {
        historyCollection = historyCollection.where("action", "==", actionFilter);
        console.log("historyCollection", historyCollection);
      }

      if (reasonFilter != 'all') {
        historyCollection = historyCollection.where("reason_int", "==", reasonFilter);
        // console.log("historyCollection", historyCollection);
      }

      if (genderFilter != 'all') {
        historyCollection = historyCollection.where("post_data.gender", "==", genderFilter);
        console.log("historyCollection", genderFilter);
      }

      if (range) {

        historyCollection = historyCollection
          .where("date", "<=", new Date(range))
        // .where("date", "<=", new Date());

      }
    }

    historyCollection = historyCollection.orderBy("date", "desc");
    // console.log(historyCollection, "hisss")
    historyCollection
      .limit(limit)
      .get()
      .then((snapshot) => {
        const historyData = [];

        snapshot.forEach((element) => {
          historyData.push(element.data());
        });
        console.log(historyData[0], "historyData");
        setLastPost(snapshot.docs[snapshot.docs.length - 1]);
        setElements([historyData]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    initialFetch();
    console.log("this is userFilter", userFilter)
  }, [range, adminFilter, actionFilter, reasonFilter, genderFilter, userFilter]);

  // useEffect(() => {
  //   if (userFilter) initialFetch();
  // }, [userFilter])

  // useEffect(initialFetch, [adminFilter]);
  // useEffect(initialFetch, [actionFilter]);
  // useEffect(initialFetch, [reasonFilter]);
  // useEffect(initialFetch, [genderFilter]);



  function setChanged(imageID) {
    setElements((elements) => {
      const newElements = elements.map((element) => {
        if (element.imageID === imageID) {
          element.changed = true;
        }
        return element;
      });

      return newElements;
    });
  }

  function loadAdmins() {
    const adminsArray = [];
    db.collection("admin")
      .where("admin", "==", true)
      .get()
      .then((snapshot) => {
        snapshot.forEach((admin) => {
          const { userID } = admin.data();
          // console.log(userID, "-------------------userid");
          adminsArray.push({ label: userID, value: userID });
        });
        setAdmins((admins) => [...admins, ...adminsArray]);
      });
  }

  useEffect(loadAdmins, []);

  function reset() {
    setLastPost(null);
    setPage(0);
    initialFetch();
  }

  function PageNavigation() {
    return (
      <div className="page-navigation">
        <Button onClick={previousPage} label="Previous" ref={left} />
        <Button label={page + 1} className="p-button-outlined " />
        <Button onClick={nextPage} label="next" ref={right} />
      </div>
    );
  }

  return (
    <div className="history">
      <div className="filters">
        <div className="refresh">
          <Button icon=" pi pi-replay" onClick={() => reset()} />
        </div>

        <PageNavigation />

        <div className="admin-filter">
          <Dropdown
            options={admins}
            onChange={(e) => {
              setPage(0);
              setAdminFilter(e.value);
              setLastPost(null);
            }}
            value={adminFilter}
            showClear
            placeholder="All Admins"
          />

          <input
            placeholder="All Admins"
            style={{ marginTop: "10px", height: "28px" }}
            type="datetime-local"
            onChange={(e) => {
              setRange(e.target.value);
            }}
            value={range}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px", marginTop: "-45px" }}>

        <div className="action-filter">
          <label style={{ fontSize: "19px" }}>Action: </label>
          <Dropdown
            options={actionOptions}
            value={actionFilter}
            onChange={(e) => {
              setPage(0);
              setActionFilter(e.value);
              console.log("this is action filter:", e.value)
              setLastPost(null);
            }}
            placeholder="All"
            showClear={showClearIcon}
          />
        </div>
        <div className="action-filter">
          <label style={{ fontSize: "19px" }}>Gender: </label>
          <Dropdown
            options={genderOptions}
            value={genderFilter}
            onChange={(e) => {
              setPage(0);
              setGenderFilter(e.value);
              console.log("this is gender filter:", e.value)
              setLastPost(null);
            }}
            placeholder="All"
            showClear={showClearIcon}
          />
        </div>
        {
          actionFilter === 'delete'
          &&
          <div className="action-filter" style={{ marginLeft: "5px" }}>
            <label style={{ fontSize: "19px" }}>Reason: </label>
            <Dropdown
              options={reasonOptions}
              value={reasonFilter}
              onChange={(e) => {
                setPage(0);
                setReasonFilter(e.value);
                console.log("this is reason filter:", e.value)
                setLastPost(null);
              }}
              placeholder="Reason"
              showClear={showClearIcon}
            />
          </div>
        }
        <div style={{ marginLeft: "45px" }}>
          <input
            type="text"
            placeholder="Search by User ID"
            style={{
              height: "30px",
              width: "200px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
            onChange={(e) => {
              console.log("this is search by userID:", e.target.value)
              // initialFetch(e.target.value);
              setUserFilter(e.target.value);
            }}
            value={userFilter}
          />
        </div>
      </div>

      <div className="block posts">
        {isLoading ? <ProgressSpinner /> : <HistoryPosts />}
      </div>

      <PageNavigation />
    </div>
  );
}

export default HistoryComponent;
