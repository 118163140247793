import { createSlice } from "@reduxjs/toolkit";

export const postsSlice = createSlice({
    name: "profile",

    initialState: {
        pressedKey: 0,
        all: [],
        reported: [],
        unread: [],
        verification: [],
        loading: true,
        activePost: -1,
        page: 1,
        lastSeen: false,
    },
    reducers: {
        setPosts: (state, action) => {
            console.log(state, action, "setting the Posts +++++");
            if (action.payload.unread) {
                state.unread[action.payload.page] = action.payload.posts;
            }
            else if (action.payload.reported) {
                state.reported[action.payload.page] = action.payload.posts;
            }
            else {
                if (action.payload.page === 1) {
                    //because we need to clear all the pages before setting the new ones
                    state.all = [];
                    state.all[action.payload.page] = action.payload.posts;
                }
                state.all[action.payload.page] = action.payload.posts;
            }
            state.loading = false;
        },
        setActivePost: (state, action) => {
            if (action.payload >= -1) {
                state.activePost = action.payload;
                console.log("this is the store setting active post", state.activePost)
            }
        },
        removePost: (state, action) => {
            let id = action.payload.id;

            if (state.reported[state.page])
                state.reported[state.page].forEach((post, i) => {
                    if (post.id === action.payload.id)
                        state.reported[state.page].splice(i, 1);
                });

            if (state.all[state.page])
                state.all[state.page].forEach((post, i) => {
                    if (post.id === action.payload.id) state.all[state.page].splice(i, 1);
                });

            if (state.unread[state.page])
                state.unread[state.page].forEach((post, i) => {
                    if (post.id === action.payload.id)
                        state.unread[state.page].splice(i, 1);
                });
        },
        removePostSwiping: (state, action) => {
            console.log("remove Post");
            if (state.all[state.page])
                state.all[state.page].forEach((post, i) => {
                    if (post.uid === action.payload.id) state.all[state.page].splice(i, 1);
                });
        },
        setPostUsername: (state, action) => {
            console.log("setPostUsername called");

            if (action.payload.unread) {
                state.unread[state.page][state.activePost].username =
                    action.payload.username;
            } else if (action.payload.reported) {
                state.reported[state.page][state.activePost].username =
                    action.payload.username;
            } else {
                state.all[state.page][state.activePost].username =
                    action.payload.username;
            }
        },
        setReportedPostSwiping: (state, action) => {
            console.log("redux called");
            if (action.payload.reported_user) {
                state.all[state.page][state.activePost].reported_user =
                    action.payload.reported_user;
            }
        },
        setPostText: (state, action) => {
            if (action.payload.unread) {
                state.unread[state.page][state.activePost].postText =
                    action.payload.postText;
            } else if (action.payload.reported) {
                state.reported[state.page][state.activePost].postText =
                    action.payload.postText;
            } else {
                state.all[state.page][state.activePost].postText =
                    action.payload.postText;
            }
        },
        nextPost: (state) => {
            state.activePost++;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
    },
});

export const {
    // keyDown,
    // updatePosts,
    setActivePost,
    nextPost,
    setPosts,
    removePost,
    changePage,
    setLastSeen,
    setPostUsername,
    setPostText,
    setReportedPostSwiping,
    removePostSwiping
} = postsSlice.actions;

export const selectPosts = (state) => state.profile.all;
export const selectReportedPosts = (state) => state.profile.reported;
export const selectUnreadPosts = (state) => state.profile.unread;

export const selectPage = (state) => state.profile.page;
export const selectLastSeen = (state) => state.profile.lastSeen;

export const selectVerificationPosts = (state) => state?.profile?.verification;

export const selectPostsLoading = (state) => state?.profile?.loading;
export const selectActivePost = (state) => state?.profile?.activePost;

export default postsSlice.reducer;
