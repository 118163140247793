import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

export default function TableDetails({ age_ai, age_req, liveliness }) {

  // const tableContainerStyle = {
  //   maxWidth: 700,
  //   boxShadow: 3,
  //   height: '40px'
  // };

  // const tableStyle = {
  //   minWidth: 250,
  //   maxWidth: '100%',
  //   fontSize: '1 rem',
  // };

  // const tableCellStyle = {
  //   fontSize: '1.2rem',
  //   textAlign:'center'
  //   // padding: '16px'
  // };

  // const thickBorderStyle = {
  //   borderRight: '1px solid lightgrey'
  // };

  return (
    // <TableContainer component={Paper} sx={tableContainerStyle}>
    //   <Table sx={tableStyle} aria-label="simple table">
    //     <TableBody>
    //       <TableRow
    //         key={"details"}
    //         sx={{ '&:last-child td, &:last-child th': {height:'40px',  paddingX:'5px', paddingY:0, borderTop:0,borderBottom:0,borderLeft:0} }}
    //       >
    //         <TableCell component="th" scope="row" sx={{ ...tableCellStyle, ...thickBorderStyle }}>
    //           Age<span > | </span>{age_req}
    //         </TableCell>
    //         <TableCell component="th" scope="row" sx={{ ...tableCellStyle, ...thickBorderStyle }}>
    //           AI Age<span > | </span>{age_ai}
    //         </TableCell>
    //         <TableCell component="th" scope="row" sx={{ ...tableCellStyle, ...thickBorderStyle }}>
    //           {liveliness === "fake" ? liveliness + ' 🔴' : liveliness}
    //         </TableCell>
    //       </TableRow>
    //     </TableBody>
    //   </Table>
    // </TableContainer>
    <div>
      <span style={{
        fontSize: '30px',
        backgroundColor: 'white',
        padding: '5px 20px',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
      }}>Age: <span style={{fontWeight: '500'}}>{age_req}</span>  •  AI: <span style={{fontWeight: '500'}}>{age_ai}</span>  •  Liveliness: <span style={{fontWeight: '500'}}>{liveliness === "fake" ? liveliness + ' 🔴' : liveliness}</span> </span>
    </div>
  );
}
