/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect, useCallback, useRef } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookf
// import * as yup from "yup";orm/resolvers/yup';

import { } from "../components/inputs";
import { useSelector } from "react-redux";
import {
  get_userid_mod,
  getBannedInfo,
  db,
  storage,
  logAnalytics,
  firestore,
  firebaseDeletePost,
  bio_moderation,
  username_moderation,
  delete_verification_moderation,
  profile_pic_moderation,
  view_verif_img,
  request_verif_moderation,
  caption_delete_moderation,
  ban_user_mod
} from "../../app/firebase";
import "./searchUser.css";
import { selectUser } from "../auth/userSlice";
import { useHistory, useLocation } from 'react-router-dom';

import { deleteNotification } from "../posts/model";

import sendDeleteMessage from "../posts/functions/sendDeleteMessage";
import { InputText } from "primereact/inputtext";

import {
  BanDataFields,
  lastPostFields,
  profileInfoFields,
  userInfoFields,
} from "./SearchUser.functions";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiStreetView } from "react-icons/bi";
import { BsFillChatSquareQuoteFill } from "react-icons/bs";

import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChatComponent from "../chat/chatComponent";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { FaAngleDoubleRight, FaHistory, FaInstagram, FaSnapchatGhost, FaTiktok, FaTwitter } from "react-icons/fa";
import { parseUrl, removeUndefined2 } from "../../helpers/helpers";


// import { useParams } from 'react-router-dom';


//k6uhRXNdiAXKr1b1tk74RevcPwR2
// +15512716227
export default function SearchUser(props) {

  let userID = props?.userID ? props?.userID : false;
  const [asPopup, setAsPopup] = useState(props?.userID ? true : false);

  let user = useSelector(selectUser);

  const currentLocation = useLocation();

  const [number, setNumber] = useState("");
  const [uid, setUid] = useState(userID ? userID : "");
  const [userData, setUserData] = useState("");
  const [banData, setBanInfo] = useState("");
  const [postInfo, setPostInfo] = useState({});
  const [pictures, setPictures] = useState([]);
  const [profileData, setProfileData] = useState("");
  const [verifImage, setVerifImage] = useState("");
  const [guessAI, setGuessAI] = useState('')
  const [closeCases, setCloseCases] = useState([])
  const [searchFilter, setSearchFilter] = useState(false)
  const [showPopup, setShowPopup] = useState({ show: false, ID: undefined });
  const [dataArrived, setDataArrived] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reasonFilter, setReasonFilter] = useState();
  const [showReasonDropdown, setShowReasonDropdown] = useState(false);
  const [showConfrimCaption, setShowConfirmCaption] = useState(false);
  const typingRef = useRef(false);
  const [links, setLinks] = useState([]);

  // const schema = yup.object({
  //   username: yup.string().required("Username is required").min(3, "Username must be at least 3 characters"),
  //   email: yup.string().required("Email is required").email("Must be a valid email"),
  // }).required();

  let history = useHistory();


  const inputRef = useRef(true);
  const location = "search_user";
  function handleNumberChange(event) {
    setNumber(event.target.value);
  }

  function handleUidChange(event) {
    // Regular expressions
    const phoneNumberRegex = /^\+?\d+$/; // Only numbers, optionally starting with '+'
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const uidRegex = /^[a-zA-Z0-9_-]+$/
    let text = event.target.value; // current change (the whole thing if paste / one char in typing)
    let type;
    let allText = uid // the whole string in the text box

    // check if paste or typing
    if (text.length === 1 && !typingRef.current && allText?.length < 8) { // we added allText?.length to know if we have removed a charachter from an already pasted text to not treat it as typing
      typingRef.current = true;
    }

    // if we are pasting (or hit search / enter) we should check the type immediately
    if (!typingRef.current && text.length > 8) {
      if (phoneNumberRegex.test(text)) {
        console.log("the type is phone")
        type = "phone";
        if (text[0] !== '+') {
          text = '+' + text;
        }
      } else if (emailRegex.test(text)) {
        console.log("the type is phone")
        type = "email";
      }
      else if (uidRegex.test(text)) {
        console.log("the type is uid");
        type = "uid";
      }

      if (type) {
        // we setUID inside the if so when we have anything else we dont update the state with urls/weird stuff that will cause a crash
        setUid(text);
        if (type === 'uid' && !typingRef.current) {
          console.log("searchUserByID is called from handleUidChange")
          searchUserByID(text);
        }
        else searchUserBy(type, text);
      }
      else {
        alert(`Please enter a valid User ID, phone number, or email: ${text}`);
      }
    }
    else {
      console.log("its typing")
      setUid(text);
    }
  }

  const getPostInfo = (uid) => {
    if(!uid){
      return;
    }
    db.collection("public_post")
      .doc(String(uid))
      .get()
      .then((postResponse) => {
        let post = postResponse?.data();
        console.log("those are post info", post)
        setPostInfo(post);
      });
  }

  function moderate(change) {
    if (!uid) return;

    db.collection("banned_moderation")
      .doc(String(uid))
      .set({ ...change, support_uid: user?.uid }, { merge: true })
      .then(() => {
        console.log("searchUserByID is called from moderate")
        searchUserByID();
      })
      .catch((err) => { });
  }

  function searchUserBy(type, text) {
    setIsLoading(true);
    get_userid_mod({
      type,
      text
    })
      .then((response) => {
        if (response?.data?.status === 1) {
          setUid(response?.data?.uid);
          console.log("searchUserByID is called from searchUserBy")
          searchUserByID(response?.data?.uid);
        }
        else alert(`UserID not found: ${response?.data?.message}`);
      })
      .catch((err) => {
        alert(`UserID not found: ${err}`);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const storageList = (uid) => {
    if (!uid) return;
    setPictures([]);

    let storageRef = storage.ref().child(uid + "/posts");

    storageRef.list({ maxResults: 6 }).then((images) => {
      images.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          setPictures((pictures) => {
            return [...pictures, url];
          });
        });
      });
    });
  }

  function adminAction() {
    db.collection("admin").doc("info").set(
      {
        last_action: Date.now(),
        userID: user?.uid,
        last_action_timestamp: firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
  }

  function log(event) {
    if (!postInfo) return;
    const logObject = {
      event_type: event,
      email: "email@gmail.com",
      userID: postInfo?.userID,
      imageUrlPost: postInfo?.imageUrlPost,
      postText: postInfo?.postText,
    };

    logAnalytics(logObject).catch((err) => console.error("log err", err));
  }

  function deletePost(reason) {
    if (!postInfo) return;

    ban_user_mod({
      uid: uid,
      action: "ban_temp",
      reason: reason,
      location: "post",
    })


    log("remove_img_mod");
    adminAction(postInfo?.id);
    firebaseDeletePost(postInfo?.userID, postInfo?.userID, props?.reported);
    getPostInfo(uid);
    deleteNotification({ fcm_token: postInfo?.fcm_token });
    setShowReasonDropdown(false);

    let reason_data = {
      reason_report: "Against guidelines",
      reason_report_int: 0
    }

    switch (reason) {
      case 'selling':
        reason_data.reason_report = 'Selling / Self-Promotion';
        reason_data.reason_report_int = 3;
        break;
      case 'nudity':
        reason_data.reason_report = 'Nudity / Partial Nudity';
        reason_data.reason_report_int = 1;
        break;
      case 'sexual':
        reason_data.reason_report = 'Suggestive / Sexual Suggestion';
        reason_data.reason_report_int = 4;
        break;
      case 'underage':
        reason_data.reason_report = 'Underage';
        reason_data.reason_report_int = 2;
        break;
      case 'guidelines':
        reason_data.reason_report = 'Against guidelines';
        reason_data.reason_report_int = 0;
        break;
      case 'illegal':
        reason_data.reason_report = 'Illegal';
        reason_data.reason_report_int = 6;
        break;
      case 'scam':
        reason_data.reason_report = 'Scam';
        reason_data.reason_report_int = 5;
        break;
      default:
        reason_data.reason_report = 'Against guidelines';
        reason_data.reason_report_int = 0;
        break;
    }

    sendDeleteMessage({
      username: postInfo?.username,
      uid: postInfo?.userID,
      emoji: postInfo?.emoji,
      reason: reason_data
    });

    // same as lock in posts
    db.collection("banned_moderation")
      .doc(uid)
      .set(
        {
          ...reason_data,
          unlock_all: false,
          report_counter_mod: firestore.FieldValue.increment(1),
          report_mod_uid: uid,
          report_mod_date: firestore.FieldValue.serverTimestamp(),
          last_post_reported: true,
          postText: postInfo?.postText ?? "",
          imageUrlPost: postInfo?.imageUrlPost ?? "",
        },
        { merge: true }
      );

    // same as deleteHistory in posts
    let imgurl = parseUrl(postInfo.imageUrlPost)
    let correctDatePost = {...postInfo};
    if(correctDatePost?.datePost){
      correctDatePost.datePost = correctDatePost.datePost.seconds 
    }
    db.collection("history")
      .doc(imgurl ?? user.uid)
      .set(
        {
          post_data: removeUndefined2(correctDatePost),
          mods: firestore.FieldValue.arrayUnion(user?.uid),
          action: "delete",
          edit_counter: firestore.FieldValue.increment(1),
          mod_uid: user?.uid,
          reason: reason_data.reason_report,
          reason_int: reason_data.reason_report_int,
          location: "search_user",
          verification_url: verifImage,
          profile_pic_url: userData?.first_profile_url,
          date: firestore.FieldValue.serverTimestamp(),
          imageID: imgurl,
        },
        { merge: true }
      ).then(() => {
        console.log("successfully written to history")
      })
      .catch((err) => {
        console.log("error writing to history", err)
      })

  }



  function deleteCaption() {
    console.log("This is Delete Caption Function", postInfo);
    // // { userID, username, caption, location 
    // return
    caption_delete_moderation({ userID: postInfo?.userID, username: postInfo?.username, caption: postInfo?.postText, location: "search_user" }).then((res) => {
      console.log(res?.data, "=========Res==========");
      alert(res?.data?.message);
    }).catch((err) => {
      console.log(err, "=============Error==================");
    });
  }



  function addKeys() {
    let keys = prompt("Enter ammount of keys", 50);
    db.collection("users")
      .doc(String(uid))
      .set({ keys: firestore.FieldValue.increment(keys) }, { merge: true })
      .then(() => {
        console.log("searchUserByID is called from addKeys")
        searchUserByID();
      })
      .catch((error) => console.log("Adding keys failed:", error));
  }

  function addComment() {
    if (!uid) return;
    let comment = prompt("Banning comment", "");
    db.collection("banned_moderation")
      .doc(String(uid))
      .set({ comment }, { merge: true })
      .then(() => {
        console.log("searchUserByID is called from addComment")
        searchUserByID();
      })
      .catch((error) => console.log("Adding comment failed", error));
  }
  // console.log(userData, "**********")

  // const get_ai_guess = (uid) => {
  //   if (uid) db.collection('verif')
  //     .where('app', '==', 'addchat')
  //     .where('waiting_moderation', '==', false)
  //     .where('uid', '==', uid)
  //     .limit(20)
  //     .get()
  //     .then((res) => {
  //       console.log('this is ai guess', res.docs[0].data().verif_guess)
  //       return setGuessAI(res?.docs[0]?.data()?.verif_guess)
  //     })
  //     .catch((err) => console.error(err))
  // }

  const get_ai_guess = (uid) => {
    if (uid) {
      db.collection('verif')
        .where('app', '==', 'addchat')
        .where('waiting_moderation', '==', false)
        .where('uid', '==', uid)
        .limit(20)
        .get()
        .then((res) => {
          if (res.docs.length > 0) {
            console.log('this is ai guess', res?.docs[0]?.data().verif_guess);
            return setGuessAI(res?.docs[0]?.data().verif_guess);
          } else {
            console.log('No documents found');
            return setGuessAI('no found');
          }
        })
        .catch((err) => {
          console.error(err);
          return setGuessAI(null);
        });
    }
  };

  const searchUserByID = (passedUID) => {
    setUserData(null);
    setBanInfo(null);
    setPostInfo(null);
    setPictures([]);
    setNumber("");
    let userID = passedUID || uid;
    console.log("searchUserByID with uid", userID)

    if (!userID) return;
    storageList(uid);
    getPostInfo(uid);

    db.collection("profile")
      .doc(String(userID))
      .get()
      .then((response) => {
        // console.log("{{{{-profiledata----}}}}}}}}}}}", response.data());
        setProfileData(response?.data());
      })
      .catch((err) => {
        console.error(err);
      });

    db.collection("users")
      .doc(String(userID))
      .get()
      .then((response) => {
        setUserData(response?.data());
        if (!dataArrived) setDataArrived(true);
      })
      .catch((err) => {
        console.error(err);
      });

    get_ai_guess(userID)
    // get_close_cases(uid)
    view_verif_img({ uid: userID }).then((res) => {
      setVerifImage(res?.data)
      console.log("VERIFIMG", res?.data)
    }).catch((err) => {
      console.log(err, "==========Err=========");
    })

    getBannedInfo(userID).then((banData) => {
      // let banData = banData.data();
      // banData["ai_guess"] =
      setBanInfo(banData?.data());
      if (!dataArrived) setDataArrived(true);
      // console.log("these are ban data", banData.data());
    });

    getPostInfo(userID);

    storageList(userID);

    getLinks(userID);

    if (!dataArrived) setDataArrived(true);
  }

  useEffect(() => {
    if (dataArrived) {
      // Move focus away from the input by blurring it to make sure when the user clicks "C" he doesn't write it in input box
      if (inputRef.current && asPopup) {
        inputRef.current.blur();
      }
    }
  }, [dataArrived]);

  useEffect(() => {
    document.addEventListener("keydown", callback);
    return () => {
      document.removeEventListener("keydown", callback);
    };
  });

  useEffect(() => {
    console.log("uid:", uid)
    if (uid === 'u') {
      return setUid('');
    }
    else {
      searchUserByID();
    }
  }, [])

  useEffect(() => {
    if (uid === 'u' || uid === '8') {
      return setUid('');
    }
    else if (!uid) {
      typingRef.current = false;
    }
  }, [uid])

  useEffect(() => {
    console.log(`guessAI changed for user ${uid}: ${guessAI}`)
  }, [guessAI])

  function callback(e) {
    if (e.key === "/") {
      // console.log("first")
      inputRef.current.focus();
      e?.preventDefault();
    }
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
      return //The key was pressed inside an input or textarea.
    }
    else if (e.key === "Escape") {
      //TODO defocus input
    
      // debugger;
      console.log("Escape pressed with showPopup:", showPopup)
      if (showPopup?.show) setShowPopup(currentState => {
        return { show: !currentState.show, ID: undefined };
      })
      inputRef.current.blur();
      e?.preventDefault();
    }
    else if (e.key === "c" && !e.ctrlKey && !e.metaKey) {
      // debugger;
      console.log("C pressed")
      if (!showPopup.show) handleShow(true, "chat");
      // debugger;
    }
    else if (e.key === "h" && !e.ctrlKey && !e.metaKey) {
      // debugger;
      console.log("H pressed")
      if (!showPopup.show) handleShow(true, "post")
      // debugger;
    }
  }

  const getLinks = async (uid) => {
    try {
      const docSnapshot = await db.collection("banned_moderation").doc(uid).get();
      const res = docSnapshot.data();
      const subArray = [res?.snap, res?.insta, res?.twitter, res?.tiktok];
      const allUndefined = subArray.every(item => item === undefined);
      setLinks(allUndefined ? [] : subArray);
    } catch (err) {
      console.log("failed getLinks:", err);
    }
  };

  const openLinks = (links) => {
    if (links.length === 0) return;

    links.forEach((user, index) => {
      if (!user) return; // Skip if no user data

      let link = '';
      switch (index) {
        case 0:
          link = `https://www.snapchat.com/add/${user}`;
          break;
        case 1:
          link = `https://www.instagram.com/${user}`;
          break;
        case 2:
          link = `https://twitter.com/${user}`;
          break;
        case 3:
          link = `https://www.tiktok.com/@${user}`;
          break;
        default:
          console.log(`No social media defined for index ${index}`);
          return;
      }

      console.log(`Opening link for ${user} at index ${index}: ${link}`);
      setTimeout(() => {
        window.open(link, '_blank');
      }, 100);
    });
  };

  useEffect(() => {
    console.log("showPopup:", showPopup);
  }, [showPopup])

  // useEffect(searchUserByID, [searchUserByID]);

  const handleShow = (newTab, page) => {
    if (newTab) {
      if (page === "chat") window.open(`${window.location.origin}/chat/${uid}`, "_blank");
      else if (page === "post") window.open(`${window.location.origin}/history/${uid}`, "_blank");
    }
    else history.push('/chat', { fromSearch: true, fromSearchID: uid });
  }

  useEffect(() => {
    console.log(searchFilter, "searchFilter")
  }, [searchFilter]);

  // useEffect(()=>{
  //   if(postInfo) console.log("this is after removeUndefined2",removeUndefined2(postInfo));
  // },[postInfo])

  return (
    <div className="user-search">
      {showPopup.show && showPopup.ID &&
        <div style={showPopup?.show && showPopup?.ID ? { backgroundColor: "rgba(0, 0, 0, 0.4)", width: "100vw", height: "100vh", zIndex: 900, position: "fixed", top: 0, left: 0 } : {}}>
          <div className="chatpopup_container" style={{
            position: 'fixed', // Or 'absolute', if you prefer
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Centers the div
            width: '80vw',
            border: '2px solid grey',
            borderRadius: '10px',
            backgroundColor: 'white',
            zIndex: 900,
            // Ensure content is scrollable if it overflows
            height: '90vh',
            overflowY: 'scroll'
          }}>
            <button className="close" style={{ position: "sticky" }} onClick={() => {
              console.log("popup set to false by button")
              setShowPopup(currentState => {
                return { show: false, ID: undefined };
              }
              )
            }}>
              Close
            </button>
            <ChatComponent reported={true} userID={showPopup.ID ? showPopup.ID : undefined} />
          </div>
        </div>
      }
      <div className="block">
        <div className="title"> Search User by </div>
        <div style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
          {/* <div className={`filter_search_button ${searchFilter ? 'active' : ''}`} style={{ width: "250px", height: "30px" }} onClick={() => { if (searchFilter) setSearchFilter(!searchFilter) }}> UserID / Email / Phone Number</div> */}
          {/* <div className={`filter_search_button ${searchFilter ? '' : 'active'}`} style={{ width: "150px", height: "30px" }} onClick={() => { if (!searchFilter) setSearchFilter(!searchFilter) }}> Details</div> */}
        </div>

        <div className="flex-center">
          {
            searchFilter ?
              <div className="details_search_container">
                <InputText
                  placeholder="Phone Number"
                  onChange={handleNumberChange}
                />
                <InputText
                  placeholder="Phone Number"
                  onChange={handleNumberChange}
                />
                <InputText
                  placeholder="Phone Number"
                  onChange={handleNumberChange}
                />
                <InputText
                  placeholder="Phone Number"
                  onChange={handleNumberChange}
                />
              </div>
              :
              <>
                {/* <div className="search p-grid">
                  <div className="p-col-10">
                    <InputText
                      placeholder="Phone Number"
                      value={number ?? ""}
                      onChange={handleNumberChange}
                    />
                  </div>
                  <div className="p-col">
                    <Button
                      icon={<FontAwesomeIcon icon={faSearch} />}
                      onClick={() => {
                        setUid(null);
                        searchUserByNumber();
                      }}
                    />
                  </div>
                </div> */}

                <div className="search" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-col-10">
                    <InputText
                      ref={inputRef}
                      autoFocus
                      placeholder="User ID, phone number, or email"
                      value={uid}
                      onChange={handleUidChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          console.log("ENTER KEY PRESSED", uid)
                          typingRef.current = false; // to tell it that it should search (we are not typing)
                          handleUidChange({ target: { value: uid } })
                        }
                      }}
                    />
                  </div>
                  <div className="p-col">
                    <Button
                      icon={<FontAwesomeIcon icon={faSearch} />}
                      onClick={() => {
                        setNumber("");
                        typingRef.current = false;
                        console.log("BUTTON CLICKED")
                        handleUidChange({ target: { value: uid } });
                      }}
                    />
                  </div>
                  {isLoading &&
                    <div>
                      <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                  }
                </div>
              </>}
        </div>
      </div>

      <div className="moderation-controls">
        <div className="block">
          <div
            className="control-button"
            onClick={() => moderate({ banned: true })}
          >
            Ban
          </div>
          <div
            className="control-button"
            onClick={() => moderate({ banned: false })}
          >
            Unban
          </div>

          <div
            className="control-button"
            onClick={() => moderate({ hide: true, unlock_all: false })}
          >
            Hide
          </div>
          <div
            className="control-button"
            onClick={() => moderate({ hide: false, unlock_all: true })}
          >
            Unhide
          </div>

          <div
            className="control-button"
            onClick={() => moderate({ supreme: true, unlock_all: false })}
          >
            Supreme
          </div>
          <div
            className="control-button"
            onClick={() => moderate({ supreme: false, unlock_all: true })}
          >
            Remove supreme
          </div>

          <div
            className="control-button"
            onClick={() => moderate({ unlock_all: false })}
          >
            Lock All
          </div>
          <div
            className="control-button"
            onClick={() => moderate({ unlock_all: true })}
          >
            Unlock All
          </div>

          <div className="neutral">
            <div className="control-button" onClick={() => addKeys()}>
              Add coins
            </div>
          </div>
          <div className="neutral">
            <div className="control-button" onClick={() => addComment()}>
              Add comment
            </div>
          </div>
          <div className="neutral">
            <div className="control-button" onClick={() => handleShow(true, "chat")}>
              Show Chat History
            </div>
          </div>
          <div className="neutral">
            <div className="control-button" onClick={() => handleShow(true, "post")}>
              Show Post History
            </div>
          </div>
        </div>
      </div>
      <div className="moderation-controls">
        <div className="block">
        <div
            id="btns"
            onClick={() => {
              ban_user_mod({
                uid: uid,
                action: "ban_temp",
                reason: 'selling',
                location: "search_user"
              })
              .then((res)=>{
                alert(`banned Post${res?.data?.message}`)
                console.log("banned Post", res?.data?.message)
              })
              .catch((err)=>{
                console.error(err);
              })
            }}
          >
            <FaHistory style={{ marginRight: "4px", height: "22px", width: "22px" }} />
            Selling Temporary Ban
          </div>
          <div
            id="btns"
            onClick={() => {
              ban_user_mod({
                uid: uid,
                action: "un_ban_temp",
                location: "search_user"
              })
              .then((res)=>{
                alert(`Unbanned Post ${res?.data?.message}`)
                console.log("unbanned Post", res?.data?.message)
              })
              .catch((err)=>{
                console.error(err);
              })
            }}
          >
            <FaHistory style={{ marginRight: "4px", height: "22px", width: "22px" }} />
            Unban Post
          </div>
          <div
            id="btns"
            onClick={() => {
              bio_moderation({ userID: userData.userID, username: userData.username, location, bio: userData.bio }).then((res) => {
                if (res?.data?.status == 1) {
                  window.alert(res?.data?.message)
                }
                console.log(res, "-------------bio---------")
              });
            }}
          >
            <RiDeleteBinLine style={{ marginRight: "4px" }} />
            Bio
          </div>
          <div
            id="btns"
            onClick={() => {
              username_moderation({ userID: userData.userID, username: userData.username, location }).then((res) => {
                if (res?.data?.status == 1) {
                  window.alert(res?.data?.message)
                }
                console.log(res, "-----Username--------")
              });
            }}
          >
            <RiDeleteBinLine style={{ marginRight: "4px" }} />
            Username
          </div>
          <div
            id="btns"
            onClick={() => {
              delete_verification_moderation({ uid, username: userData.username, location }).then((res) => {
                if (res?.data?.status == 1) {
                  window.alert(res?.data?.message)
                }
                console.log(res, "-----verify ing--------")
              });
            }}
          >
            <RiDeleteBinLine style={{ marginRight: "4px" }} />
            Verification
          </div>
          <div
            id="btns"
            onClick={() => {
              profile_pic_moderation({ userID: userData.userID, username: userData.username, location, imgUrl: userData.first_profile_url }).then((res) => {
                if (res?.data?.status == 1) {
                  window.alert(res?.data?.message)
                }
                console.log(res, "----- Profile Picture--------")
              });
            }}
          >
            <RiDeleteBinLine style={{ marginRight: "4px" }} />
            Profile Picture
          </div>
          {links.length > 0 &&
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
              <div
                id="btns"
                onClick={() => {
                  console.log("opening links")
                  openLinks(links)
                }}
              >
                <FaAngleDoubleRight style={{ marginRight: "4px" }} />
                Open Links
              </div>
              <p style={{
                backgroundColor: "#ffb6c1",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                borderRadius: '10px',
                margin: '0',
                gap: "5px"
              }}>
                {links[0] && <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '3px' }}><FaSnapchatGhost color={'#fffff'} /></span>}
                {links[1] && <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '3px' }}><FaInstagram color={'#fffff'} /></span>}
                {links[2] && <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '3px' }}><FaTwitter color={'#fffff'} /></span>}
                {links[3] && <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '3px' }}><FaTiktok color={'#fffff'} /></span>}
              </p>
            </div>
          }
          <div
            id="btns"
            onClick={() => {
              request_verif_moderation({ uid, username: userData.username, location }).then((res) => {
                if (res?.data?.status == 1) {
                  window.alert(res?.data?.message)
                }
                console.log(res, "-----req veryfy------------")
              });
            }}
          >
            <BsFillChatSquareQuoteFill style={{ marginRight: "4px" }} /> Request
            Verification
          </div>
        </div>
      </div>
      <div className="results">
        {/* {console.log(postInfo, "========postInfo========")} */}
        {postInfo && (
          <div className="result ">
            <div className="block post-result">
              {
                <div style={{ display: 'flex', flexDirection: 'column', gap: "5px", alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ display: 'flex', gap: "5px", alignItems: 'center', justifyContent: 'center' }}>

                    {!showReasonDropdown && !showConfrimCaption &&
                      <>
                        <button className="btn" style={{ position: 'static' }} onClick={() => setShowConfirmCaption(true)}>
                          Caption
                        </button>
                        <button className="btn" style={{ position: 'static' }} onClick={() => setShowReasonDropdown(true)}>
                          Post
                        </button>
                      </>
                    }
                    {showReasonDropdown &&
                      <>
                        <button className="btn" style={{ position: 'static' }} onClick={() => setShowReasonDropdown(false)}>
                          Cancel
                        </button>
                        <button className="btn" style={{ position: 'static' }} onClick={() => deletePost(reasonFilter)}>
                          Delete Post
                        </button>
                      </>
                    }
                    {showConfrimCaption &&
                      <>
                        <button className="btn" style={{ position: 'static' }} onClick={() => setShowConfirmCaption(false)}>
                          Cancel
                        </button>
                        <button className="btn" style={{ position: 'static' }} onClick={() => deleteCaption()}>
                          Delete Caption
                        </button>
                      </>
                    }
                  </div>
                  {showReasonDropdown && <Dropdown
                    options={[
                      { value: "selling", label: "Selling / Self-Promotion" },
                      { value: "nudity", label: "Nudity / Partial Nudity" },
                      { value: "sexual", label: "Suggestive / Sexual Suggestion" },
                      { value: "underage", label: "Underage" },
                      { value: "guidelines", label: "Against guidelines" },
                      { value: "illegal", label: "Illegal" },
                      { value: "scam", label: "Scam" }
                    ]}
                    value={reasonFilter}
                    onChange={(e) => {
                      setReasonFilter(e.value);
                      console.log(e.value);
                    }}
                    placeholder="Choose reason to proceed"
                  />
                  }
                </div>
              }

              <p style={{ marginTop: '20px', fontSize: '20px', fontWeight: 'bold' }}>Last Post</p>
              <div className="date">
                {new Date(postInfo.datePost?.seconds * 1000).toString()}
              </div>
              {postInfo.postText && (
                <div className="post-text"> {postInfo.postText} </div>
              )}

              <img src={postInfo.imageUrlPost} />
              <div className="fields">
                {lastPostFields({ lastPost: postInfo })}
              </div>
            </div>
          </div>
        )}

        {userData && (
          <div className="result">
            <div className="block">
              <h3> User Information </h3>
              <div className="fields">


                {userData.first_profile_url && (
                  <div className="field">
                    <img src={userData.first_profile_url} alt="no profile image" />
                  </div>
                )}
                {!typingRef.current && userInfoFields({ userInfo: userData })}
                {/* {userData.bio && (
                  <div className="field">
                    <div className="label"> Bio </div>
                    <div className="value">{userData.bio} </div>
                  </div>
                )} */}



              </div>
              <h3> profile Information </h3>
              <div className="fields">
                {/* {console.log(profileData, "===========profileData===========")} */}
                {profileInfoFields({ profileInfo: profileData })}
              </div>
            </div>
          </div>
        )}

        {banData && (
          <div className="result">
            <div className="block">
              <h3> Ban Moderation </h3>

              <div className="fields">
                {banData.supreme && (
                  <div className="field red">Supreme banned</div>
                )}
                {/* {console.log(banData, "===============banData=============")} */}
                {banData.imageUrlPost && (
                  <div className="field">
                    <img src={banData.imageUrlPost} alt="banned" />
                  </div>
                )}

                <BanDataFields banData={banData} />
                <div className="field">
                  <div className="label">Age-AI</div>
                  <div className="value">{guessAI?.age || 'Nothing'}</div>
                </div>
                <div className="field">
                  <div className="label">Liveliness</div>
                  <div className="value">{guessAI?.liveliness || 'Nothing'}</div>
                </div>
                <div className="field">
                  <div className="label">Face Match</div>
                  <div className="value">{guessAI?.face_match || 'Nothing'}</div>
                </div>
                {/* {console.log(verifImage, "=================verifImage==============")} */}

                {verifImage && (
                  <div className="field">
                    <img src={verifImage?.url} alt="Verify Image" />
                  </div>
                )}
                {verifImage?.url_sign && (
                  <div className="field">
                    <img src={verifImage?.url_sign} alt="Verify Sign Image" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* {closeCases.length > 0 &&
          <div className="result">
            <div className="block">
              <div className="field">
                <div className="label">Age-AI</div>
                <div className="value">{guessAI?.age}</div>
              </div>
              <div className="field">
                <div className="label">Liveliness</div>
                <div className="value">{guessAI?.liveliness}</div>
              </div>
              <div className="field">
                <div className="label">Face Match</div>
                <div className="value">{guessAI?.face_match}</div>
              </div>
            </div>
          </div> */}

      </div>

      <div className="results image-results">
        {pictures?.map((picture, index) => {
          return (
            <div className="result" key={picture}>
              <img src={picture} key={index} />
            </div>
          );
        })}
      </div>
    </div>
  );
}



// Rajkumar Jain APM: from Search User, on the far right section (Ban User)

// Rajkumar Jain APM: [ ] load the verification image and the sign verification if it exist

// Rajkumar Jain APM: and load them at the bottom of this section

// Rajkumar Jain APM: to get them call "view_verif_img" with param "uid" as the user id of the user we want to see the 2 verif images

// Rajkumar Jain APM: and you will get the url and url_sign (2 images to load and might not exist)