import { Box, Button, CircularProgress } from '@mui/material'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { changePage, selectPage } from '../../../posts/postsSlice';
import { useCallback, useEffect, useState } from 'react';
import { get_counter_mod } from '../../../../app/firebase';

export default function PageNavigation({ postsLength, fetchNextPage, loading, setLoading, isLast }) {
    const [counter, setCounter] = useState(null);
    let page = useSelector(selectPage);
    const dispatch = useDispatch();
    const PagePaper = styled(Paper)(({ theme }) => ({
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        ...theme.typography.body2,
        textAlign: 'center',
    }));

    const getCountOfData = useCallback(async () => {
        let count = await get_counter_mod({ app: "addchat", section: "age" });
        if (count?.data?.status === 1) {
            setCounter(count?.data?.counter);
        } else {
            console.error("failed to fetch counter")
        }
        // console.log("called counter")
    }, [postsLength]);
    useEffect(() => {
        getCountOfData();
    }, [getCountOfData])

    return (
        <nav style={{ paddingTop: 0, borderBottom: '1px solid lightgray', marginBottom: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <PagePaper square={false} sx={{marginLeft: '50px'}}>
                {
                    counter === null ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress size={20} />
                        </Box>
                        :
                        counter
                }
            </PagePaper>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '3px' }}>
                <Button sx={{
                    backgroundColor: 'rgba(190, 190, 190, 0.8)',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: 'grey',
                        textDecoration: 'bold'
                    }
                }}
                    startIcon={<ArrowBackOutlinedIcon />}
                    onClick={() => {
                        setLoading(true);
                        dispatch(changePage(page - 1))
                    }}>
                    Back</Button>

                <PagePaper square={false}>{page}</PagePaper>

                <Button sx={{
                    backgroundColor: 'rgba(190, 190, 190, 0.8)',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: 'grey',
                        textDecoration: 'bold'
                    }
                }}
                    endIcon={<ArrowForwardOutlinedIcon />}
                    onClick={() => {
                        if (loading) return; //to prevent spamming the button
                        if (!isLast) {
                            setLoading(true);
                            dispatch(changePage(page + 1))
                        }
                        else fetchNextPage()
                    }}>
                    Next
                </Button>
            </div>
            <div>
                {/** this is used to help align the other components */}
            </div>
        </nav>
    )
}
